import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import _ from 'lodash';
import { Type } from 'react-bootstrap-table2-editor';
import { dateFilter, textFilter } from 'react-bootstrap-table2-filter';

import { columnMapping, defaultMediumCol, defaultSmallCol } from './Columns';
import { withAuthorization, withEmailVerification } from '../Session';
import Firebase, { withFirebase } from '../Firebase';
import Spinner from '../Spinner';
import { RefinedDataTable } from './refinedDataTable';
import { Button, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { ButtonTabs } from '../Common/ButtonTabs/buttonTabs';
import PropTypes from 'prop-types';

const fileList = [
  {
    id: 'cbs',
    name: 'CBS',
  },
  {
    id: 'ehr',
    name: 'East Houston Refined',
  },
  {
    id: 'dmb',
    name: 'Des Moines Butane',
  },
  {
    id: 'dmfg',
    name: 'Des Moines Frac Gas',
  },
  {
    id: 'kcb',
    name: 'Kansas City Butane',
  },
  {
    id: 'g2',
    name: 'Greensboro II',
  },
  {
    id: '8082',
    name: '80.82',
  },
  {
    id: 'wtb',
    name: 'W Tank Batching',
  },
  {
    id: 'rvp',
    name: 'RVP',
  },
];

let selectedSort = fileList[0];

const tabDisplay = [
  { title: 'Sorted', sortTypes: ['A', 'F', 'M'] },
  { title: 'Follow up', sortTypes: ['F'] },
  { title: 'Missing', sortTypes: ['M'] },
  { title: 'Deleted', sortTypes: ['D'] },
  { title: 'Complete', sortTypes: ['A'] },
];

class SortedPageBase extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      rows: [],
      columns: [],
      keyField: 'index',
      selectedTab: 0,
    };
    this.changeSelectedFile = this.changeSelectedFile.bind(this);
    this.loadSelectedFile = this.loadSelectedFile.bind(this);
    this.saveNotes = this.saveNotes.bind(this);
  }

  componentDidMount() {
    this.loadSelectedFile();
  }

  // eslint-disable-next-line no-unused-vars
  componentDidUpdate(prevProps, prevState, snapshot) {
    const { selectedTab } = this.state;
    if (prevState.selectedTab !== selectedTab) {
      this.loadSelectedFile(true);
    }
  }

  refreshClick = () => {
    this.setState({ loading: true });
    setTimeout(() => {
      this.loadSelectedFile(true);
    }, 200);
  };

  saveNotes(oldValue, newValue, row, column) {
    // TODO: check how to implement it
    const writeObj = {};
    writeObj[row.sampleid] = newValue;
    const { firebase } = this.props;
    if (column.dataField === 'assignanalytical') {
      firebase.saveNote(`analytical-${selectedSort.id}`, writeObj);
    }
    if (column.dataField === 'customnotes') {
      firebase.saveNote(`sort-${selectedSort.id}`, writeObj);
    }
    if (column.dataField === 'status') {
      firebase.saveNote(`status-${selectedSort.id}`, writeObj);
      if (newValue !== 'ACTIVE') {
        this.setState({ loading: true });
        row.customnotes =
          row.customnotes && row.customnotes !== ''
            ? `${row.customnotes} [${newValue}]`
            : `[${newValue}]`;
        const notesObj = {};
        notesObj[row.sampleid] = row.customnotes;
        firebase.saveNote(`sort-${selectedSort.id}`, notesObj);
        this.setState({ loading: false });
      }
    }
  }

  changeSelectedFile(event) {
    selectedSort = JSON.parse(JSON.stringify(fileList.find((fl) => fl.id === event.target.value)));
    this.setState({ rows: [] });
    this.loadSelectedFile(true);
  }

  async loadSelectedFile(force = false) {
    const { selectedTab, rows } = this.state;
    const { sortTypes } = tabDisplay[selectedTab];
    const { firebase } = this.props;

    if (force || !rows || rows.length < 1) {
      this.setState({ loading: true });
      const [snapshot, notesSnapshot, statusSnapshot, analyticalSnapshot] = await Promise.all([
        firebase.sort(selectedSort.id).once('value'),
        firebase.notes(`sort-${selectedSort.id}`).once('value'),
        firebase.notes(`status-${selectedSort.id}`).once('value'),
        firebase.notes(`analytical-${selectedSort.id}`).once('value'),
      ]);

      // need to double up dates for two-way date filters
      const rowsMain = snapshot.val();
      const header = Object.keys(rowsMain[0]);

      let columns = [];
      if (columnMapping[selectedSort.id]) {
        columns = _.cloneDeep(columnMapping[selectedSort.id]);
      } else {
        // build header based upon actual contents
        header
          .filter((h) => !['tank_line', 'remarks'].includes(h))
          .forEach((h) => {
            columns.push({
              dataField: h,
              text: h,
              filter: h.endsWith('date') || h.startsWith('date') ? dateFilter() : textFilter(),
              sort: true,
              editable: false,
              ...defaultSmallCol,
            });
          });
      }

      if (
        (sortTypes.includes('F') || sortTypes.includes('A')) &&
        !columns.find((c) => c.dataField === 'status')
      ) {
        columns.push({
          dataField: 'status',
          text: 'Status',
          filter: textFilter(),
          sort: true,
          editable: sortTypes.length === 1,
          ...defaultSmallCol,
          editor: {
            type: Type.SELECT,
            editcellstyle: { minWidth: 200 },
            options: [
              {
                value: 'ACTIVE',
                label: 'ACTIVE',
              },
              {
                value: 'EXCEPTION',
                label: 'EXCEPTION',
              },
              {
                value: 'SILENCE',
                label: 'SILENCE',
              },
            ],
          },
        });
      }
      if (sortTypes.includes('F') && !columns.find((c) => c.dataField === 'followupreason')) {
        columns.push({
          dataField: 'followupreason',
          text: 'Follow-Up Reason',
          filter: textFilter(),
          sort: true,
          editable: false,
          ...defaultMediumCol,
          editcellstyle: { minWidth: 200 },
        });
      }

      if (sortTypes.length > 1 && !columns.find((c) => c.dataField === 'sort')) {
        columns.push({
          dataField: 'sort',
          text: 'Category Code',
          filter: textFilter(),
          sort: true,
          editable: false,
          ...defaultSmallCol,
        });
      }

      if (sortTypes.length === 1 && !columns.find((c) => c.dataField === 'assignanalytical')) {
        columns.push({
          dataField: 'assignanalytical',
          text: 'Assign Analytical',
          filter: textFilter(),
          sort: true,
          ...defaultMediumCol,
          editcellstyle: { minWidth: 200 },
          editor: {
            type: Type.TEXTAREA,
            rows: 2,
            cols: 40,
          },
        });
      }

      if (sortTypes.length === 1 && !columns.find((c) => c.dataField === 'customnotes')) {
        columns.push({
          dataField: 'customnotes',
          text: 'Notes',
          filter: textFilter(),
          sort: true,
          ...defaultMediumCol,
          editcellstyle: { minWidth: 200 },
          editor: {
            type: Type.TEXTAREA,
            rows: 2,
            cols: 40,
          },
        });
      }

      let notes = notesSnapshot.val();
      if (!notes) {
        notes = {};
      }
      let status = statusSnapshot.val();
      if (!status) {
        status = {};
      }
      let analytical = analyticalSnapshot.val();
      if (!analytical) {
        analytical = {};
      }
      for (const row of rowsMain) {
        row.customnotes = notes[row.sampleid] || '';
        row.status = status[row.sampleid] || 'ACTIVE';
        row.assignanalytical = analytical[row.sampleid] || '';
      }

      // set up rows:
      let rows = rowsMain.filter((r) => sortTypes.includes(r.sort));
      let exceptionRows = [];

      // handle follow-up designations:
      if (sortTypes.length === 1 && sortTypes.includes('A')) {
        rows = rows.concat(rowsMain.filter((r) => r.sort === 'F' && r.status === 'SILENCE'));
      }
      if (sortTypes.length === 1 && sortTypes.includes('F')) {
        exceptionRows = rows.filter((r) => r.status === 'EXCEPTION');
        rows = rows.filter((r) => r.status === 'ACTIVE');
      }

      this.setState({ rows, exceptionRows, columns, loading: false });
    }
  }

  render() {
    const { loading, rows, exceptionRows, columns, keyField, selectedTab } = this.state;
    return (
      <div style={{ display: 'flex', flexDirection: 'column', gap: '0.75rem' }}>
        <h1>Refined Data</h1>
        <div style={{ display: 'flex', gap: '0.75rem' }}>
          <FormControl fullWidth>
            <InputLabel id="select-file-label">File</InputLabel>
            <Select
              labelId="select-file-label"
              value={selectedSort.id}
              label="File"
              onChange={this.changeSelectedFile}
              disabled={loading}
            >
              {fileList.map((fl) => {
                return (
                  <MenuItem key={`menuItem-file-${fl.id}`} value={fl.id}>
                    {fl.name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          <Button color="primary" variant="contained" onClick={() => this.refreshClick()}>
            Refresh
          </Button>
        </div>
        <ButtonTabs
          tabDisplay={tabDisplay}
          setSelectedTab={(value) => this.setState({ selectedTab: value })}
          selectedTab={selectedTab}
        />

        {loading && <Spinner loading={loading} />}
        {!loading && rows && Array.isArray(rows) && (
          <div>
            {rows && Array.isArray(rows) && rows.length > 0 && (
              <RefinedDataTable keyField={keyField} rows={rows} columns={columns} />
            )}
          </div>
        )}

        {!loading && exceptionRows && exceptionRows.length > 0 && (
          <RefinedDataTable keyField={keyField} exceptionRows={exceptionRows} columns={columns} />
        )}
      </div>
    );
  }
}

SortedPageBase.propTypes = {
  firebase: PropTypes.instanceOf(Firebase).isRequired,
};

const condition = (authUser) => !!authUser;

const SortedPage = withFirebase(SortedPageBase);

export default compose(
  withEmailVerification,
  withAuthorization(condition),
  withRouter,
  withFirebase
)(SortedPage);

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Chip, Collapse, IconButton, TableCell, TableRow } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

const CollapsibleRow = ({ row, columns }) => {
  const [open, setOpen] = useState(false);

  const columnsToDisplay = columns.filter((value) => value.display);
  const columnsInCollapse = columns.filter((value) => !value.display);
  return (
    <>
      <TableRow>
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>

        {columnsToDisplay.map((c) => (
          <TableCell key={`${c.text}-${row[c.dataField]}`}>
            {c.formatter ? c.formatter(row[c.dataField]) : row[c.dataField]}
          </TableCell>
        ))}
      </TableRow>
      <TableRow>
        <TableCell
          style={{ paddingBottom: 0, paddingTop: 0 }}
          colSpan={columnsToDisplay.length + 1}
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            <div
              style={{
                display: 'grid',
                margin: '1rem auto',
                gridTemplateColumns: 'repeat(4,1fr)',
                gap: '1rem',
              }}
            >
              {columnsInCollapse.map((c) => (
                <Chip
                  variant="outlined"
                  color="primary"
                  key={`chip-${c.text}-${row[c.dataField]}`}
                  label={`${c.text}: ${row[c.dataField]}`}
                />
              ))}
            </div>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

CollapsibleRow.propTypes = {
  row: PropTypes.shape({}).isRequired,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      dataField: PropTypes.string.isRequired,
      display: PropTypes.bool.isRequired,
    })
  ).isRequired,
};

export { CollapsibleRow };

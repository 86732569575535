import React from 'react';
import { Alert, Avatar, Card, CardContent, CardHeader, Chip } from '@mui/material';
import ReceiptIcon from '@mui/icons-material/Receipt';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import LeaderboardIcon from '@mui/icons-material/Leaderboard';
import PropTypes from 'prop-types';

const FacilitySummary = ({ facility }) => {
  const listHelper = [
    {
      header: 'Total',
      items: [{ title: 'Total Volume (YTD)', keyValue: 'batchvolume', icon: <ReceiptIcon /> }],
    },
    {
      header: 'Benzene',
      items: [
        { title: 'Average', keyValue: 'benzeneaverage', icon: <LeaderboardIcon /> },
        { title: 'Credit', keyValue: 'benzenecredit', icon: <CreditCardIcon /> },
      ],
    },
    {
      header: 'Sulfur',
      items: [
        { title: 'Average', keyValue: 'sulfuraverage', icon: <LeaderboardIcon /> },
        { title: 'Credit', keyValue: 'sulfurcredit', icon: <CreditCardIcon /> },
      ],
    },
  ];

  if (!facility.swcg) {
    return (
      <Alert variant="filled" severity="error">
        Not Found
      </Alert>
    );
  }

  const ItemDisplay = ({ item }) => (
    <Chip
      style={{ width: '100%' }}
      icon={item.icon}
      label={`${item.title}: ${facility.swcg[item.keyValue]}`}
      color="primary"
    />
  );

  ItemDisplay.propTypes = {
    item: PropTypes.shape({
      icon: PropTypes.any,
      title: PropTypes.string,
      keyValue: PropTypes.string,
    }).isRequired,
  };

  return (
    <div style={{ width: '100%', display: 'flex', gap: '1rem' }}>
      {' '}
      {listHelper.map((group) => {
        return (
          <Card style={{ width: '100%' }}>
            <CardHeader
              avatar={<Avatar>{group.header[0].toUpperCase()}</Avatar>}
              title={group.header}
            />
            <CardContent style={{ display: 'flex', gap: '0.25rem' }}>
              {group.items.map((item) => (
                <ItemDisplay item={item} />
              ))}
            </CardContent>
          </Card>
        );
      })}
    </div>
  );
};

FacilitySummary.propTypes = {
  facility: PropTypes.shape({
    swcg: PropTypes.any,
  }).isRequired,
};

export { FacilitySummary };

import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';

const DropDown = ({ onChange, value, label, options, disabled }) => (
  <FormControl fullWidth>
    <InputLabel id="attestation-year">{label}</InputLabel>
    <Select
      labelId="attestation-year"
      onChange={(event) => onChange(event.target.value)}
      value={value}
      label={label}
      disabled={disabled}
    >
      {options.map((y) => (
        <MenuItem key={`${label}-${y.label}`} value={y.value}>
          {y.label}
        </MenuItem>
      ))}
    </Select>
  </FormControl>
);

DropDown.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
};

DropDown.defaultProps = {
  disabled: false,
};

export { DropDown };

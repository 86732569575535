import moment from 'moment';
import _ from 'lodash';

const aggregations = {};
const gsf0402 = {};
const facilities = {};
const rvpData = {};
const aggregationsKeys = ['8082', 'cbs', 'dmb', 'dmfg', 'ehr', 'g2', 'kcb', 'wtb'];

function sulfurFlag(aggType, a) {
  // sulfur:
  return (
    (['8082', 'ehr', 'dmb', 'kcb'].includes(aggType) && a.sulfur > 10) ||
    (['g2', 'cbs', 'wtb'].includes(aggType) && a.sulfur > 80) ||
    (['dmfg'].includes(aggType) && a.sulfur > 95)
  );
}

function benzeneFlag(aggType, a) {
  // benzene
  return (
    (['8082', 'ehr', 'dmb', 'kcb'].includes(aggType) && a.benzene > 0.03) ||
    (['g2', 'cbs', 'wtb', 'dmfg'].includes(aggType) && a.benzene > 2)
  );
}

function butaneFlag(aggType, a) {
  // butane
  return ['8082'].includes(aggType) && a.totalbutanevolume < 92;
}

/* disabled temporarily
function rvpFlag(aggType, a) {
  // rvp over 9.05 between 5/1 and 9/15
  return (
    !!aggType &&
    a.rvp > 9.05 &&
    moment(a.date).month() >= 4 &&
    (moment(a.date).month() < 8 || (moment(a.date).month() === 9 && moment(a.date).date() <= 15))
  );
}
*/

const dateFormat = (date) => (date.includes('/') ? 'M/D/YYYY' : 'YYYY-MM-DD');
const asMoment = (date) => moment(date, dateFormat(date));
const flagQuantity = ({ facilitySet, aggType, filter }) => {
  if (!facilitySet) return 0;

  return facilitySet.filter((a) => filter(aggType, a)).length;
};
const fetchAggregations = async (firebase, location) => {
  const [rvpDataSnapshot, rvpNotesSnapshot, rvpStatusSnapshot] = await Promise.all([
    firebase.sortRvpByFacility(location),
    firebase.notes(`sort-rvp`).once('value'),
    firebase.notes(`status-rvp`).once('value'),
  ]);

  const newVar = await Promise.all(
    aggregationsKeys.map((aggType) => firebase.aggsByFacility(aggType, location))
  );

  const aggregationsIn = newVar
    .map((snapshot, index) => {
      const val = snapshot.val();

      if (!val) {
        return null;
      }

      if (Array.isArray(val)) {
        return { [aggregationsKeys[index]]: val };
      }

      return { [aggregationsKeys[index]]: Object.keys(val).map((k) => val[k]) };
    })
    .filter((v) => v)
    .reduce((acc, cur) => ({ ...acc, ...cur }), {});

  const rvpDataUnfiltered = Object.values(rvpDataSnapshot.val());

  let rvpNotes = rvpNotesSnapshot.val();
  let rvpStatus = rvpStatusSnapshot.val();

  // filter rvp data
  if (!rvpNotes) {
    rvpNotes = {};
  }
  if (!rvpStatus) {
    rvpStatus = {};
  }
  for (const row of rvpDataUnfiltered) {
    row.customnotes = rvpNotes[row.sampleid] || '';
    row.status = rvpStatus[row.sampleid] || 'ACTIVE';
  }

  // set up rows:
  rvpData[location] = rvpDataUnfiltered.filter((r) => r.sort === 'F' && r.status === 'ACTIVE');

  // filter for only flags:
  for (const aggType of aggregationsKeys) {
    const newAggs = Object.keys(aggregationsIn[aggType] || {}).map((i) => {
      const agg = aggregationsIn[aggType][i];
      agg.flag =
        sulfurFlag(aggType, agg) || benzeneFlag(aggType, agg) || butaneFlag(aggType, agg)
          ? 'Y'
          : 'N';
      return agg;
    });

    if (!aggregations[aggType]) {
      aggregations[aggType] = newAggs;
    } else {
      aggregations[aggType] = [...aggregations[aggType], ...newAggs];
    }
  }
};

function generateSwcg(m, selectedFacility) {
  const facilityname = selectedFacility;

  const ret = {
    facilityname,
    key: Object.keys(facilities).indexOf(facilityname),
    batchvolume: Math.round(
      _.sum(m.map((r) => (!Number.isNaN(parseFloat(r.batchvolume)) ? r.batchvolume : 0)))
    ).toLocaleString(),
  };
  ['sulfur', 'benzene'].forEach((substance) => {
    const subtractor = substance === 'sulfur' ? 10 : 0.62;
    ret[`${substance}average`] =
      _.sum(
        m.map((r) =>
          !Number.isNaN(parseFloat(r.batchvolume)) && !Number.isNaN(parseFloat(r[substance]))
            ? r.batchvolume * r[substance]
            : 0
        )
      ) / _.sum(m.map((r) => (!Number.isNaN(parseFloat(r.batchvolume)) ? r.batchvolume : 0)));
    ret[`${substance}credit`] =
      (_.sum(m.map((r) => (!Number.isNaN(parseFloat(r.batchvolume)) ? r.batchvolume : 0))) *
        (subtractor - ret[`${substance}average`])) /
      100;
    ret[`${substance}average`] = ret[`${substance}average`].toFixed(4);
    ret[`${substance}credit`] = Math.round(ret[`${substance}credit`]).toLocaleString();
  });
  return ret;
}

export async function loadFacility({ selectedFacility, firebase, minDate, maxDate }) {
  if (!selectedFacility) {
    return {};
  }

  if (!facilities[selectedFacility]) {
    facilities[selectedFacility] = {
      table: [],
      flags: {},
    };
    await fetchAggregations(firebase, selectedFacility);
  }

  // notes
  if (!gsf0402[selectedFacility]) {
    const reports = (await firebase.gsf0402ReportByFacility(selectedFacility)).val();

    gsf0402[selectedFacility] = !reports ? [] : Object.values(reports);
  }

  // systemwide Credit Generation table
  const data = gsf0402[selectedFacility]
    // .filter(({ facilityname }) => facilityname === selectedFacility)
    .filter(({ productiondate }) => asMoment(productiondate).isSameOrAfter(minDate))
    .filter(({ productiondate }) => asMoment(productiondate).isSameOrBefore(maxDate))
    .sort((a, b) => asMoment(a.productiondate).valueOf() - asMoment(b.productiondate).valueOf());

  const systemWideCreditGeneration = generateSwcg(data, selectedFacility);

  // set swcg per location
  const fac = facilities[selectedFacility];

  fac.table = [];
  fac.flags = {
    sulfur: 0,
    benzene: 0,
    butane: 0,
  };

  for (const aggType of aggregationsKeys) {
    if (!aggregations[aggType]) {
      console.log(`could not find ${aggType}`);
    } else {
      // get total records per facility
      const fac = facilities[selectedFacility];
      const facilitySet = aggregations[aggType]
        .filter(({ location }) => location === selectedFacility)
        .filter(({ date }) => asMoment(date).isSameOrAfter(minDate, 'day'))
        .filter(({ date }) => asMoment(date).isSameOrBefore(maxDate, 'day'));
      fac.totalRecords = (fac.totalRecords || 0) + (facilitySet ? facilitySet.length : 0);

      fac.flags.sulfur += flagQuantity({ facilitySet, aggType, filter: sulfurFlag });
      fac.flags.benzene += flagQuantity({ facilitySet, aggType, filter: benzeneFlag });
      fac.flags.butane += flagQuantity({ facilitySet, aggType, filter: butaneFlag });
      if (facilitySet && Array.isArray(facilitySet) && facilitySet.length > 0) {
        fac.table.push(...facilitySet);
      }
    } // we have values for this aggregation
  }

  if (systemWideCreditGeneration) {
    fac.swcg = systemWideCreditGeneration;
    fac.flags.benzeneAverage = systemWideCreditGeneration.benzeneaverage > 1.3 ? 1 : 0;
    fac.flags.sulfurAverage = systemWideCreditGeneration.sulfuraverage > 80 ? 1 : 0;
  }

  // set rvp for facility
  if (rvpData[selectedFacility]) {
    fac.rvpTable = rvpData[selectedFacility]
      .filter(({ sampledate }) => asMoment(sampledate).isSameOrAfter(minDate, 'day'))
      .filter(({ sampledate }) => asMoment(sampledate).isSameOrBefore(maxDate, 'day'));
    const summerCount = fac.rvpTable.filter((r) => {
      const dateEntered = moment(r.sampledate, 'M/D/YYYY');
      const deMonth = dateEntered.month() + 1;
      const deDayOfMonth = dateEntered.date();
      return (deMonth >= 5 && deMonth <= 8) || (deMonth === 9 && deDayOfMonth <= 15);
    }).length;
    fac.flags.rvp = fac.rvpTable.length;
    fac.flags.rvpSummer = summerCount;
  }

  // set button colors per facility
  ['sulfur', 'benzene', 'butane', 'sulfurAverage', 'benzeneAverage'].forEach((f) => {
    if (fac.totalRecords <= 0) {
      fac.flags[`${f}Color`] = 'secondary';
    } else if (fac.flags[f] > 0) {
      fac.flags[`${f}Color`] = 'error';
    } else {
      fac.flags[`${f}Color`] = 'success';
    }
  });

  // rvp:
  if (fac.totalRecords <= 0) {
    fac.flags.rvpColor = 'secondary';
  } else if (fac.flags.rvpSummer > 0) {
    fac.flags.rvpColor = 'error';
  } else {
    fac.flags.rvpColor = 'success';
  }

  return facilities[selectedFacility];
}

import React from 'react';
import PropTypes from 'prop-types';
import { CollapsibleRow } from './collapsibleRow';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { PaginationTable } from '../Common/PaginationTable/paginationTable';

const columns = [
  {
    dataField: 'batchid',
    text: 'Batch ID',
    display: true,
  },
  {
    dataField: 'location',
    text: 'Location',
    display: false,
  },
  {
    dataField: 'date',
    text: 'Date',
    display: true,
  },
  {
    dataField: 'blendstock',
    text: 'Blend Stock',
    display: true,
    filterType: 'text',
  },
  {
    dataField: 'productiondate',
    text: 'Production Date',
    display: true,
    filterType: 'date',
  },
  {
    dataField: 'volume',
    text: 'Volume',
    formatter: (cell) => Number(cell).toLocaleString(),
    display: true,
  },
  {
    dataField: 'rvp',
    text: 'RVP',
    display: false,
  },
  {
    dataField: 'sulfur',
    text: 'Sulfur',
    display: false,
  },
  {
    dataField: 'benzene',
    text: 'Benzene',
    display: false,
  },
  {
    dataField: 'aromatics',
    text: 'Aromatics',
    display: false,
  },
  {
    dataField: 'olefins',
    text: 'Olefins',
    display: false,
  },
  {
    dataField: 'e200',
    text: 'E200',
    display: false,
  },
  {
    dataField: 't50',
    text: 'T50',
    display: false,
  },
  {
    dataField: 't90',
    text: 'T90',
    display: false,
  },
  {
    dataField: 'product',
    text: 'Product',
    display: false,
  },
  {
    dataField: 'facilityorigin',
    text: 'Facility Origin',
    display: true,
  },
  {
    dataField: 'totalbutanevolume',
    text: 'Total Butane Vol',
    display: false,
  },
  {
    dataField: 'flag',
    text: 'Flag',
    display: true,
    formatter: (cell) =>
      cell === 'Y' ? <CheckIcon color="success" /> : <CloseIcon color="error" />,
  },
];

const BatchesInfo = ({ facility }) => {
  const { table: rows } = facility;
  return (
    <PaginationTable
      rows={rows}
      columns={columns}
      rowComponent={(row) => <CollapsibleRow row={row} columns={columns} key={row.batchid} />}
      columnFilter={(c) => c.display}
      collapsible
    />
  );
};
BatchesInfo.propTypes = {
  facility: PropTypes.shape({
    table: PropTypes.arrayOf(PropTypes.any),
  }).isRequired,
};

export { BatchesInfo };

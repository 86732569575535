import { FlexibleXYPlot } from './flexibleXYPlot';
import { HorizontalGridLines, LineSeries, VerticalBarSeries, XAxis, YAxis } from 'react-vis';
import moment from 'moment';
import React from 'react';
import { minMaxAndMonths } from './chartHelper';
import PropTypes from 'prop-types';

const SingleFacilityPerformance = ({ chartData, options, substances }) => {
  const { singleFacilityPerformance, months } = chartData;

  const { filteredMonths, min, max } = minMaxAndMonths({ data: singleFacilityPerformance, months });

  return (
    <FlexibleXYPlot
      xType="time"
      height={600}
      xDomain={[min, max]}
      margin={{ bottom: 100 }}
      style={{ width: '100%' }}
    >
      <VerticalBarSeries data={singleFacilityPerformance} />
      <LineSeries
        data={filteredMonths.map((f) => ({
          x: f,
          y: substances[options.substanceIndex] === 'Sulfur' ? 10 : 0.62,
        }))}
      />
      {substances[options.substanceIndex] === 'Benzene' && (
        <LineSeries
          data={filteredMonths.map((f) => ({
            x: f,
            y: 1.3,
          }))}
        />
      )}
      <HorizontalGridLines />
      <XAxis
        tickValues={filteredMonths}
        tickFormat={(v) => moment(v).format('MM/YY')}
        tickLabelAngle={-45}
        style={{ text: { fontSize: 18 } }}
      />
      <YAxis style={{ text: { fontSize: 18 } }} />
    </FlexibleXYPlot>
  );
};

SingleFacilityPerformance.propTypes = {
  chartData: PropTypes.shape({
    singleFacilityPerformance: PropTypes.arrayOf(
      PropTypes.shape({
        x: PropTypes.number,
        y: PropTypes.number,
      })
    ),
    months: PropTypes.arrayOf(PropTypes.number),
  }).isRequired,
  options: PropTypes.shape({
    substanceIndex: PropTypes.number,
  }).isRequired,
  substances: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export { SingleFacilityPerformance };

import React from 'react';
import PropTypes from 'prop-types';
import { Button, ButtonGroup } from '@mui/material';
import './style.css';

const ButtonTabs = ({ tabDisplay, selectedTab, setSelectedTab }) => (
  <ButtonGroup className="button-tabs">
    {tabDisplay.map((tab, index) => (
      <Button
        variant={index !== selectedTab ? 'outlined' : 'contained'}
        color="secondary"
        size="small"
        key={tab.title}
        onClick={() => setSelectedTab(index)}
      >
        {tab.title}
      </Button>
    ))}
  </ButtonGroup>
);

ButtonTabs.propTypes = {
  tabDisplay: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
  selectedTab: PropTypes.number.isRequired,
  setSelectedTab: PropTypes.func.isRequired,
};

export { ButtonTabs };

export const rinsTypes = ['d3OrD7', 'd4', 'd5', 'd6'];

export const rinsStyle = {
  initial: {
    backgroundColor: 'grey',
    color: '#ffffff',
    width: '100%',
  },
  d3OrD7: {
    backgroundColor: '#ff5722',
    color: '#ffffff',
    width: '100%',
  },
  d4: {
    backgroundColor: '#4caf50',
    color: '#000000',
    width: '100%',
  },
  d5: {
    backgroundColor: '#03a9f4',
    color: '#000000',
    width: '100%',
  },
  d6: {
    backgroundColor: '#ffeb3b',
    color: '#000000',
    width: '100%',
  },
};

export const rinsLabels = {
  d4: 'D4 Biomass Based Diesel RINs',
  d6: 'D6 Ethanol RINs',
  d3OrD7: 'D3 or D7 Cellulosic Biofuel RINs',
  d5: 'D5 Advanced Biofuel RINs',
};

export const auditLabels = {
  d3OrD7: 'D3 or D7 Cellulosic',
  d4: 'D4 Biodiesel',
  d5: 'D5 Advanced',
  d6: 'D6 Renewable',
};

import { DebounceInput } from 'react-debounce-input';
import React from 'react';
import { TextField } from '@mui/material';
import PropTypes from 'prop-types';

const FacilityNotes = ({ facilityName, changeNotes, notes }) => {
  return (
    <DebounceInput
      id={`${facilityName}-notes`}
      element={(props) => {
        // eslint-disable-next-line react/jsx-props-no-spreading
        return <TextField {...props} multiline label="notes" />;
      }}
      style={{ width: '100%' }}
      rows="10"
      cols="120"
      debounceTimeout={1000}
      onChange={changeNotes}
      value={notes[facilityName]}
    />
  );
};

FacilityNotes.propTypes = {
  facilityName: PropTypes.string.isRequired,
  changeNotes: PropTypes.func.isRequired,
  notes: PropTypes.string.isRequired,
};

export { FacilityNotes };

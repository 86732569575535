import { dateFilter, numberFilter, textFilter, Comparator } from 'react-bootstrap-table2-filter';

const today = new Date();
const thisMonth = today.getMonth() + 1;

const defaultDateFilter1 = dateFilter({
  defaultValue: {
    date: new Date(today.getFullYear() - 1, thisMonth <= 6 ? 5 : 11, 31),
    comparator: Comparator.GT,
  },
});
dateFilter({
  defaultValue: {
    date: new Date(today.getTime() + 24 * 60 * 60 * 1000),
    comparator: Comparator.LT,
  },
});
const defaultDateFilter3 = dateFilter();

const commonMap = [
  {
    dataField: 'location',
    text: 'Location',
    filter: textFilter(),
    sort: true,
    editable: false,
    display: true,
    filterType: 'text',
  },
  {
    dataField: 'date',
    text: 'Date',
    filter: defaultDateFilter1,
    sort: true,
    editable: false,
    display: true,
    filterType: 'date',
  },
  {
    dataField: 'batchid',
    text: 'Batch ID',
    filter: textFilter(),
    sort: true,
    editable: false,
    display: true,
  },
  {
    dataField: 'blendstock',
    text: 'Blend Stock',
    filter: textFilter(),
    sort: true,
    editable: false,
    display: true,
  },
  {
    dataField: 'productiondate',
    text: 'Production Date',
    filter: defaultDateFilter1,
    sort: true,
    editable: false,
    display: true,
    filterType: 'date',
  },
  {
    dataField: 'volume',
    text: 'Volume',
    filter: numberFilter(),
    sort: true,
    editable: false,
    display: true,
    formatter: (cell) => Number(cell).toLocaleString(),
  },
  {
    dataField: 'rvp',
    text: 'RVP',
    filter: numberFilter(),
    sort: true,
    editable: false,
    display: true,
  },
  {
    dataField: 'sulfur',
    text: 'Sulfur',
    filter: numberFilter(),
    sort: true,
    editable: false,
    display: true,
  },
  {
    dataField: 'benzene',
    text: 'Benzene',
    filter: numberFilter(),
    sort: true,
    editable: false,
    display: true,
  },
  {
    dataField: 'aromatics',
    text: 'Aromatics',
    filter: numberFilter(),
    sort: true,
    editable: false,
    display: true,
  },
  {
    dataField: 'olefins',
    text: 'Olefins',
    filter: numberFilter(),
    sort: true,
    editable: false,
    display: true,
  },
  {
    dataField: 'e200',
    text: 'E200',
    filter: numberFilter(),
    sort: true,
    editable: false,
    display: false,
  },
  {
    dataField: 't50',
    text: 'T50',
    filter: numberFilter(),
    sort: true,
    editable: false,
    display: false,
  },
  {
    dataField: 't90',
    text: 'T90',
    filter: numberFilter(),
    sort: true,
    editable: false,
    display: false,
  },
  {
    dataField: 'product',
    text: 'Product',
    filter: textFilter(),
    sort: true,
    editable: false,
    display: false,
  },
];

const mmp2CommonMap = [
  {
    dataField: 'reporttype',
    text: 'Report Type',
    filter: textFilter(),
    sort: true,
  },
  {
    dataField: 'cbi',
    text: 'CBI',
    filter: textFilter(),
    sort: true,
  },
  {
    dataField: 'reportdate',
    text: 'Report Date',
    filter: defaultDateFilter3,
    sort: true,
  },
  {
    dataField: 'reportyear',
    text: 'Report Year',
    filter: numberFilter(),
    sort: true,
  },
  {
    dataField: 'reporterid',
    text: 'Reporter ID',
    filter: numberFilter(),
    sort: true,
  },
  {
    dataField: 'companyid',
    text: 'Company ID',
    filter: numberFilter(),
    sort: true,
  },
  {
    dataField: 'facilityname',
    text: 'Facility Name',
    filter: textFilter(),
    sort: true,
  },
  {
    dataField: 'facilityid',
    text: 'Facility ID',
    filter: numberFilter(),
    sort: true,
  },
  {
    dataField: 'batchnumber',
    text: 'Batch Number',
    filter: textFilter(),
    sort: true,
  },
  {
    dataField: 'volumetype',
    text: 'Volume Type',
    filter: textFilter(),
    sort: true,
  },
  {
    dataField: 'batchvolume',
    text: 'Batch Volume',
    filter: numberFilter(),
    sort: true,
  },
  {
    dataField: 'productiondate',
    text: 'Production Date',
    filter: defaultDateFilter3,
    sort: true,
  },
  {
    dataField: 'datepbprecd',
    text: 'Date PCG, Butane, or Pentane Batch Received',
    filter: defaultDateFilter3,
    sort: true,
  },
  {
    dataField: 'producttype',
    text: 'Product Type',
    filter: textFilter(),
    sort: true,
  },
  {
    dataField: 'gasolinevolatilitystandards',
    text: 'Gasoline Volatility Standards',
    filter: textFilter(),
    sort: true,
  },
  {
    dataField: 'oxygenate',
    text: 'Oxygenate',
    filter: textFilter(),
    sort: true,
  },
  {
    dataField: 'oxygenateparametertestmethod',
    text: 'Oxygenate Paramter Test Method',
    filter: textFilter(),
    sort: true,
  },
  {
    dataField: 'oxygenatetype',
    text: 'Oxygenate Type',
    filter: textFilter(),
    sort: true,
  },
  {
    id: 'sulfur-pergalloncompliancevalue',
    dataField: 'sulfur-pergalloncompliancevalue',
    text: 'Sulfur - Per Gallson Compliance Value',
    filter: numberFilter(),
    sort: true,
  },
  {
    id: 'sulfur-averagecompliancevalue',
    dataField: 'sulfur-averagecompliancevalue',
    text: 'Sulfur - Average Compliance Value',
    filter: numberFilter(),
    sort: true,
  },
  {
    dataField: 'sulfurparametertestmethod',
    text: 'Sulfur Parameter Test Method',
    filter: textFilter(),
    sort: true,
  },
  {
    dataField: 'benzene',
    text: 'Benzene',
    filter: numberFilter(),
    sort: true,
  },
  {
    dataField: 'benzeneparametertestmethod',
    text: 'Benzene Parameter Test Method',
    filter: textFilter(),
    sort: true,
  },
  {
    id: 'rvpstr0200',
    dataField: 'rvp',
    text: 'RVP',
    filter: numberFilter(),
    sort: true,
  },
  {
    dataField: 'rvpparametertestmethod',
    text: 'RVP Parameter Test Method',
    filter: textFilter(),
    sort: true,
  },
  {
    dataField: 'benzenedeficit',
    text: 'Benzene Deficit',
    filter: textFilter(),
    sort: true,
  },
  {
    dataField: 'sulfurdeficit',
    text: 'Sulfur Deficit',
    filter: textFilter(),
    sort: true,
  },
  {
    dataField: 'butaneorpentane',
    text: 'Butane Or Pentane',
    filter: textFilter(),
    sort: true,
  },
  {
    dataField: 'butaneorpentaneparametertestmethod',
    text: 'Butane Or Pentane Parameter Test Method',
    filter: textFilter(),
    sort: true,
  },
  {
    dataField: 'comments',
    text: 'Comments',
    filter: textFilter(),
    sort: true,
  },
  {
    dataField: 'notes',
    text: 'Notes',
    filter: textFilter(),
    sort: true,
  },
  {
    dataField: 'datasource',
    text: 'Data Source',
    filter: textFilter(),
    sort: true,
  },
  {
    dataField: 'blendstock',
    text: 'Blendstock',
    filter: textFilter(),
    sort: true,
  },
  {
    dataField: 'includedincreditcalc',
    text: 'Included in Credit Calc',
    filter: textFilter(),
    sort: true,
  },
  {
    dataField: 'sampleidblend',
    text: 'Sample ID (Blend)',
    filter: textFilter(),
    sort: true,
  },
  {
    dataField: 'sampleidcbablendstockcert',
    text: 'Sample ID (CBA Blendstock Cert.)',
    filter: textFilter(),
    sort: true,
  },
  {
    dataField: 'certifiedbutanecoasampleid',
    text: 'Certified Butane COA Sample Id',
    filter: textFilter(),
    sort: true,
  },
  {
    dataField: 'bolnumber',
    text: 'BOL Number',
    filter: numberFilter(),
    sort: true,
  },
  {
    dataField: 'facilityorigin',
    text: 'Facility Origin',
    filter: textFilter(),
    sort: true,
  },
  {
    dataField: 'srpsortcode',
    text: 'SRP Sort Code',
    filter: textFilter(),
    sort: true,
  },
];

const columnMapping = {
  8082: commonMap.concat([
    {
      dataField: 'facilityorigin',
      text: 'Facility Origin',
      filter: textFilter(),
      sort: true,
      editable: false,
      filterType: 'text',
    },
    {
      dataField: 'totalbutanevolume',
      text: 'Total Butane Vol',
      filter: numberFilter(),
      sort: true,
      editable: false,
    },
  ]),
  dmb: commonMap,
  cbs: commonMap,
  dmfg: commonMap,
  ehr: commonMap,
  g2: commonMap,
  kcb: commonMap,
  wtb: commonMap,
  rvp: commonMap,
  bgb: mmp2CommonMap,
  cba: mmp2CommonMap,
  cbs2: mmp2CommonMap,
  dm: mmp2CommonMap,
  kc: mmp2CommonMap,
  pcg: mmp2CommonMap,
  tgp: mmp2CommonMap,
};

for (const cm of Object.keys(columnMapping)) {
  for (const c of columnMapping[cm]) {
    if (!c.headerStyle && !c.style) {
      c.headerStyle = {
        minWidth: '135px',
      };
    }
  }
}

export default columnMapping;

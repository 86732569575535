import Row from 'react-bootstrap/Row';
import React, { useEffect, useState } from 'react';
import { FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import moment from 'moment';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import PropTypes from 'prop-types';

const FilterOverview = ({ facilities, substances, updateFilter }) => {
  const today = new Date();
  const thisMonth = today.getMonth() + 1;
  const startDate = new Date(today.getFullYear() - 1, thisMonth <= 6 ? 5 : 11, 31);

  const [facilityIndex, setFacilityIndex] = useState('');
  const [substanceIndex, setSubstanceIndex] = useState(0);
  const [minDate, setMinDate] = useState(moment(startDate));
  const [maxDate, setMaxDate] = useState(moment());

  const submitFilter = () => {
    updateFilter({
      facilityIndex,
      substanceIndex,
      minDate: minDate.valueOf(),
      maxDate: maxDate.valueOf(),
    });
  };

  useEffect(() => {
    submitFilter();
  }, [facilityIndex, substanceIndex, minDate, maxDate]);

  return (
    <Row
      style={{ display: 'flex', justifyContent: 'space-between', gap: '5px', flexWrap: 'nowrap' }}
    >
      <FormControl style={{ width: '100%' }}>
        <InputLabel id="facility-label">Facility</InputLabel>
        <Select
          labelId="facility-label"
          id="facilityIndex"
          label="Facility"
          onChange={(event) => setFacilityIndex(event.target.value)}
          value={facilityIndex}
        >
          <MenuItem value="">All</MenuItem>
          {facilities.map((f, index) => (
            <MenuItem key={`${f}-item`} value={index}>
              {f}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <FormControl style={{ width: '100%' }}>
        <InputLabel id="substance-label">Substance</InputLabel>
        <Select
          labelId="substance-label"
          id="substanceIndex"
          label="Substance"
          onChange={(event) => setSubstanceIndex(event.target.value)}
          value={substanceIndex}
        >
          {substances.map((f, index) => (
            <MenuItem key={`${f}-menu-item`} value={index}>
              {f}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl style={{ width: '100%' }}>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <DatePicker
            style={{ width: '100%' }}
            value={minDate}
            onChange={(date) => setMinDate(date)}
            maxDate={maxDate}
            label="From"
            renderInput={(params) => {
              // eslint-disable-next-line react/jsx-props-no-spreading
              return <TextField {...params} />;
            }}
          />
        </LocalizationProvider>
      </FormControl>

      <FormControl style={{ width: '100%' }}>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <DatePicker
            style={{ width: '100%' }}
            value={maxDate}
            label="To"
            onChange={(date) => setMaxDate(date)}
            minDate={minDate}
            renderInput={(params) => {
              // eslint-disable-next-line react/jsx-props-no-spreading
              return <TextField {...params} />;
            }}
          />
        </LocalizationProvider>
      </FormControl>
    </Row>
);
};

FilterOverview.propTypes = {
  facilities: PropTypes.arrayOf(PropTypes.string).isRequired,
  substances: PropTypes.arrayOf(PropTypes.string).isRequired,
  updateFilter: PropTypes.func.isRequired,
};

export { FilterOverview };

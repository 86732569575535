import { Backdrop, CircularProgress } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';

const Loader = ({ isLoading }) => (
  <Backdrop sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoading}>
    <CircularProgress color="primary" />
  </Backdrop>
);

Loader.propTypes = {
  isLoading: PropTypes.bool.isRequired,
};

export { Loader };

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DatePicker } from '@mui/x-date-pickers';
import moment from 'moment';
import { TextField } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';

const DateInput = ({ onChange, value, label }) => (
  <LocalizationProvider dateAdapter={AdapterMoment}>
    <DatePicker
      label={label}
      onChange={(value) => {
        const formattedDate = moment(value).format('MM/DD/YYYY');
        onChange(formattedDate);
      }}
      value={value}
      renderInput={(params) => {
        // eslint-disable-next-line react/jsx-props-no-spreading
        return <TextField {...params} />;
      }}
      style={{ width: '100%' }}
    />
  </LocalizationProvider>
);

DateInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};

export { DateInput };

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import Firebase, { withFirebase } from '../Firebase';
import Spinner from '../Spinner';
import PropTypes from 'prop-types';

class UserItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
    };
  }

  componentDidMount() {
    const { match, onSetUser, user, firebase } = this.props;
    if (!user) {
      this.setState({ loading: true });
    }

    firebase.user(match.params.id).on('value', (snapshot) => {
      onSetUser(snapshot.val(), match.params.id);

      this.setState({ loading: false });
    });
  }

  componentWillUnmount() {
    const { match, firebase } = this.props;
    firebase.user(match.params.id).off();
  }

  onSendPasswordResetEmail = () => {
    const { user, firebase } = this.props;
    firebase.doPasswordReset(user.email);
  };

  render() {
    const { user, match } = this.props;
    const { loading } = this.state;

    return (
      <div>
        <h2>User ({match.params.id})</h2>
        {loading && <Spinner loading={loading} />}

        {user && (
          <div>
            <span>
              <strong>ID:</strong> {user.uid}
            </span>
            <span>
              <strong>E-Mail:</strong> {user.email}
            </span>
            <span>
              <strong>Username:</strong> {user.username}
            </span>
            <span>
              <button type="button" onClick={this.onSendPasswordResetEmail}>
                Send Password Reset
              </button>
            </span>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state, props) => ({
  user: (state.userState.users || {})[props.match.params.id],
});

const mapDispatchToProps = (dispatch) => ({
  onSetUser: (user, uid) => dispatch({ type: 'USER_SET', user, uid }),
});

UserItem.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }).isRequired,
  onSetUser: PropTypes.func.isRequired,
  user: PropTypes.shape({
    uid: PropTypes.string,
    email: PropTypes.string,
    username: PropTypes.string,
  }),
  firebase: PropTypes.instanceOf(Firebase).isRequired,
};

UserItem.defaultProps = {
  user: null,
};

export default compose(withFirebase, connect(mapStateToProps, mapDispatchToProps))(UserItem);

export const minMaxAndMonths = ({ data, months }) => {
  const filteredMonths = data
    ?.map((d) => months.includes(d.x) ? d.x : null)
    .filter((d) => d !== null)
    .sort((a, b) => a - b) ?? [];

  const min = filteredMonths?.[0] ?? 0;
  const max = filteredMonths?.[filteredMonths.length - 1] ?? 1000;
  return { filteredMonths, min, max };
};

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import { withAuthorization, withEmailVerification } from '../Session';
import { withFirebase } from '../Firebase';
import { PasswordForgetForm } from '../PasswordForget';
import PasswordChangeForm from '../PasswordChange';
import PropTypes from 'prop-types';

const AccountPage = ({ authUser }) => (
  <div>
    <h1>
      Account:
      {authUser.email}
    </h1>
    <PasswordForgetForm />
    <PasswordChangeForm />
    <LoginManagement authUser={authUser} />
  </div>
);

class LoginManagementBase extends Component {
  constructor(props) {
    super(props);

    this.state = {
      error: null,
    };
  }

  componentDidMount() {}

  render() {
    const { error } = this.state;

    return <div>{error && error.message}</div>;
  }
}

AccountPage.propTypes = {
  authUser: PropTypes.shape({ email: PropTypes.string.isRequired }).isRequired,
};
const LoginManagement = withFirebase(LoginManagementBase);

const mapStateToProps = (state) => ({
  authUser: state.sessionState.authUser,
});

const condition = (authUser) => !!authUser;

export default compose(
  connect(mapStateToProps),
  withEmailVerification,
  withAuthorization(condition)
)(AccountPage);

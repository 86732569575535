import { dateFilter, numberFilter, textFilter, Comparator } from 'react-bootstrap-table2-filter';

const today = new Date();
const thisMonth = today.getMonth() + 1;

const defaultDateFilter1 = dateFilter({
  defaultValue: {
    date: new Date(today.getFullYear() - 1, thisMonth <= 6 ? 5 : 11, 31),
    comparator: Comparator.GT,
  },
});


const defaultSmallCol = {
  style: {
    maxWidth: '135px',
    minWidth: '135px',
  },
  headerStyle: {
    maxWidth: '135px',
    minWidth: '135px',
    textAlign: 'center',
  },
  display:false
};

const defaultMediumCol = {
  style: {
    maxWidth: '210px',
    minWidth: '210px',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  headerStyle: {
    maxWidth: '210px',
    minWidth: '210px',
    textAlign: 'center',
  },
};

const defaultLargeCol = {
  style: {
    maxWidth: '300px',
    minWidth: '300px',
  },
  headerStyle: {
    maxWidth: '300px',
    minWidth: '300px',
    textAlign: 'center',
  },
};

const commonMap = [
  {
    dataField: 'sampleid',
    text: 'Sample ID',
    filter: textFilter(),
    sort: true,
    editable: false,
    ...defaultSmallCol,
    display: true
  },
  {
    dataField: 'sampledate',
    text: 'Sample Date',
    filter: defaultDateFilter1,
    sort: true,
    editable: false,
    ...defaultMediumCol,
    display: true,
    filterType: 'date'
  },
  // {
  //   dataField: 'sampledate2',
  //   text: 'Sample Date (End)',
  //   filter: defaultDateFilter2,
  //   sort: true,
  //   editable: false,
  //   ...defaultMediumCol,
  // },
  {
    dataField: 'location',
    text: 'Location',
    filter: textFilter(),
    sort: true,
    editable: false,
    ...defaultMediumCol,
    display: true,
    filterType: 'text'
  },
  {
    dataField: 'tank_line',
    text: 'Tank Line',
    filter: textFilter(),
    sort: true,
    editable: false,
    ...defaultMediumCol,
    display: true
  },
  {
    dataField: 'product',
    text: 'Product',
    filter: textFilter(),
    sort: true,
    editable: false,
    ...defaultSmallCol,
    display: true,
    filterType: 'text'
  },
  {
    dataField: 'remarks',
    text: 'Remarks',
    filter: textFilter(),
    sort: true,
    editable: false,
    ...defaultMediumCol,

  },
  {
    dataField: 'afterrvp',
    text: 'After RVP',
    filter: numberFilter(),
    sort: true,
    editable: false,
    ...defaultSmallCol,
    display: true
  },
  {
    dataField: 'aftersulfur',
    text: 'After Sulfur',
    filter: numberFilter(),
    sort: true,
    editable: false,
    ...defaultSmallCol,
  },
  {
    dataField: 'beforervp',
    text: 'Before RVP',
    filter: numberFilter(),
    sort: true,
    editable: false,
    ...defaultSmallCol,
    display: true
  },
  {
    dataField: 'beforesulfur',
    text: 'Before Sulfur',
    filter: numberFilter(),
    sort: true,
    editable: false,
    ...defaultSmallCol,
  },
  {
    dataField: 'cbsblend',
    text: 'CBS Blend',
    filter: textFilter(),
    sort: true,
    editable: false,
    ...defaultSmallCol,
  },
  {
    dataField: 'correspondingsample',
    text: 'Corresponding Sample',
    filter: textFilter(),
    sort: true,
    editable: false,
    ...defaultSmallCol,
  },
  {
    dataField: 'endpoint',
    text: 'End Point',
    filter: numberFilter(),
    sort: true,
    editable: false,
    ...defaultSmallCol,
    display: true
  },
  {
    dataField: 'fracgasbarrelsblended',
    text: 'Frac Gas Barrels Blended',
    filter: numberFilter(),
    sort: true,
    editable: false,
    ...defaultSmallCol,
  },
  {
    dataField: 'gasbarrelsafterblend',
    text: 'Gas Barrels After Blend',
    filter: numberFilter(),
    sort: true,
    editable: false,
    ...defaultSmallCol,
  },
  {
    dataField: 'gasbarrelsbeforeblend',
    text: 'Gas Barrels Before Blend',
    filter: numberFilter(),
    sort: true,
    editable: false,
    ...defaultSmallCol,
  },
  {
    dataField: 'hbbls',
    text: 'H BBLS',
    filter: numberFilter(),
    sort: true,
    editable: false,
    ...defaultSmallCol,
  },
  {
    dataField: 'spherecertificationsample',
    text: 'Sphere Certification Sample',
    filter: textFilter(),
    sort: true,
    editable: false,
    ...defaultSmallCol,
    display: true
  },
  {
    dataField: 'splitmonthblend',
    text: 'Split Month Blend',
    filter: textFilter(),
    sort: true,
    editable: false,
    ...defaultSmallCol,
  },
  {
    dataField: 'tmixbarrelsblended',
    text: 'T-Mix Barrels Blended',
    filter: numberFilter(),
    sort: true,
    editable: false,
    ...defaultSmallCol,
  },
  {
    dataField: 'targetrvp',
    text: 'Target RVP',
    filter: numberFilter(),
    sort: true,
    editable: false,
    ...defaultSmallCol,
  },
  {
    dataField: 'wbbls',
    text: 'W BBLS',
    filter: numberFilter(),
    sort: true,
    editable: false,
    ...defaultSmallCol,
  },
];

const columnMapping = {
  cbs: commonMap.concat([
    {
      dataField: 'beforearomatics(lab)',
      text: 'Before Aromatics (Lab)',
      filter: numberFilter(),
      sort: true,
      editable: false,
      ...defaultSmallCol,
    },
    {
      dataField: 'beforebenzene(lab)',
      text: 'Before Benzene (Lab)',
      filter: numberFilter(),
      sort: true,
      editable: false,
      ...defaultSmallCol,
    },
    {
      dataField: 'beforee200(lab)',
      text: 'Before E200 (Lab)',
      filter: numberFilter(),
      sort: true,
      editable: false,
      ...defaultSmallCol,
    },
    {
      dataField: 'beforee300(lab)',
      text: 'Before E300 (Lab)',
      filter: numberFilter(),
      sort: true,
      editable: false,
      ...defaultSmallCol,
    },
    {
      dataField: 'beforegravity(laballlevel)',
      text: 'Before Gravity (Lab All Level)',
      filter: numberFilter(),
      sort: true,
      editable: false,
      ...defaultSmallCol,
    },
    {
      dataField: 'beforeolefins(lab)',
      text: 'Before Olefins (Lab)',
      filter: numberFilter(),
      sort: true,
      editable: false,
      ...defaultSmallCol,
    },
    {
      dataField: 'beforesulfur(lab)',
      text: 'Before Sulfur (Lab)',
      filter: numberFilter(),
      sort: true,
      editable: false,
      ...defaultSmallCol,
    },
    {
      dataField: 'beforet50(lab)',
      text: 'Before T50 (Lab)',
      filter: numberFilter(),
      sort: true,
      editable: false,
      ...defaultSmallCol,
    },
    {
      dataField: 'beforet90(lab)',
      text: 'Before T90 (Lab)',
      filter: numberFilter(),
      sort: true,
      editable: false,
      ...defaultSmallCol,
    },
    {
      dataField: 'afteraromatics(lab)',
      text: 'After Aromatics (Lab)',
      filter: numberFilter(),
      sort: true,
      editable: false,
      ...defaultSmallCol,
    },
    {
      dataField: 'afterbenzene(lab)',
      text: 'After Benzene (Lab)',
      filter: numberFilter(),
      sort: true,
      editable: false,
      ...defaultSmallCol,
    },
    {
      dataField: 'aftere200(lab)',
      text: 'After E200 (Lab)',
      filter: numberFilter(),
      sort: true,
      editable: false,
      ...defaultSmallCol,
    },
    {
      dataField: 'aftere300(lab)',
      text: 'After E300 (Lab)',
      filter: numberFilter(),
      sort: true,
      editable: false,
      ...defaultSmallCol,
    },
    {
      dataField: 'aftergravity(laballlevel)',
      text: 'After Gravity (Lab All Level)',
      filter: numberFilter(),
      sort: true,
      editable: false,
      ...defaultSmallCol,
    },
    {
      dataField: 'afterolefins(lab)',
      text: 'After Olefins (Lab)',
      filter: numberFilter(),
      sort: true,
      editable: false,
      ...defaultSmallCol,
    },
    {
      dataField: 'aftersulfur(lab)',
      text: 'After Sulfur (Lab)',
      filter: numberFilter(),
      sort: true,
      editable: false,
      ...defaultSmallCol,
    },
    {
      dataField: 'aftert50(lab)',
      text: 'After T50 (Lab)',
      filter: numberFilter(),
      sort: true,
      editable: false,
      ...defaultSmallCol,
    },
    {
      dataField: 'aftert90(lab)',
      text: 'After T90 (Lab)',
      filter: numberFilter(),
      sort: true,
      editable: false,
      ...defaultSmallCol,
    },
  ]),
  ehr: commonMap.concat([
    {
      dataField: 'sulfur',
      text: 'Sulfur',
      filter: numberFilter(),
      sort: true,
      editable: false,
      ...defaultSmallCol,
    },
    {
      dataField: 'benzene',
      text: 'Benzene',
      filter: numberFilter(),
      sort: true,
      editable: false,
      ...defaultSmallCol,
    },
    {
      dataField: 'producttype',
      text: 'ProductType',
      filter: textFilter(),
      sort: true,
      editable: false,
      ...defaultSmallCol,
    },
  ]),
  g2: commonMap.concat([
    {
      dataField: 'newsampleid',
      text: 'Sample ID Used',
      filter: numberFilter(),
      sort: true,
      editable: false,
      ...defaultSmallCol,
    },
    {
      dataField: 'rvph',
      text: 'RVP H',
      filter: numberFilter(),
      sort: true,
      editable: false,
      ...defaultSmallCol,
    },
    {
      dataField: 'sulfurppmww',
      text: 'Sulfur PPM W/W',
      filter: numberFilter(),
      sort: true,
      editable: false,
      ...defaultSmallCol,
    },
    {
      dataField: 'benzenevol',
      text: 'Benzene Vol %',
      filter: numberFilter(),
      sort: true,
      editable: false,
      ...defaultSmallCol,
    },
    {
      dataField: 'olefinsvol',
      text: 'Olefins Vol %',
      filter: numberFilter(),
      sort: true,
      editable: false,
      ...defaultSmallCol,
    },
    {
      dataField: 'aromaticsvol',
      text: 'Aromatics Vol %',
      filter: numberFilter(),
      sort: true,
      editable: false,
      ...defaultSmallCol,
    },
  ]),
  dmfg: commonMap.concat([
    {
      dataField: 'sulfur(localresults)',
      text: 'Sulfur (Local Results)',
      filter: numberFilter(),
      sort: true,
      editable: false,
      ...defaultSmallCol,
    },
    {
      dataField: 'rvp(local)',
      text: 'RVP (Local)',
      filter: numberFilter(),
      sort: true,
      editable: false,
      ...defaultSmallCol,
    },
    {
      dataField: 'e200(lab)',
      text: 'E200 (Lab)',
      filter: numberFilter(),
      sort: true,
      editable: false,
      ...defaultSmallCol,
    },
    {
      dataField: 'e300(lab)',
      text: 'E300 (Lab)',
      filter: numberFilter(),
      sort: true,
      editable: false,
      ...defaultSmallCol,
    },
    {
      dataField: 't50(lab)',
      text: 'T50 (Lab)',
      filter: numberFilter(),
      sort: true,
      editable: false,
      ...defaultSmallCol,
    },
    {
      dataField: 't90(lab)',
      text: 'T90 (Lab)',
      filter: numberFilter(),
      sort: true,
      editable: false,
      ...defaultSmallCol,
    },
    {
      dataField: 'aromatics(lab)',
      text: 'Aromatics (Lab)',
      filter: numberFilter(),
      sort: true,
      editable: false,
      ...defaultSmallCol,
    },
    {
      dataField: 'olefins(lab)',
      text: 'Olefins (Lab)',
      filter: numberFilter(),
      sort: true,
      editable: false,
      ...defaultSmallCol,
    },
    {
      dataField: 'benzene(lab)',
      text: 'Benzene (Lab)',
      filter: numberFilter(),
      sort: true,
      editable: false,
      ...defaultSmallCol,
    },
  ]),
  dmb: commonMap.concat([
    {
      dataField: 'rvph',
      text: 'RVP H',
      filter: numberFilter(),
      sort: true,
      editable: false,
      ...defaultSmallCol,
    },
    {
      dataField: 'benzenevol',
      text: 'Benzene Vol %',
      filter: numberFilter(),
      sort: true,
      editable: false,
      ...defaultSmallCol,
    },
    {
      dataField: 'olefinsvol',
      text: 'Olefins Vol %',
      filter: numberFilter(),
      sort: true,
      editable: false,
      ...defaultSmallCol,
    },
    {
      dataField: 'aromaticsvol',
      text: 'Aromatics Vol %',
      filter: numberFilter(),
      sort: true,
      editable: false,
      ...defaultSmallCol,
    },
    {
      dataField: 'sulfurppmww',
      text: 'Sulfur PPM W/W',
      filter: numberFilter(),
      sort: true,
      editable: false,
      ...defaultSmallCol,
    },
    {
      dataField: 'sulfurspot',
      text: 'Sulfur From Spot',
      filter: numberFilter(),
      sort: true,
      editable: false,
      ...defaultSmallCol,
    },
    {
      dataField: 'chosensulfur',
      text: 'Chosen Sulfur',
      filter: numberFilter(),
      sort: true,
      editable: false,
      ...defaultSmallCol,
    },
  ]),
  kcb: commonMap.concat([
    {
      dataField: 'rvph',
      text: 'RVP H',
      filter: numberFilter(),
      sort: true,
      editable: false,
      ...defaultSmallCol,
    },
    {
      dataField: 'benzenevol',
      text: 'Benzene Vol %',
      filter: numberFilter(),
      sort: true,
      editable: false,
      ...defaultSmallCol,
    },
    {
      dataField: 'olefinsvol',
      text: 'Olefins Vol %',
      filter: numberFilter(),
      sort: true,
      editable: false,
      ...defaultSmallCol,
    },
    {
      dataField: 'aromaticsvol',
      text: 'Aromatics Vol %',
      filter: numberFilter(),
      sort: true,
      editable: false,
      ...defaultSmallCol,
    },
    {
      dataField: 'sulfurppmww',
      text: 'Sulfur PPM W/W',
      filter: numberFilter(),
      sort: true,
      editable: false,
      ...defaultSmallCol,
    },
    {
      dataField: 'sulfurspot',
      text: 'Sulfur From Spot',
      filter: numberFilter(),
      sort: true,
      editable: false,
      ...defaultSmallCol,
    },
    {
      dataField: 'chosensulfur',
      text: 'Chosen Sulfur',
      filter: numberFilter(),
      sort: true,
      editable: false,
      ...defaultSmallCol,
    },
  ]),
  8082: [
    {
      dataField: 'bolid',
      text: 'BOL ID',
      filter: textFilter(),
      sort: true,
      editable: false,
      ...defaultSmallCol,
      display: true
    },
    {
      dataField: 'bolnumber',
      text: 'BOL Number',
      filter: numberFilter(),
      sort: true,
      editable: false,
      ...defaultSmallCol,
      display: true
    },
    {
      dataField: 'version',
      text: 'Version',
      filter: textFilter(),
      sort: true,
      editable: false,
      ...defaultSmallCol,
      display: true
    },
    {
      dataField: 'bolstartdate',
      text: 'BOL Start Date',
      filter: defaultDateFilter1,
      sort: true,
      editable: false,
      ...defaultSmallCol,
      display: true,
      filterType: 'date'
    },
    // {
    //   dataField: 'bolstartdate2',
    //   text: 'BOL Start Date (End)',
    //   filter: defaultDateFilter2,
    //   sort: true,
    //   editable: false,
    //   ...defaultSmallCol,
    // },
    {
      dataField: 'location',
      text: 'Location',
      filter: textFilter(),
      sort: true,
      editable: false,
      ...defaultSmallCol,
      display: true,
      filterType: 'text'

    },
    {
      dataField: 'facilityorigin',
      text: 'Facility Origin',
      filter: textFilter(),
      sort: true,
      editable: false,
      ...defaultSmallCol,
    },
    {
      dataField: 'bolnetvolume',
      text: 'BOL Net Volume',
      filter: numberFilter(),
      sort: true,
      editable: false,
      ...defaultSmallCol,
    },
    {
      dataField: 'product',
      text: 'Product',
      filter: textFilter(),
      sort: true,
      editable: false,
      ...defaultSmallCol,
    },
    {
      dataField: 'rowStatus',
      text: 'Status',
      filter: textFilter(),
      sort: true,
      editable: false,
      ...defaultSmallCol,
    },
    {
      dataField: 'sampleid',
      text: 'Sample ID',
      filter: numberFilter(),
      sort: true,
      editable: false,
      ...defaultSmallCol,
    },
    {
      dataField: 'equipment',
      text: 'Equipment',
      filter: textFilter(),
      sort: true,
      editable: false,
      ...defaultSmallCol,
    },
    {
      dataField: 'sampleequipment',
      text: 'Sample Equipment',
      filter: textFilter(),
      sort: true,
      editable: false,
      ...defaultSmallCol,
    },
    {
      dataField: 'sampleproduct',
      text: 'Sample Product',
      filter: textFilter(),
      sort: true,
      editable: false,
      ...defaultSmallCol,
    },
    {
      dataField: 'dateunloaded',
      text: 'Date Unloaded',
      filter: defaultDateFilter1,
      sort: true,
      editable: false,
      ...defaultSmallCol,
      display: true,
      filterType: 'date'
    },
    {
      dataField: 'aromaticsvol',
      text: 'Aromatics Vol',
      filter: numberFilter(),
      sort: true,
      editable: false,
      ...defaultSmallCol,
    },
    {
      dataField: 'benzenevol',
      text: 'Benzene Vol',
      filter: numberFilter(),
      sort: true,
      editable: false,
      ...defaultSmallCol,
    },
    {
      dataField: 'olefinsvol',
      text: 'Olefins Vol',
      filter: numberFilter(),
      sort: true,
      editable: false,
      ...defaultSmallCol,
    },
    {
      dataField: 'rvph',
      text: 'RVP H',
      filter: numberFilter(),
      sort: true,
      editable: false,
      ...defaultSmallCol,
    },
    {
      dataField: 'sulfurppmww',
      text: 'Sulfur ppm w/w',
      filter: numberFilter(),
      sort: true,
      editable: false,
      ...defaultSmallCol,
    },
    {
      dataField: 'totalbutanevolume',
      text: 'Total Butane Vol',
      filter: numberFilter(),
      sort: true,
      editable: false,
      ...defaultSmallCol,
    },
    {
      dataField: 'afterrvp',
      text: 'After RVP',
      filter: numberFilter(),
      sort: true,
      editable: false,
      ...defaultSmallCol,
    },
    {
      dataField: 'targetrvp',
      text: 'Target RVP',
      filter: numberFilter(),
      sort: true,
      editable: false,
      ...defaultSmallCol,
    },
  ],
  rvp: commonMap,
  wtb: commonMap,
};

for (const cm of Object.keys(columnMapping)) {
  for (const c of columnMapping[cm]) {
    if (!c.headerStyle && !c.style) {
      c.headerStyle = {
        minWidth: '135px',
      };
    }
  }
}

export { columnMapping, defaultSmallCol, defaultMediumCol, defaultLargeCol };

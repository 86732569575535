import React, { useEffect, useRef, useState } from 'react';
import Firebase, { withFirebase } from '../Firebase';
import { compose } from 'recompose';
import { withAuthorization, withEmailVerification } from '../Session';
import { withRouter } from 'react-router-dom';
import { ButtonTabs } from '../Common/ButtonTabs/buttonTabs';
import { Button, TextField } from '@mui/material';
import { Transactions } from './transactions/Transactions';
import { RVOAuditCheck } from './RVO_AC/RVOAuditCheck';
import { RVOTP } from './RVO_TP/RVOTP';
import { DropDown } from './DropDown';
import PropTypes from 'prop-types';
import * as XLSX from 'xlsx';
import { read } from 'xlsx';
import { Loader } from './Loader';
import { invalidYears } from './InvalidYears';

class RinInfo {
  constructor(row) {
    if (row[4]) {
      this.cost = row[4];
      this.type = 'd6';
      return;
    }
    if (row[5]) {
      this.cost = row[5];
      this.type = 'd3OrD7';
      return;
    }
    if (row[6]) {
      this.cost = row[6];
      this.type = 'd4';
      return;
    }

    if (row[7]) {
      this.cost = row[7];
      this.type = 'd5';
      return;
    }
    throw new Error('Invalid RIN type');
  }
}

function valueOrEmpty(value) {
  return value === undefined || value === null ? '' : value;
}

class Transaction {
  constructor(row, executed) {
    this.executed = executed;
    this.contractDate = excelDateToJsDate(row[1]);
    this.tradePartner = valueOrEmpty(row[2]);
    this.buySell = valueOrEmpty(row[3]);
    this.rinInfo = new RinInfo(row);
    this.rinYear = valueOrEmpty(row[8]);
    this.type = valueOrEmpty(row[9]);
    this.price = valueOrEmpty(row[13]);
    this.raDealNumber = valueOrEmpty(row[15]);
    if (executed) {
      const regex = /Deal (\d{1,2}\/\d{1,2}\/\d{4})/;
      const match = row[14].match(regex);
      if (match) {
        this.transactionDate = match[1];
      }
    } else {
      this.expectedDelivery = valueOrEmpty(row[14]);
    }

    this.cost = Math.round(this.rinInfo.cost * this.price);
    this.id = row[17];
  }
}

class Tracking {
  constructor(row) {
    this.production = row[0];
    this.obtainedFrom = row[2];
    this.date = excelDateToJsDate(row[1]);
  }
}

const excelDateToJsDate = (excelDate) => {
  if (typeof excelDate !== 'number' || Number.isNaN(excelDate)) {
    throw new Error('Invalid date format');
  }
  const excelEpoch = new Date(Date.UTC(1899, 11, 31)); // Excel epoch is December 31, 1899
  const millisecondsPerDay = 24 * 60 * 60 * 1000;
  const date = new Date(excelEpoch.getTime() + excelDate * millisecondsPerDay);

  const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
  return date.toLocaleDateString('en-US', options);
};

const years = [];
for (let i = 2020; i <= new Date().getFullYear() + 1; i += 1) {
  years.push({ value: i, label: i });
}

function mapTransactions(workbook) {
  const transactionsByYear = workbook.SheetNames.filter((name) =>
    name.includes('Transactions')
  ).map((name) => ({ name, year: `20${name.split(' ')[0]}` }));

  return transactionsByYear
    .map((ty) => {
      const worksheet = workbook.Sheets[ty.name];
      const rows = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
      let executed = false;
      const transactions = rows
        .slice(1)
        .map((row) => {
          try {
            return new Transaction(row, executed);
          } catch (e) {
            if (!executed) {
              executed = [row[4], row[5], row[6], row[7]].every((r) => r >= 0);
            }
            return null;
          }
        })
        .filter((t) => t !== null);
      return { [ty.year]: [...transactions] };
    })
    .reduce((acc, curr) => ({ ...acc, ...curr }), {});
}

function mapTracking(workbook) {
  const trackingByYear = workbook.SheetNames.filter((name) =>
    name.includes('Production')
  ).map((name) => ({ name, year: `20${name.split(' ')[0]}` }));

  return trackingByYear
    .map((ty) => {
      const worksheet = workbook.Sheets[ty.name];
      const rows = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
      const transactions = rows
        .slice(1)
        .map((row) => {
          try {
            return new Tracking(row);
          } catch (e) {
            return null;
          }
        })
        .filter((t) => t !== null);
      return { [ty.year]: [...transactions] };
    })
    .reduce((acc, curr) => ({ ...acc, ...curr }), {});
}

const RinsPage = ({ firebase }) => {
  const [year, setYear] = useState(2023);
  const [company, setCompany] = useState('mas');
  const [selectedTab, setSelectedTab] = useState(0);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});
  const fileInputRef = useRef(null);
  const dataRef = useRef(null);

  useEffect(() => {
    firebase.rinListener({ year, company, dataRef, listener: setData });
  }, [year, company, firebase]);

  const handleUploadButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    // eslint-disable-next-line no-undef
    const reader = new FileReader();

    reader.onload = (e) => {
      setLoading(true);
      const data = new Uint8Array(e.target.result);
      const workbook = read(data, { type: 'array' });

      const transactions = mapTransactions(workbook);

      const tracking = mapTracking(workbook);

      Promise.all([
        firebase.newRins({ company, transactions }),
        firebase.newTracking({ company, tracking }),
      ]).finally(() => setLoading(false));
    };

    reader.readAsArrayBuffer(file);
  };

  const tabDisplay = [
    {
      title: 'Transactions',
      component: <Transactions year={year} company={company} firebase={firebase} data={data} />,
    },
    {
      title: 'RVO Tracking/Projection',
      component: <RVOTP company={company} firebase={firebase} year={year} data={data} />,
    },
    {
      title: 'RVO Audit/Check',
      component: <RVOAuditCheck year={year} company={company} firebase={firebase} data={data} />,
    },
  ];

  const companies = [
    { label: 'MAS', value: 'mas' },
    { label: 'MPL', value: 'mpl' },
    { label: 'Powder Springs', value: 'powderSprings' },
  ];

  const errorYear = invalidYears(year) ? 'Invalid Year' : null;

  const printReport = () => {
    // eslint-disable-next-line no-undef
    localStorage.setItem('rin-report', JSON.stringify({ ...data, year, company }));
    // eslint-disable-next-line no-undef
    window.open('/report/rins', '_blank');
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '1.2rem' }}>
      <h1>RINS Tracking Tool</h1>
      <ButtonTabs
        tabDisplay={tabDisplay}
        selectedTab={selectedTab}
        setSelectedTab={(tab) => setSelectedTab(tab)}
      />

      <div style={{ display: 'flex', gap: '1rem' }}>
        <TextField
          error={!!errorYear}
          fullWidth
          onChange={(y) => setYear(Number(y.target.value))}
          value={Number(year)}
          label="Compliance Year"
          type="number"
          helperText={errorYear}
        />

        <DropDown
          onChange={(c) => setCompany(c)}
          value={company}
          label="Company"
          options={companies}
        />
      </div>

      {tabDisplay[selectedTab].component}

      <div style={{ marginBottom: '1rem', display: 'flex', justifyContent: 'space-around' }}>
        <input
          type="file"
          ref={fileInputRef}
          style={{ display: 'none' }}
          accept=".xlsx"
          onChange={handleFileUpload}
        />
        <Button variant="contained" onClick={handleUploadButtonClick}>
          Add From File
        </Button>
        <Button variant="contained" onClick={printReport}>
          Print Report
        </Button>
      </div>
      <Loader isLoading={loading || data?.updateRvo} />
    </div>
  );
};

RinsPage.propTypes = {
  firebase: PropTypes.instanceOf(Firebase).isRequired,
};

const condition = (authUser) => !!authUser;

const RinsPageFB = withFirebase(RinsPage);

export default compose(
  withEmailVerification,
  withAuthorization(condition),
  withRouter,
  withFirebase
)(RinsPageFB);

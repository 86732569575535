import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { CustomTable } from '../CustomTable';
import { Collapsible } from '../Collapsible';
import { TransactionForm } from './TransactionForm';
import { rinsLabels, rinsStyle, rinsTypes } from '../RinsConstants';
import { Chip, IconButton, Tooltip } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import moment from 'moment';
import { RightPanel } from '../../Common/Panels/rightPanel';
import { Loader } from '../Loader';
import { FabAddIcon } from '../FabAddIcon';
import Firebase from '../../Firebase';

const mapTransactions = (data) => {
  const transactions = data?.transactions || {};

  return (
    Object.keys(transactions).map((id) => ({
      ...transactions[id],
      cost: transactions[id].price * transactions[id].rinInfo.cost,
    })) || []
  );
};

const Transactions = ({ year, company, firebase, data }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [enabledRins, setEnabledRins] = useState(rinsTypes);
  // const [rows, setRows] = useState([]);
  const [openModal, setOpenModal] = useState(false);

  const [rowToUpdate, setRowToUpdate] = useState(false);

  const rows = mapTransactions(data);

  const headers = (executed) => [
    { label: 'Executed', key: 'executed', type: 'boolean' },
    { label: 'Unique ID', key: 'id' },
    { label: 'Transaction Date', key: 'transactionDate', hide: !executed },
    { label: 'Trade Partner', key: 'tradePartner' },
    { label: 'Buy/Sell', key: 'buySell' },
    { label: 'RIN', key: 'rinInfo' },
    { label: 'Rin Year', key: 'rinYear' },
    { label: 'Type', key: 'type' },
    { label: 'Price', key: 'price' },
    { label: 'Contract Date', key: 'contractDate' },
    { label: 'Expected Delivery', key: 'expectedDelivery', hide: executed },
    { label: 'RA Deal Number', key: 'raDealNumber' },
    { label: 'Cost', key: 'cost', format: (val) => Math.round(val) },
    { label: 'Notes', key: 'notes' },
    {
      label: 'Actions',
      key: 'actions',
      component: (row) => (
        <div style={{ display: 'flex' }}>
          <Tooltip title="Edit">
            <IconButton aria-label="edit" size="small" onClick={() => changeRow(row)}>
              <EditIcon />
            </IconButton>
          </Tooltip>
          {!executed && (
            <Tooltip title="Delete">
              <IconButton aria-label="delete" size="small" onClick={() => deleteRow(row)}>
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          )}
        </div>
      ),
    },
  ];

  const deleteRow = (row) => {
    setIsLoading(true);
    firebase
      .deleteRin({ id: row.id, year, company })
      // .then(() => setRows(rows.filter((r) => r.id !== row.id)))
      .finally(() => setIsLoading(false));
  };

  const changeRow = (row) => {
    setOpenModal(true);
    setRowToUpdate(row);
  };

  const partnersAsOptions = rows.map((r) => r.tradePartner).filter((r, i, a) => a.indexOf(r) === i);
  // .map((r) => ({ value: r, label: r }));

  const filteredRows = rows.filter((r) => enabledRins.includes(r.rinInfo.type));

  const toggleType = (type) => {
    if (enabledRins.includes(type)) {
      setEnabledRins(enabledRins.filter((r) => r !== type));
    } else {
      setEnabledRins([...enabledRins, type]);
    }
  };

  const rinColor = (type) => {
    if (enabledRins.includes(type)) {
      return rinsStyle[type];
    }
    return {
      backgroundColor: 'grey',
      color: 'white',
      width: '100%',
    };
  };

  const addRow = async (rin) => {
    if (rin.id) {
      if (!rin.transactionDate) {
        rin.transactionDate = rin.executed ? moment().format('MM/DD/YYYY') : null;
      }
      await firebase.updateRin({ rin, year, company }).finally(() => setOpenModal(false));
    } else {
      rin.id = `${year}_${String(rows.length + 1).padStart(3, '0')}`;
      await firebase.newRin({ rin, year, company }).finally(() => setOpenModal(false));
    }
  };

  const newTransactionModal = () => {
    setRowToUpdate(undefined);
    setOpenModal(true);
  };

  return (
    <div>
      <div
        style={{ display: 'flex', justifyContent: 'space-between', gap: '1rem', margin: '1rem 0' }}
      >
        {rinsTypes.map((type) => (
          <Chip label={rinsLabels[type]} style={rinColor(type)} onClick={() => toggleType(type)} />
        ))}
      </div>

      <Collapsible title="Contracted">
        <CustomTable
          headers={headers(false)}
          rows={filteredRows.filter((r) => !r.executed)}
          updateRow={(row) => addRow(row)}
        />
      </Collapsible>
      <Collapsible title="Executed Buy/Sell">
        <CustomTable
          headers={headers(true)}
          rows={filteredRows
            .filter((r) => r.executed)
            .sort((a, b) => new Date(b.transactionDate) - new Date(a.transactionDate))}
          updateRow={(row) => addRow(row)}
        />
      </Collapsible>

      <FabAddIcon onClick={newTransactionModal} />
      <RightPanel open={openModal} setOpen={setOpenModal}>
        <TransactionForm
          addRow={addRow}
          initialState={rowToUpdate}
          partnerOptions={partnersAsOptions}
        />
      </RightPanel>
      <Loader isLoading={isLoading} />
    </div>
  );
};

Transactions.propTypes = {
  year: PropTypes.number.isRequired,
  company: PropTypes.string.isRequired,
  firebase: PropTypes.instanceOf(Firebase).isRequired,
  data: PropTypes.shape({}).isRequired,
};

export { Transactions };

import { Fab } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import React from 'react';
import PropTypes from 'prop-types';

const FabAddIcon = ({ onClick }) => (
  <Fab
    color="primary"
    style={{ position: 'fixed', right: '2rem', bottom: '2rem' }}
    onClick={onClick}
  >
    <AddIcon />
  </Fab>
);

FabAddIcon.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export { FabAddIcon };

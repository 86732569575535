import React, { useState } from 'react';
import { Chip } from '@mui/material';
import { BatchesInfo } from './batchesInfo';
import { FacilitySummary } from './facilitySummary';
import { FacilityNotes } from './facilityNotes';
import { RvpData } from './rvpData';
import { ButtonTabs } from '../Common/ButtonTabs/buttonTabs';
import PropTypes from 'prop-types';

const FacilityInfo = ({ facilityName, notes, changeNotes, facility }) => {
  const [selectedTab, setSelectedTab] = useState(0);
  // const facility= facilities[facilityName];
  const { flags } = facility;

  const flagKeys = [
    {
      color: 'sulfurColor',
      value: 'sulfur',
      label: 'Sulfur',
    },
    {
      color: 'benzeneColor',
      value: 'benzene',
      label: 'Benzene',
    },
    {
      color: 'butaneColor',
      value: 'butane',
      label: 'Butane',
    },
    {
      color: 'rvpColor',
      value: 'rvpSummer',
      label: 'RVP',
    },
    {
      color: 'benzeneAverageColor',
      value: 'benzeneAverage',
      label: 'Benzene Avg',
    },
    {
      color: 'sulfurAverageColor',
      value: 'sulfurAverage',
      label: 'Sulfur Avg',
    },
  ];

  const tabs = [
    {
      title: 'Batches',
      component: <BatchesInfo facility={facility} />,
    },
    { title: 'Summary', component: <FacilitySummary facility={facility} /> },
    {
      title: 'Notes',
      component: (
        <FacilityNotes facilityName={facilityName} changeNotes={changeNotes} notes={notes} />
      ),
    },
    {
      title: 'RVP Data',
      component: <RvpData facility={facility} facilityName={facilityName} />,
    },
  ];

  return (
    <div style={{ width: '100%', display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>
      <div style={{ width: '100%', display: 'flex', justifyContent: 'space-evenly' }}>
        {flagKeys.map((flag) => (
          <Chip
            key={`${flag.label}-${flags[flag.value]}`}
            label={`${flag.label}: ${flags[flag.value] ?? 0}`}
            color={flags[flag.color]}
          />
        ))}
      </div>

      <ButtonTabs selectedTab={selectedTab} setSelectedTab={setSelectedTab} tabDisplay={tabs} />
      {tabs[selectedTab].component}
    </div>
  );
};

FacilityInfo.propTypes = {
  facilityName: PropTypes.string.isRequired,
  notes: PropTypes.string.isRequired,
  changeNotes: PropTypes.func.isRequired,
  facility: PropTypes.shape({ flags: PropTypes.any }).isRequired,
};

export { FacilityInfo };

export const LANDING = '/';
export const SIGN_IN = '/signin';
export const QMS = '/qms';
export const REFINED_DATA = '/refined-data';
export const AGGREGATIONS = '/aggregations';
export const CHANGE_LOG = '/change-log';
export const FACILITY_REVIEW = '/facility-review';
export const REPORTS = '/reports';
export const METRICS = '/metrics';
export const ACCOUNT = '/account';
export const PASSWORD_FORGET = '/pw-forget';
export const ADMIN = '/admin';
export const ADMIN_DETAILS = '/admin/:id';
export const LIBRARY = '/library';
export const RINS = '/rins';
export const RINS_REPORT = '/report/rins';

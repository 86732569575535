import { HorizontalGridLines, LineSeries, VerticalGridLines, XAxis, YAxis } from 'react-vis';
import moment from 'moment';
import React from 'react';
import { FlexibleXYPlot } from './flexibleXYPlot';
import { minMaxAndMonths } from './chartHelper';
import PropTypes from 'prop-types';

const SystemWideMarginTracker = ({ chartData, options, substances }) => {
  const { systemWideMarginTracking, months } = chartData;
  const { filteredMonths, min, max } = minMaxAndMonths({ data: systemWideMarginTracking, months });

  const darkBlueLine = () =>
    systemWideMarginTracking.map((d) => ({
      x: d.x,
      y: 1.3,
    }));

  const cyanLine = () =>
    systemWideMarginTracking.map((d) => ({
      x: d.x,
      y: substances[options.substanceIndex] === 'Sulfur' ? 10 : 0.62,
    }));

  return (
    <FlexibleXYPlot
      xType="time"
      height={600}
      xDomain={[min, max]}
      margin={{ bottom: 100 }}
      style={{ width: '100%' }}
    >
      <LineSeries data={systemWideMarginTracking} />

      {systemWideMarginTracking && <LineSeries data={cyanLine()} />}

      {systemWideMarginTracking && substances[options.substanceIndex] === 'Benzene' && (
        <LineSeries data={darkBlueLine()} />
      )}
      <VerticalGridLines />
      <HorizontalGridLines />
      <XAxis
        tickValues={filteredMonths}
        tickFormat={(v) => moment(v).format('MM/YY')}
        tickLabelAngle={-45}
        style={{ text: { fontSize: 18 } }}
      />
      <YAxis style={{ text: { fontSize: 18 } }} />
    </FlexibleXYPlot>
  );
};

SystemWideMarginTracker.propTypes = {
  chartData: PropTypes.shape({
    systemWideMarginTracking: PropTypes.arrayOf(
      PropTypes.shape({
        x: PropTypes.number,
        y: PropTypes.number,
      })
    ),
    months: PropTypes.arrayOf(PropTypes.number),
  }).isRequired,
  options: PropTypes.shape({
    substanceIndex: PropTypes.number,
  }).isRequired,
  substances: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export { SystemWideMarginTracker };

import React, { useState } from 'react';
import { Autocomplete, Button, TextField } from '@mui/material';
import { DropDown } from '../DropDown';
import { DateInput } from '../DateInput';
import PropTypes from 'prop-types';

const emptyTransaction = {
  executed: false,
  transactionDate: '',
  contractDate: '',
  tradePartner: '',
  buySell: '',
  rinInfo: {
    cost: '',
    type: '',
  },
  rinYear: '',
  type: '',
  price: '',
  expectedDelivery: '',
  raDealNumber: '',
  notes: '',
};

const TransactionForm = ({ addRow, initialState, partnerOptions }) => {
  const [newTransaction, setNewTransaction] = useState(initialState);

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
      <DateInput
        onChange={(value) => setNewTransaction({ ...newTransaction, contractDate: value })}
        value={newTransaction.contractDate}
        label="Contract Date"
      />

      {newTransaction.executed && (
        <DateInput
          onChange={(value) => setNewTransaction({ ...newTransaction, transactionDate: value })}
          value={newTransaction.transactionDate}
          label="Transaction Date"
        />
      )}

      {/* Trade Partner */}
      <Autocomplete
        freeSolo
        value={newTransaction.tradePartner || ''}
        options={partnerOptions}
        onChange={(e, newValue) => {
          setNewTransaction({ ...newTransaction, tradePartner: newValue });
        }}
        renderInput={(params) => {
          // eslint-disable-next-line react/jsx-props-no-spreading
          return <TextField {...params} label="Trade Partner" />;
        }}
      />

      {/* Buy/Sell */}
      <DropDown
        onChange={(opt) => setNewTransaction({ ...newTransaction, buySell: opt })}
        value={newTransaction.buySell}
        label="Buy/Sell"
        options={['Buy', 'Sell'].map((opt) => ({ value: opt, label: opt }))}
      />

      {/* RIN Cost */}
      <TextField
        label="RIN Quantity"
        type="number"
        value={newTransaction.rinInfo?.cost || ''}
        onChange={(e) => {
          setNewTransaction({
            ...newTransaction,
            rinInfo: { ...newTransaction.rinInfo, cost: e.target.value },
          });
        }}
      />

      {/* RIN Type */}
      <DropDown
        onChange={(opt) =>
          setNewTransaction({
            ...newTransaction,
            rinInfo: { ...newTransaction.rinInfo, type: opt },
          })
        }
        value={newTransaction.rinInfo?.type}
        label="RIN Type"
        options={[
          { label: 'D6 Ethanol RINs', value: 'd6' },
          { label: 'D3 or D7 Cellulosic Biofuel', value: 'd3OrD7' },
          { label: 'D4 Biomass Based Diesel', value: 'd4' },
          { label: 'D5 Advanced Biofuel', value: 'd5' },
        ]}
      />

      {/* Rin Year */}
      <TextField
        label="Rin Year"
        type="number"
        value={newTransaction.rinYear || ''}
        onChange={(e) => {
          setNewTransaction({ ...newTransaction, rinYear: e.target.value });
        }}
      />

      {/* Type */}
      <TextField
        label="Type"
        type="text"
        value={newTransaction.type || ''}
        onChange={(e) => {
          setNewTransaction({ ...newTransaction, type: e.target.value });
        }}
      />

      {/* Price */}
      <TextField
        label="Price"
        type="number"
        value={newTransaction.price || ''}
        onChange={(e) => {
          setNewTransaction({ ...newTransaction, price: e.target.value });
        }}
      />

      {/* Expected Delivery */}
      <TextField
        onChange={(e) => setNewTransaction({ ...newTransaction, expectedDelivery: e.target.value })}
        value={newTransaction.expectedDelivery}
        label="Expected Delivery"
      />

      {/* RA Deal Number */}
      <TextField
        label="RA Deal Number"
        type="text"
        value={newTransaction.raDealNumber || ''}
        onChange={(e) => {
          setNewTransaction({ ...newTransaction, raDealNumber: e.target.value });
        }}
      />

      <TextField
        onChange={(e) => setNewTransaction({ ...newTransaction, notes: e.target.value })}
        value={newTransaction.notes}
        label="Notes"
      />

      <Button
        onClick={() => {
          addRow(newTransaction).then(() => {
            setNewTransaction(emptyTransaction);
          });
        }}
      >
        Save
      </Button>
    </div>
  );
};

TransactionForm.propTypes = {
  addRow: PropTypes.func.isRequired,
  initialState: PropTypes.shape({
    executed: PropTypes.bool,
    transactionDate: PropTypes.string,
    contractDate: PropTypes.string,
    tradePartner: PropTypes.string,
    buySell: PropTypes.string,
    rinInfo: PropTypes.shape({
      cost: PropTypes.string,
      type: PropTypes.string,
    }),
    rinYear: PropTypes.string,
    type: PropTypes.string,
    price: PropTypes.string,
    expectedDelivery: PropTypes.string,
    raDealNumber: PropTypes.string,
    notes: PropTypes.string,
  }),
  partnerOptions: PropTypes.arrayOf(PropTypes.string).isRequired,
};

TransactionForm.defaultProps = {
  initialState: emptyTransaction,
};

export { TransactionForm };

import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { dateFilter, textFilter } from 'react-bootstrap-table2-filter';

import columnMapping from './Columns';
import { withAuthorization, withEmailVerification } from '../Session';
import Firebase, { withFirebase } from '../Firebase';
import Spinner from '../Spinner';
import { PaginationTable } from '../Common/PaginationTable/paginationTable';
import { CollapsibleRow } from '../FacilityReview/collapsibleRow';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import PropTypes from 'prop-types';

function getFormattedDate(date) {
  const year = date.getFullYear();
  const month = (1 + date.getMonth()).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');

  return `${month}/${day}/${year}`;
}

function ExcelDateToJSDate(date) {
  return new Date(Math.round((date - 25569) * 86400 * 1000));
}

function getFilesList(userData) {
  return userData === 'mmp2'
    ? [
        {
          id: 'str0200',
          name: 'STR0200',
        },
      ]
    : [
        {
          id: 'abt0300',
          name: 'ABT0300',
        },
        {
          id: 'dsl0100',
          name: 'DSL0100',
        },
        {
          id: 'gsf0402',
          name: 'GSF0402',
        },
        {
          id: 'rfg0303',
          name: 'RFG0303',
        },
        {
          id: 'rfg030x',
          name: 'RFG030X',
        },
      ];
}

class ReportPageBase extends Component {
  constructor(props) {
    super(props);
    const { authUser } = this.props;

    const fileList = getFilesList(authUser?.data);

    this.state = {
      loading: false,
      rows: [],
      columns: [],
      keyField: 'index',
      selectedReport: fileList[0],
      fileList,
    };
    this.changeSelectedFile = this.changeSelectedFile.bind(this);
    this.loadSelectedFile = this.loadSelectedFile.bind(this);
  }

  componentDidMount() {
    this.loadSelectedFile();
  }

  loadSelectedFile(force = false) {
    const { authUser, firebase } = this.props;
    const { rows, selectedReport } = this.state;

    if (force || !rows || rows.length < 1) {
      this.setState({ loading: true });
      firebase.report(selectedReport.id, authUser?.data).on('value', (snapshot) => {
        const tRows = snapshot.val();
        if (Array.isArray(tRows)) {
          // need to double up dates for two-way date filters
          const rows = tRows.map((t) => {
            return {
              ...t,
              productiondate2: t.productiondate || '',
              reportdate: Number.isNaN(t.reportdate)
                ? t.reportdate
                : getFormattedDate(ExcelDateToJSDate(Number(t.reportdate))),
            };
          });
          const header = Object.keys(rows[0]);
          const columns =
            columnMapping[selectedReport.id] ||
            header
              .map((h) => {
                return {
                  dataField: h,
                  text: h,
                  filter: h.endsWith('date') || h.startsWith('date') ? dateFilter() : textFilter(),
                  sort: true,
                };
              })
              .filter((h) => !['tank_line', 'remarks'].includes(h.dataField));
          this.setState({
            rows,
            columns,
            loading: false,
          });
        }
      });
    }
  }

  async changeSelectedFile(event) {
    const { fileList } = this.state;
    const selectedReport = fileList.find((fl) => fl.id === event.target.value);
    await this.setState({ rows: [], columns: [], selectedReport });
    this.loadSelectedFile(true);
  }

  render() {
    const { loading, rows, columns, keyField, selectedReport, fileList } = this.state;
    return (
      <div>
        <div>
          <h1>Reports</h1>
          <FormControl fullWidth>
            <InputLabel id="file-select">File</InputLabel>
            <Select
              labelId="file-select"
              onChange={this.changeSelectedFile}
              label="File"
              value={selectedReport.id}
            >
              {fileList.map((fl) => (
                <MenuItem key={`agg-${fl.id}`} value={fl.id}>
                  {fl.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        {loading && <Spinner loading={loading} />}

        {rows && rows.length > 0 && (
          <PaginationTable
            columns={columns}
            rows={rows}
            columnFilter={(c) => c.display}
            keyField={keyField}
            rowComponent={(row) => (
              <CollapsibleRow row={row} columns={columns} key={row?.[keyField]} />
            )}
            collapsible
          />
        )}
      </div>
    );
  }
}

ReportPageBase.propTypes = {
  authUser: PropTypes.shape({ data: PropTypes.any.isRequired }).isRequired,
  firebase: PropTypes.instanceOf(Firebase).isRequired,
};

const condition = (authUser) => !!authUser;

const ReportPage = withFirebase(ReportPageBase);

export default compose(
  withEmailVerification,
  withAuthorization(condition),
  withRouter,
  withFirebase
)(ReportPage);

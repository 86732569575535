import {
  Collapse,
  IconButton,
  List,
  ListItem,
  ListItemText,
  TableCell,
  TableRow,
} from '@mui/material';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

const CollapsibleRow = ({ row, columns }) => {
  const [open, setOpen] = useState(false);

  const columnsToDisplay = columns.filter((value) => value.display);
  const listInCollapse = columns
    .filter((value) => !value.display)
    .reduce((resultArray, item, index, array) => {
      const groupsLength = array.length / 5;
      const chunkIndex = Math.floor(index / groupsLength);

      if (!resultArray[chunkIndex]) {
        resultArray[chunkIndex] = []; // start a new chunk
      }

      resultArray[chunkIndex].push(item);

      return resultArray;
    }, []);
  //

  return (
    <>
      <TableRow>
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>

        {columnsToDisplay.map((c) => (
          <TableCell key={`${c.text}-${row[c.dataField]}`}>
            {c.formatter ? c.formatter(row[c.dataField]) : row[c.dataField]}
          </TableCell>
        ))}
      </TableRow>
      <TableRow>
        <TableCell
          style={{ paddingBottom: 0, paddingTop: 0 }}
          colSpan={columnsToDisplay.length + 1}
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            <div style={{ display: 'flex' }}>
              {listInCollapse.map((list) => (
                <List>
                  {list.map((c) => (
                    <ListItem key={`list-item-${c.text}-${row[c.dataField]}`}>
                      <ListItemText primary={c.text} secondary={row[c.dataField] || 'No data'} />
                    </ListItem>
                  ))}
                </List>
              ))}
            </div>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

CollapsibleRow.propTypes = {
  row: PropTypes.shape({}).isRequired,
  columns: PropTypes.arrayOf(PropTypes.shape({ display: PropTypes.bool })).isRequired,
};
export { CollapsibleRow };

import React from 'react';
import Firebase, { withFirebase } from '../Firebase';
import { IconButton, Tooltip } from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';
import * as PropTypes from 'prop-types';

const SignOutButton = ({ firebase }) => (
  <IconButton onClick={firebase.doSignOut}>
    <Tooltip title="Sign Out" placement="right">
      <LogoutIcon />
    </Tooltip>
  </IconButton>
);

SignOutButton.propTypes = {
  firebase: PropTypes.instanceOf(Firebase).isRequired,
};

export default withFirebase(SignOutButton);

import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import _ from 'lodash';
import { Type } from 'react-bootstrap-table2-editor';
import { dateFilter, textFilter } from 'react-bootstrap-table2-filter';

import columnMapping from './Columns';
import Spinner from '../Spinner';
import { withAuthorization, withEmailVerification } from '../Session';
import Firebase, { withFirebase } from '../Firebase';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { PaginationTable } from '../Common/PaginationTable/paginationTable';
import { CollapsibleRow } from '../FacilityReview/collapsibleRow';
import { filesList } from './constants';
import PropTypes from 'prop-types';

function getFormattedDate(date) {
  const year = date.getFullYear();
  const month = (1 + date.getMonth()).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');

  return `${month}/${day}/${year}`;
}

function ExcelDateToJSDate(date) {
  return new Date(Math.round((date - 25569) * 86400 * 1000));
}

class AggregationPageBase extends Component {
  constructor(props) {
    super(props);

    const { authUser } = this.props;
    const fileList = filesList(authUser?.data);

    this.state = {
      loading: false,
      rows: [],
      columns: [],
      keyField: 'index',
      selectedAgg: fileList[0],
      fileList,
    };
    this.changeSelectedFile = this.changeSelectedFile.bind(this);
    this.loadSelectedFile = this.loadSelectedFile.bind(this);
    this.saveNotes = this.saveNotes.bind(this);
    // this.reloadFileList = this.reloadFileList.bind(this);
  }

  componentDidMount() {
    this.loadSelectedFile();
  }

  async loadSelectedFile(force = false) {
    const { rows, selectedAgg } = this.state;

    if ((force || !rows || rows.length < 1) && selectedAgg?.id) {
      this.setState({ loading: true });
      const { authUser, firebase } = this.props;
      const [snapshot, sortNotesSnapshot, aggNotesSnapshot] = await Promise.all([
        firebase.agg(selectedAgg.id, authUser?.data).once('value'),
        firebase.notes(`sort-${selectedAgg.id}`).once('value'),
        firebase.notes(`agg-${selectedAgg.id}`).once('value'),
      ]);
      const tRows = snapshot.val();
      // need to double up dates for two-way date filters
      const rows = tRows?.map((t) => {
        return {
          ...t,
          date2: t.date || '',
          reportdate: Number.isNaN(t.reportdate)
            ? t.reportdate
            : getFormattedDate(ExcelDateToJSDate(Number(t.reportdate))),
        };
      });

      const header = Object.keys(rows?.[0] || {});
      const columns =
        _.cloneDeep(columnMapping[selectedAgg.id]) ||
        header
          .map((h) => {
            return {
              dataField: h,
              text: h,
              filter: h.endsWith('date') || h.startsWith('date') ? dateFilter() : textFilter(),
              sort: true,
              editable: false,
            };
          })
          .filter((h) => !['tank_line', 'remarks'].includes(h.dataField));

      if (!columns.find((c) => c.dataField === 'customnotes')) {
        columns.push({
          dataField: 'customnotes',
          text: 'Notes',
          filter: textFilter(),
          sort: true,
          style: { minWidth: 200 },
          editcellstyle: { minWidth: 200 },
          editable: !['g2', 'cbs', 'wtb'].includes(selectedAgg.id),
          editor: {
            type: Type.TEXTAREA,
            rows: 2,
            cols: 40,
          },
          display: false,
        });
      }

      // get notes

      let sortNotes = sortNotesSnapshot.val();
      if (!sortNotes) {
        sortNotes = {};
      }
      let aggNotes = aggNotesSnapshot.val();
      if (!aggNotes) {
        aggNotes = {};
      }
      if (rows?.length > 0) {
        for (const row of rows) {
          const whichNotes = ['g2', 'cbs', 'wtb'].includes(selectedAgg.id) ? sortNotes : aggNotes;
          if (row.sampleid) {
            row.customnotes = whichNotes[row.sampleid.substring(0, 500)] || '';
          }
        }
      }
      this.setState({ rows, columns, loading: false });
    }
  }

  async changeSelectedFile(event) {
    const { fileList } = this.state;
    const selectedAgg = fileList.find((fl) => fl.id === event.target.value);
    await this.setState({ rows: [], selectedAgg });
    await this.loadSelectedFile(true);
  }

  saveNotes(oldValue, newValue, row, column) {
    const { selectedAgg } = this.state;
    const { firebase } = this.props;

    if (column.dataField === 'customnotes') {
      const writeObj = {};
      writeObj[row.sampleid.substring(0, 500)] = newValue;
      firebase.saveNote(`agg-${selectedAgg.id}`, writeObj);
    }
  }

  render() {
    const { loading, rows, columns, keyField, selectedAgg, fileList } = this.state;

    return (
      <div>
        <h1>Aggregations</h1>
        <FormControl fullWidth>
          <InputLabel id="file-select">File</InputLabel>
          <Select
            labelId="file-select"
            onChange={this.changeSelectedFile}
            label="File"
            value={selectedAgg.id}
          >
            {fileList.map((fl) => (
              <MenuItem key={`agg-${fl.id}`} value={fl.id}>
                {fl.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {loading && <Spinner loading={loading} />}

        {rows && Array.isArray(rows) && !loading && (
          <div>
            {rows && Array.isArray(rows) && rows.length > 0 && (
              <PaginationTable
                columns={columns}
                rows={rows}
                columnFilter={(c) => c.display}
                keyField={keyField}
                rowComponent={(row) => (
                  <CollapsibleRow row={row} columns={columns} key={row?.[keyField]} />
                )}
                collapsible
              />
            )}
          </div>
        )}
      </div>
    );
  }
}

AggregationPageBase.propTypes = {
  authUser: PropTypes.shape({ data: PropTypes.any }).isRequired,
  firebase: PropTypes.instanceOf(Firebase).isRequired,
};

const condition = (authUser) => !!authUser;

const AggregationPage = withFirebase(AggregationPageBase);

export default compose(
  withEmailVerification,
  withAuthorization(condition),
  withRouter,
  withFirebase
)(AggregationPage);

import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import _ from 'lodash';

import 'react-datepicker/dist/react-datepicker.css';
import '../../../node_modules/react-vis/dist/style.css';
import { dateFilter, textFilter } from 'react-bootstrap-table2-filter';

import columnMapping from './Columns';
import Spinner from '../Spinner';
import { withAuthorization, withEmailVerification } from '../Session';
import Firebase, { withFirebase } from '../Firebase';
import { PaginationTable } from '../Common/PaginationTable/paginationTable';
import { CollapsibleRow } from '../FacilityReview/collapsibleRow';
import PropTypes from 'prop-types';

class ChangeLogPageBase extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      rows: [],
      columns: [],
    };
  }

  componentDidMount() {
    this.setState({ loading: true });
    const { firebase } = this.props;
    firebase.changelog('all').on('value', (snapshot) => {
      const tRows = snapshot.val();
      // need to double up dates for two-way date filters
      const rows = tRows.map((t) => {
        return {
          ...t,
        };
      });
      const header = Object.keys(rows[0]);
      const columns =
        _.cloneDeep(columnMapping.all) ||
        header
          .map((h) => {
            return {
              dataField: h,
              text: h,
              filter: h.endsWith('date') || h.startsWith('date') ? dateFilter() : textFilter(),
              sort: true,
              editable: false,
            };
          })
          .filter((h) => !['tank_line', 'remarks'].includes(h.dataField));

      this.setState({ rows, columns, loading: false });
    });
  }

  render() {
    const { loading, rows, columns } = this.state;

    return (
      <div>
        <h1>Change Log</h1>
        {loading && <Spinner loading={loading} />}

        {rows && Array.isArray(rows) && !loading && (
          <div>
            {rows && Array.isArray(rows) && rows.length > 0 && (
              <PaginationTable
                columns={columns}
                rows={rows}
                columnFilter={(c) => c.display}
                rowComponent={(row) => (
                  <CollapsibleRow row={row} columns={columns} key={Math.random()} />
                )}
                collapsible
              />
            )}
          </div>
        )}
      </div>
    );
  }
}

ChangeLogPageBase.propTypes = {
  firebase: PropTypes.instanceOf(Firebase).isRequired,
};

const condition = (authUser) => !!authUser;

const ChangeLogPage = withFirebase(ChangeLogPageBase);

export default compose(
  withEmailVerification,
  withAuthorization(condition),
  withRouter,
  withFirebase
)(ChangeLogPage);

import React from 'react';
import { PaginationTable } from '../Common/PaginationTable/paginationTable';
import { CollapsibleRow } from './collapsibleRow';
import PropTypes from 'prop-types';

const RvpData = ({ facility }) => {
  const columns = [
    {
      dataField: 'followupreason',
      text: 'Follow-Up Reason',
      display: false,
    },
    {
      dataField: 'sampleid',
      text: 'Sample ID',
      display: true,
    },
    {
      dataField: 'sampledate',
      text: 'Sample Date',
      display: true,
    },
    {
      dataField: 'location',
      text: 'Location',
      display: false,
    },
    {
      dataField: 'tank_line',
      text: 'Tank Line',
      display: true,
    },
    {
      dataField: 'product',
      text: 'Product',
      display: true,
      filterType: 'text',
    },

    {
      dataField: 'targetrvp',
      text: 'Target RVP',
      display: true,
    },
    {
      dataField: 'afterrvp',
      text: 'After RVP',
      display: true,
    },
    {
      dataField: 'aftersulfur',
      text: 'After Sulfur',
      display: true,
    },
    {
      dataField: 'beforervp',
      text: 'Before RVP',
      display: true,
    },
    {
      dataField: 'beforesulfur',
      text: 'Before Sulfur',
      display: true,
    },
    {
      dataField: 'cbsblend',
      text: 'CBS Blend',
      display: true,
    },
    {
      dataField: 'correspondingsample',
      text: 'Corresponding Sample',
      display: false,
    },
    {
      dataField: 'endpoint',
      text: 'End Point',
      display: false,
    },
    {
      dataField: 'remarks',
      text: 'Remarks',
      display: false,
    },
    {
      dataField: 'fracgasbarrelsblended',
      text: 'Frac Gas Barrels Blended',
      display: false,
    },
    {
      dataField: 'gasbarrelsafterblend',
      text: 'Gas Barrels After Blend',
      display: false,
    },
    {
      dataField: 'gasbarrelsbeforeblend',
      text: 'Gas Barrels Before Blend',
      display: false,
    },
    {
      dataField: 'hbbls',
      text: 'H BBLS',
      display: false,
    },
    {
      dataField: 'spherecertificationsample',
      text: 'Sphere Certification Sample',
      display: false,
    },
    {
      dataField: 'splitmonthblend',
      text: 'Split Month Blend',
      display: false,
    },
    {
      dataField: 'tmixbarrelsblended',
      text: 'T-Mix Barrels Blended',
      display: false,
    },
    {
      dataField: 'targetrvp',
      text: 'TargetRVP',
      display: false,
    },
    {
      dataField: 'wbbls',
      text: 'W BBLS',
      display: false,
    },
    {
      dataField: 'customnotes',
      text: 'Notes',
      display: false,
    },
  ];
  // CSV only needs the rows that are filtered out
  return (
    <PaginationTable
      rows={facility.rvpTable}
      columns={columns}
      rowComponent={(row) => <CollapsibleRow row={row} columns={columns} key={row.sampleid} />}
      columnFilter={(c) => c.display}
      collapsible
    />
  );
};

RvpData.propTypes = {
  facility: PropTypes.shape({
    rvpTable: PropTypes.arrayOf(PropTypes.any).isRequired,
  }).isRequired,
};
export { RvpData };

import React, { useState } from 'react';
import { LinkContainer } from 'react-router-bootstrap';
import { connect } from 'react-redux';
import logo from '../../images/magellan-horizontal.jpg';

import SignOutButton from '../SignOut';
import * as ROUTES from '../../constants/routes';
import * as ROLES from '../../constants/roles';
import {
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Tooltip,
} from '@mui/material';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import AutoGraphIcon from '@mui/icons-material/AutoGraph';
import ReportIcon from '@mui/icons-material/Report';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import MenuIcon from '@mui/icons-material/Menu';
import SignOutDrawer from './signOutDrawer';
import MergeIcon from '@mui/icons-material/Merge';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import PropTypes from 'prop-types';

const Navigation = ({ authUser }) => {
  if (authUser) return <NavigationAuth authUser={authUser} />;
  return null;
};

const notMmp2 = (authUser) => authUser.data !== 'mmp2';

const isAdmin = (authUser) => !!authUser.roles[ROLES.ADMIN];

const navigationItems = [
  { label: 'Overview', icon: <RemoveRedEyeIcon />, route: ROUTES.METRICS, display: notMmp2 },
  {
    label: 'Facility Review',
    icon: <LocationCityIcon />,
    route: ROUTES.FACILITY_REVIEW,
    display: notMmp2,
  },
  { label: 'Raw Data', icon: <ShowChartIcon />, route: ROUTES.QMS, display: notMmp2 },
  { label: 'Refined Data', icon: <AutoGraphIcon />, route: ROUTES.REFINED_DATA, display: notMmp2 },
  { label: 'Aggregations', icon: <MergeIcon />, route: ROUTES.AGGREGATIONS, display: () => true },
  { label: 'Reports', icon: <ReportIcon />, route: ROUTES.REPORTS, display: () => true },
  {
    label: 'Change Log',
    icon: <FormatListBulletedIcon />,
    route: ROUTES.CHANGE_LOG,
    display: () => true,
  },
  { label: 'Library', icon: <LibraryBooksIcon />, route: ROUTES.LIBRARY, display: notMmp2 },
  {
    label: 'RINS Tracking tool',
    icon: <QueryStatsIcon />,
    route: ROUTES.RINS,
    display: () => true,
  },
  { label: 'Admin', icon: <AdminPanelSettingsIcon />, route: ROUTES.ADMIN, display: isAdmin },
];

const NavigationAuth = ({ authUser }) => {
  const [openDrawer, setOpenDrawer] = useState(false);

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '0.5rem', position: 'fixed' }}>
      <IconButton color="default" onClick={() => setOpenDrawer(true)}>
        <MenuIcon />
      </IconButton>
      <Drawer anchor="left" open={openDrawer} onClose={() => setOpenDrawer(false)}>
        <List>
          <ListItem>
            <ListItemAvatar>
              <img alt="Logo" src={logo} style={{ width: '16vw' }} />
            </ListItemAvatar>
          </ListItem>
          {navigationItems
            .filter((item) => item.display(authUser))
            .map((item) => (
              <LinkContainer to={item.route} active={false} onClick={() => setOpenDrawer(false)}>
                <ListItem>
                  <ListItemButton>
                    <ListItemIcon>{item.icon}</ListItemIcon>
                    <ListItemText primary={item.label} />
                  </ListItemButton>
                </ListItem>
              </LinkContainer>
            ))}
          <SignOutDrawer />
        </List>
      </Drawer>
      {navigationItems
        .filter((item) => item.display(authUser))
        .map((item) => (
          <LinkContainer to={item.route} active={false}>
            <Tooltip title={item.label} placement="right">
              <IconButton>{item.icon}</IconButton>
            </Tooltip>
          </LinkContainer>
        ))}
      <SignOutButton />
    </div>
  );
};

const authProps = PropTypes.shape({
  roles: PropTypes.any.isRequired,
  data: PropTypes.string.isRequired,
});

Navigation.propTypes = {
  authUser: authProps.isRequired,
};

NavigationAuth.propTypes = {
  authUser: authProps.isRequired,
};

const mapStateToProps = (state) => ({
  authUser: state.sessionState.authUser,
});

export default connect(mapStateToProps)(Navigation);

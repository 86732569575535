import React, { useState } from 'react';
import { DropDown } from '../DropDown';
import { Button, TextField } from '@mui/material';
import { auditLabels } from '../RinsConstants';
import PropTypes from 'prop-types';

const AuditForm = ({ rowToUpdate, onUpdate }) => {
  const [formValue, setFormValue] = useState({ ...rowToUpdate });

  const fields = [
    {
      label: 'Alternate D code of 3 / 7',
      key: 'd3OrD7',
      disable: !['d3OrD7', 'd5', 'd6'].includes(formValue.type),
    },
    {
      label: 'Alternate D code of 4',
      key: 'd4',
      disable: formValue.type === 'd3OrD7',
    },
    {
      label: 'Alternate D code of 5',
      key: 'd5',
      disable: !['d5', 'd6'].includes(formValue.type),
    },
    {
      label: 'Alternate D code of 6',
      key: 'd6',
      disable: formValue.type !== 'd6',
    },
  ];

  const options = Object.keys(auditLabels).map((key) => ({
    label: auditLabels[key],
    value: key,
  }));

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
      <DropDown
        disabled
        onChange={(opt) => setFormValue({ ...formValue, type: opt })}
        value={formValue.type}
        label="RIN Type"
        options={options}
      />
      <TextField
        label="Alternate Carry Over"
        type="number"
        value={Number(formValue.alternateCarryOver?.replace(/,/g, '')) || ''}
        onChange={(e) => {
          setFormValue({
            ...formValue,
            alternateCarryOver: e.target.value,
          });
        }}
      />

      {fields
        .filter((field) => !field.disable)
        .map((header) => (
          <TextField
            label={header.label}
            type="number"
            value={formValue.alternate[header.key] || ''}
            onChange={(e) => {
              setFormValue({
                ...formValue,
                alternate: {
                  ...formValue.alternate,
                  [header.key]: e.target.value,
                },
              });
            }}
          />
        ))}

      <Button onClick={() => onUpdate(formValue)}>Save</Button>
    </div>
  );
};

AuditForm.propTypes = {
  rowToUpdate: PropTypes.shape({
    type: PropTypes.string.isRequired,
    gasolineAndDieselProductionRenewableFuelExportVolume: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ]).isRequired,
    renewableFuelStandardValueEquivalenceValue: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ]).isRequired,
    renewableVolumeObligationRvo: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
      .isRequired,
    priorYearDeficit: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    '2021RinCOIncludesContracts': PropTypes.oneOfType([PropTypes.number, PropTypes.string])
      .isRequired,
    '20RawMax': PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    '20NestedOptimal': PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    priorYearRinsUsedDCodeOf37: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
      .isRequired,
    priorYearRinsUsedDCodeOf4: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    priorYearRinsUsedDCodeOf5: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    priorYearRinsUsedDCodeOf6: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    priorYearRinsUsed: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    priorYearLimitCheck: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    excess2021RinCarryover: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    rvoBalance1: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    '2022RinInventoryIncludesContracts': PropTypes.oneOfType([PropTypes.number, PropTypes.string])
      .isRequired,
    currentYearRinsUsedDCodeOf37: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
      .isRequired,
    alternateDCodeOf37: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    rvoBalance2: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    currentYearRinsUsedDCodeOf4: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
      .isRequired,
    alternateDCodeOf4: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    rvoBalance3: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    currentYearRinsUsedDCodeOf5: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
      .isRequired,
    alternateDCodeOf5: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    rvoBalance4: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    currentYearRinsUsedDCodeOf6: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
      .isRequired,
    alternateDCodeOf6: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    deficitRvo: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    carryOver: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    alternateCarryOver: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  }).isRequired,
  onUpdate: PropTypes.func.isRequired,
};

export { AuditForm };

import { Accordion, AccordionSummary, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import React from 'react';
import PropTypes from 'prop-types';

const Collapsible = ({ children, title }) => (
  <Accordion>
    <AccordionSummary
      expandIcon={<ExpandMoreIcon />}
      aria-controls="panel1a-content"
      id="panel1a-header"
    >
      <Typography>{title}</Typography>
    </AccordionSummary>
    {children}
  </Accordion>
);

Collapsible.propTypes = {
  children: React.ReactNode,
  title: PropTypes.string.isRequired,
};

Collapsible.defaultProps = {
  children: null,
};

export { Collapsible };

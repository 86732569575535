import React from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';

import 'react-datepicker/dist/react-datepicker.css';
import '../../../node_modules/react-vis/dist/style.css';
import { withAuthorization, withEmailVerification } from '../Session';
import { withFirebase } from '../Firebase';
import { OverviewTemplate } from './overviewTemplate';

// eslint-disable-next-line react/jsx-props-no-spreading
const MetricPageBase = (props) => <OverviewTemplate {...props} />;

const condition = (authUser) => !!authUser;

const MetricPage = withFirebase(MetricPageBase);

export default compose(
  withEmailVerification,
  withAuthorization(condition),
  withRouter,
  withFirebase
)(MetricPage);

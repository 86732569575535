import React, { useEffect, useState } from 'react';
import moment from 'moment';
import _ from 'lodash';
import Spinner from '../Spinner';
import { FilterOverview } from './filterOverview';
import { ChartsAndTable } from './chartsAndTable';
import Firebase from '../Firebase';
import PropTypes from 'prop-types';

const OverviewTemplate = ({ firebase }) => {
  const substances = ['Benzene', 'Sulfur'];

  const [options, setOptions] = useState({});
  const [loading, setLoading] = useState(true);
  const [reports, setReports] = useState(null);
  const [facilities, setFacilities] = useState([]);
  const [singleFacilityPerformance, setSingleFacilityPerformance] = useState([]);
  const [performanceAcrossFacilities, setPerformanceAcrossFacilities] = useState([]);
  const [systemWideMarginTracking, setSystemWideMarginTracking] = useState([]);
  const [table, setTable] = useState({
    rows: [],
    columns: [
      {
        dataField: 'facilityname',
        text: 'Location',
        sort: true,
      },
      {
        dataField: 'batchvolume',
        text: 'Annual Volume',
        sort: true,
      },
      {
        dataField: 'sulfuraverage',
        text: 'Sulfur Average',
        sort: true,
      },
      {
        dataField: 'sulfurcredit',
        text: 'Sulfur Credit (Deficit)',
        sort: true,
      },
      {
        dataField: 'benzeneaverage',
        text: 'Benzene Average',
        sort: true,
      },
      {
        dataField: 'benzenecredit',
        text: 'Benzene Credit (Deficit)',
        sort: true,
      },
    ],
  });

  const [chartData, setChartData] = useState({
    months: [],
  });

  useEffect(() => {
    setLoading(true);
    if (options.substanceIndex !== undefined && reports) {
      setLoading(true);
      const newChartData = chartData;
      const substance = substances[options.substanceIndex].toLowerCase();
      const facility = facilities[options.facilityIndex];

      // get months
      newChartData.months = [];
      // check how to update the months refresh
      for (
        let i = options.minDate;
        moment(i).isSameOrBefore(options.maxDate, 'month');
        i = moment(i).add(1, 'month')
      ) {
        newChartData.months.push(i.valueOf());
      }

      // get groupings:
      const data = reports.gsf0402
        .filter(
          (d) =>
            moment(d.productiondate).isSameOrAfter(options.minDate, 'day') &&
            moment(d.productiondate).isSameOrBefore(options.maxDate, 'day')
        )
        .sort((a, b) => moment(a.productiondate).valueOf() - moment(b.productiondate).valueOf());

      const dataByMonthObj = _.groupBy(data, (o) => moment(o.productiondate).format('YYYY-MM'));
      const dataByMonth = Object.keys(dataByMonthObj).map((k) => dataByMonthObj[k]);

      const dataByFacilityObj = _.groupBy(data, (o) => o.facilityname);
      const dataByFacility = Object.keys(dataByFacilityObj).map((k) => dataByFacilityObj[k]);

      const dataByMonthAndFacilityObj = _.groupBy(
        data.filter((d) => d.facilityname === facility),
        (o) => moment(o.productiondate).format('YYYY-MM')
      );
      const dataByMonthAndFacility = Object.keys(dataByMonthAndFacilityObj).map(
        (k) => dataByMonthAndFacilityObj[k]
      );

      // table
      const rows = dataByFacility
        .map((m) => {
          const facilityname = _.max(m.map((r) => r.facilityname));

          const ret = {
            facilityname,
            key: facilities.indexOf(facilityname),
            batchvolume: Math.round(
              _.sum(m.map((r) => (!Number.isNaN(parseFloat(r.batchvolume)) ? r.batchvolume : 0)))
            ).toLocaleString(),
          };
          ['sulfur', 'benzene'].forEach((substance) => {
            const subtractor = substance === 'sulfur' ? 10 : 0.62;
            ret[`${substance}average`] =
              _.sum(
                m.map((r) =>
                  !Number.isNaN(parseFloat(r.batchvolume)) &&
                  !Number.isNaN(parseFloat(r[substance]))
                    ? r.batchvolume * r[substance]
                    : 0
                )
              ) /
              _.sum(m.map((r) => (!Number.isNaN(parseFloat(r.batchvolume)) ? r.batchvolume : 0)));
            ret[`${substance}credit`] =
              (_.sum(m.map((r) => (!Number.isNaN(parseFloat(r.batchvolume)) ? r.batchvolume : 0))) *
                (subtractor - ret[`${substance}average`])) /
              100;
            ret[`${substance}average`] = ret[`${substance}average`].toFixed(4);
            ret[`${substance}credit`] = Math.round(ret[`${substance}credit`]).toLocaleString();
          });
          return ret;
        })
        .sort((a, b) => `${a.facilityname}`.localeCompare(b.facilityname));

      setTable({ ...table, rows: rows.filter((r) => !facility || r.facilityname === facility) });

      // systemwide margin tracking
      const systemWideData = dataByMonth
        .map((m) => {
          return {
            x: _.max(
              m.filter((s) => s[substance] !== 'NA').map((r) => moment(r.productiondate).valueOf())
            ),
            y:
              _.sum(
                m
                  .filter((s) => s[substance] !== 'NA')
                  .map((r) =>
                    !Number.isNaN(parseFloat(r.batchvolume)) &&
                    !Number.isNaN(parseFloat(r[substance]))
                      ? r.batchvolume * r[substance]
                      : 0
                  )
              ) /
              _.sum(
                m
                  .filter((s) => s[substance] !== 'NA')
                  .map((r) => (!Number.isNaN(parseFloat(r.batchvolume)) ? r.batchvolume : 0))
              ),
          };
        })
        .filter((d) => d.x !== undefined);

      const swmt = newChartData.months.map((m) => {
        let y = 0;
        for (const d of systemWideData) {
          if (moment(d.x).isSame(moment(m), 'month')) {
            y = d.y;
          }
        }
        return {
          x: m,
          y,
        };
      });

      setSystemWideMarginTracking(swmt);
      // performance across facilities
      const paf = dataByFacility
        .map((m) => {
          return {
            x: facilities.indexOf(_.max(m.map((r) => r.facilityname))),
            y:
              _.sum(
                m.map((r) =>
                  !Number.isNaN(parseFloat(r.batchvolume)) &&
                  !Number.isNaN(parseFloat(r[substance]))
                    ? r.batchvolume * r[substance]
                    : 0
                )
              ) /
              _.sum(m.map((r) => (!Number.isNaN(parseFloat(r.batchvolume)) ? r.batchvolume : 0))),
          };
        })
        .sort((a, b) => a.x - b.x);

      setPerformanceAcrossFacilities(paf);
      // single facility performance
      const facilityMonthData = dataByMonthAndFacility.map((m) => {
        return {
          x: _.max(m.map((r) => moment(r.productiondate).valueOf())),
          y:
            _.sum(
              m.map((r) =>
                !Number.isNaN(parseFloat(r.batchvolume)) && !Number.isNaN(parseFloat(r[substance]))
                  ? r.batchvolume * r[substance]
                  : 0
              )
            ) / _.sum(m.map((r) => (!Number.isNaN(parseFloat(r.batchvolume)) ? r.batchvolume : 0))),
        };
      });

      const sfp = newChartData.months.map((m) => {
        let y = 0;
        for (const d of facilityMonthData) {
          if (moment(d.x).isSame(moment(m), 'month')) {
            y = d.y;
          }
        }
        return {
          x: m,
          y,
        };
      });

      setSingleFacilityPerformance(sfp);

      setChartData(newChartData);
      setLoading(false);
    }
  }, [options, reports, setLoading, facilities, substances, chartData, table]);

  useEffect(() => {
    const loadAggregations = async () => {
      const [facilitiesSnapshot] = await Promise.all([firebase.agg('facilities').once('value')]);
      const facilityArray = facilitiesSnapshot.val();
      const facilities = facilityArray.sort();
      setFacilities(facilities);
    };

    const loadReports = async () => {
      for (const report of ['gsf0402']) {
        await firebase.report(report).on('value', (snapshot) => {
          // reports[report] = snapshot.val();
          const values = snapshot.val();
          setReports({ ...reports, [report]: values });
          if (report !== 'gsf0402') {
            // not last report
            setReports(reports);
          }
        });
      }
    };

    setLoading(true);

    (async () => Promise.all([loadAggregations(), loadReports()]))();
    // .then(() => setLoading(false));
  }, [firebase, reports]);
  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '10px', margin: 'auto' }}>
      <h1>Overview</h1>
      <FilterOverview facilities={facilities} substances={substances} updateFilter={setOptions} />
      {loading && <Spinner loading={loading} />}

      {reports && Object.keys(reports).length > 0 && (
        <>
          <ChartsAndTable
            chartData={{
              ...chartData,
              table,
              systemWideMarginTracking,
              performanceAcrossFacilities,
              singleFacilityPerformance,
            }}
            facilities={facilities}
            options={options}
            substances={substances}
          />
        </>
      )}
    </div>
  );
};

OverviewTemplate.propTypes = {
  firebase: PropTypes.instanceOf(Firebase).isRequired,
};

export { OverviewTemplate };

import { FlexibleXYPlot } from './flexibleXYPlot';
import { HorizontalGridLines, LineSeries, VerticalBarSeries, XAxis, YAxis } from 'react-vis';
import React from 'react';
import PropTypes from 'prop-types';

const PerformanceAcrossFacilities = ({ chartData, facilities, options, substances }) => {
  return (
    <FlexibleXYPlot
      xType="time"
      height={600}
      style={{ width: '100%' }}
      margin={{ bottom: 100 }}
      xDomain={[-1, facilities.length - 1]}
    >
      <VerticalBarSeries data={chartData.performanceAcrossFacilities} />
      <LineSeries
        data={facilities.map((f, index) => {
          return {
            x: index,
            y: substances[options.substanceIndex] === 'Sulfur' ? 10 : 0.62,
          };
        })}
      />
      {substances[options.substanceIndex] === 'Benzene' && (
        <LineSeries
          data={facilities.map((f, index) => {
            return {
              x: index,
              y: 1.3,
            };
          })}
        />
      )}
      <HorizontalGridLines />
      <XAxis
        tickValues={facilities.map((f, index) => index)}
        tickFormat={(v) => facilities[v]}
        tickLabelAngle={-45}
      />
      <YAxis style={{ text: { fontSize: 18 } }} />
    </FlexibleXYPlot>
  );
};

PerformanceAcrossFacilities.propTypes = {
  chartData: PropTypes.shape({ performanceAcrossFacilities: PropTypes.any.isRequired }).isRequired,
  facilities: PropTypes.arrayOf(PropTypes.any.isRequired).isRequired,
  options: PropTypes.shape({ substanceIndex: PropTypes.string.isRequired }).isRequired,
  substances: PropTypes.shape({}).isRequired,
};

export { PerformanceAcrossFacilities };

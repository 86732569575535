import { ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';
import React from 'react';
import Firebase, { withFirebase } from '../Firebase';
import PropTypes from 'prop-types';

const SignOutDrawer = ({ firebase }) => (
  <ListItem onClick={() => firebase.doSignOut()}>
    <ListItemButton>
      <ListItemIcon>
        <LogoutIcon />
      </ListItemIcon>
      <ListItemText primary="Sign Out" />
    </ListItemButton>
  </ListItem>
);

SignOutDrawer.propTypes = {
  firebase: PropTypes.instanceOf(Firebase).isRequired,
};

export default withFirebase(SignOutDrawer);

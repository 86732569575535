import { Fab } from '@mui/material';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import React, { useState } from 'react';
import { RightPanel } from '../Panels/rightPanel';

const FilterDrawer = ({ children, icon }) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <Fab
        color="primary"
        style={{ position: 'absolute', right: '2rem', bottom: '2rem' }}
        onClick={() => setOpen(true)}
      >
        {icon}
      </Fab>
      <RightPanel open={open} setOpen={setOpen}>
        {children}
      </RightPanel>
    </>
  );
};

FilterDrawer.propTypes = {
  children: React.ReactNode,
  icon: React.ReactNode,
};

FilterDrawer.defaultProps = {
  children: null,
  icon: <FilterAltIcon />,
};

export { FilterDrawer };

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, TextField } from '@mui/material';
import { DropDown } from '../DropDown';

const RvoTPForm = ({ onSubmit, initialValue }) => {
  const [formValue, setFormValue] = useState(initialValue);

  const save = () => {
    onSubmit(formValue);
  };

  const rvoprojectedHeaders = [
    { label: 'RIN type', key: 'rinType', hide: true },
    { label: 'Initial RVO %', key: 'initialRvo' },
    { label: 'Final RVO %', key: 'finalRvo' },
    { label: 'Nested RVO (Current yr)', key: 'nestedRvo', hide: true },
    {
      label: 'RINs Projected C/O Inventory (Previous yr)',
      key: 'projectedInventory',
      hide: true,
    },
    {
      label: 'Nested RINs Max Usable (previous yr)',
      key: 'nestedMaxUsable',
      hide: true,
    },
    {
      label: 'Nested RINs Contracted (current yr)',
      key: 'nestedContracted',
      hide: true,
    },
    {
      label: 'RINs Actual Inventory (current yr)',
      key: 'rinActualInventory',
      hide: true,
    },
    { label: 'Outstanding RVO', key: 'outstandingRvo', hide: true },
    { label: 'Nested Outstanding RVO', key: 'nestedOutstandingRvo', hide: true },
    { label: '% Of Plan Satisfied', key: 'percentOfPlanSatisfied', hide: true },
  ];

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
      <DropDown
        disabled
        onChange={(opt) => setFormValue({ ...formValue, rinType: opt })}
        value={formValue.rinType}
        label="RIN Type"
        options={[
          { label: 'D6 Ethanol RINs', value: 'd6' },
          { label: 'D3 or D7 Cellulosic Biofuel', value: 'd3OrD7' },
          { label: 'D4 Biomass Based Diesel', value: 'd4' },
          { label: 'D5 Advanced Biofuel', value: 'd5' },
        ]}
      />

      {rvoprojectedHeaders
        .filter((header) => !header.hide)
        .map((header) => (
          <TextField
            label={header.label}
            disabled={header.hide}
            type="number"
            value={formValue[header.key] || ''}
            onChange={(e) => {
              setFormValue({
                ...formValue,
                [header.key]: e.target.value,
              });
            }}
          />
        ))}

      <Button onClick={save}>Save</Button>
    </div>
  );
};

RvoTPForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  initialValue: PropTypes.shape({
    id: PropTypes.string,
    rinType: PropTypes.string,
    initialRvo: PropTypes.number,
    finalRvo: PropTypes.number,
    nestedRvo: PropTypes.number,
    projectedInventory: PropTypes.number,
    nestedMaxUsable: PropTypes.number,
    nestedContracted: PropTypes.number,
    rinActualInventory: PropTypes.number,
    outstandingRvo: PropTypes.number,
    nestedOutstandingRvo: PropTypes.number,
    percentOfPlanSatisfied: PropTypes.number,
  }).isRequired,
};

export default RvoTPForm;

import React from 'react';
import { TableCell, TableRow } from '@mui/material';
import { PaginationTable } from '../Common/PaginationTable/paginationTable';
import PropTypes from 'prop-types';

const SystemWideCreditGeneration = ({ chartData }) => {
  const { rows, columns } = chartData.table;

  const displayRow = (row) => (
    <TableRow>
      {columns.map((c) => (
        <TableCell key={Math.random()}>{row[c.dataField]}</TableCell>
      ))}
    </TableRow>
  );

  return <PaginationTable rows={rows} columns={columns} rowComponent={displayRow} />;
};

SystemWideCreditGeneration.propTypes = {
  chartData: PropTypes.shape({
    table: PropTypes.shape({
      rows: PropTypes.arrayOf(PropTypes.any),
      columns: PropTypes.arrayOf(PropTypes.shape({ dataField: PropTypes.string })),
    }),
  }).isRequired,
};

export { SystemWideCreditGeneration };

import React from 'react';
import { PaginationTable } from '../Common/PaginationTable/paginationTable';
import { CollapsibleRow } from './collapsibleRow';
import PropTypes from 'prop-types';

const RefinedDataTable = ({ rows, columns }) => (
  <PaginationTable
    rows={rows}
    columns={columns}
    rowComponent={(row) => <CollapsibleRow row={row} columns={columns} key={Math.random()} />}
    columnFilter={(c) => c.display}
    collapsible
  />
);

RefinedDataTable.propTypes = {
  rows: PropTypes.arrayOf(PropTypes.any.isRequired).isRequired,
  columns: PropTypes.arrayOf(PropTypes.any.isRequired).isRequired,
};

export { RefinedDataTable };
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { DateInput } from '../DateInput';
import { Button, TextField } from '@mui/material';

const ProductionTrackingForm = ({ saveTracking }) => {
  const [newTracking, setNewTracking] = useState({
    obtainedFrom: '',
    date: '',
    production: '',
  });

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
      <TextField
        label="Obtained From"
        type="text"
        value={newTracking.obtainedFrom}
        onChange={(e) => {
          setNewTracking({ ...newTracking, obtainedFrom: e.target.value });
        }}
      />

      <DateInput
        onChange={(value) => setNewTracking({ ...newTracking, date: value })}
        value={newTracking.date}
        label="Date"
      />

      <TextField
        label="Production"
        type="number"
        value={newTracking.production || ''}
        onChange={(e) => {
          setNewTracking({ ...newTracking, production: e.target.value });
        }}
      />

      <Button
        onClick={() => {
          saveTracking(newTracking);
        }}
      >
        Save
      </Button>
    </div>
  );
};

ProductionTrackingForm.propTypes = {
  saveTracking: PropTypes.func.isRequired,
};

export { ProductionTrackingForm };

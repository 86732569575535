import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import Switch from '@mui/material/Switch';
import React, { useState } from 'react';
import './CustomTable.scss';
import { Chip, Tooltip } from '@mui/material';
import { rinsLabels, rinsStyle } from './RinsConstants';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { grey } from '@mui/material/colors';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';

const StyledTableCell = styled(TableCell)(() => ({
  '&:nth-of-type(odd)': {
    backgroundColor: grey[50],
  },
}));

const nextOrderState = {
  asc: 'desc',
  desc: '',
  '': 'asc',
};

const CustomTable = ({ headers, rows, updateRow, maxHeight }) => {
  const [sortableHeader, setSortableHeader] = useState('');
  const [order, setOrder] = useState('asc');

  const toggleOrder = () => {
    setOrder((prevOrder) => nextOrderState[prevOrder]);
  };

  const onHeaderClicked = (header) => {
    if (header === sortableHeader) {
      toggleOrder();
    } else {
      setSortableHeader(header);
      setOrder('asc');
    }
  };

  const sortedRows = [...rows].sort((a, b) => {
    if (!order) return 0;
    const { key } = sortableHeader;
    const aElement = key === 'rinInfo' ? a[key].cost : a[key];
    const bElement = key === 'rinInfo' ? b[key].cost : b[key];

    if (sortableHeader.type === 'date') {
      return order === 'asc'
        ? new Date(aElement) - new Date(bElement)
        : new Date(bElement) - new Date(aElement);
    }

    if (typeof aElement === 'string') {
      return order === 'asc' ? aElement.localeCompare(bElement) : bElement.localeCompare(aElement);
    }

    return order === 'asc' ? aElement - bElement : bElement - aElement;
  });

  const getSortIcon = (header) => {
    if (header !== sortableHeader) {
      return null;
    }

    if (order === 'desc') {
      return <ArrowDropDownIcon />;
    }

    if (order === 'asc') {
      return <ArrowDropUpIcon />;
    }

    return null;
  };

  return (
    <TableContainer sx={{ maxHeight }} component={Paper}>
      <Table stickyHeader aria-label="simple table">
        <TableHead className="table-header">
          <TableRow>
            {headers
              .filter((h) => !h.hide)
              .map((header) => (
                <TableCell key={header.key} onClick={() => onHeaderClicked(header)}>
                  {getSortIcon(header)}
                  {header.label}
                </TableCell>
              ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {sortedRows.map((row) => (
            <TableRow
              key={row.id || row.name}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              {headers
                .filter((h) => !h.hide)
                .map((h) => {
                  if (h.component) {
                    return (
                      <StyledTableCell size="small" key={`${row.name}-${h.key}`}>
                        {h.component(row)}
                      </StyledTableCell>
                    );
                  }

                  if (h.type === 'boolean') {
                    return (
                      <StyledTableCell size="small" key={`${row.name}-${h.key}`}>
                        <Switch
                          defaultChecked={row[h.key]}
                          onChange={() => updateRow({ ...row, [h.key]: !row[h.key] })}
                        />
                      </StyledTableCell>
                    );
                  }

                  if (h.key === 'rinInfo') {
                    return (
                      <StyledTableCell size="small" key={`${row.name}-${h.key}`}>
                        <Tooltip title={rinsLabels[row[h.key].type]}>
                          <Chip label={row[h.key].cost} style={rinsStyle[row[h.key].type]} />
                        </Tooltip>
                      </StyledTableCell>
                    );
                  }

                  return (
                    <StyledTableCell size="small" key={`${row.name}-${h.key}`}>
                      {h.format ? h.format(row[h.key]) : row[h.key]}
                    </StyledTableCell>
                  );
                })}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

CustomTable.propTypes = {
  headers: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      hide: PropTypes.bool,
      type: PropTypes.string,
      component: PropTypes.func,
      format: PropTypes.func,
    })
  ).isRequired,
  rows: PropTypes.arrayOf(PropTypes.object).isRequired,
  updateRow: PropTypes.func,
  maxHeight: PropTypes.number,
};

CustomTable.defaultProps = {
  updateRow: () => {},
  maxHeight: 440,
};

export { CustomTable };

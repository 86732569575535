import { dateFilter, numberFilter, textFilter, Comparator } from 'react-bootstrap-table2-filter';

const today = new Date();
const thisMonth = today.getMonth() + 1;

const defaultDateFilter1 = dateFilter({
  defaultValue: {
    date: new Date(today.getFullYear() - 1, thisMonth <= 6 ? 5 : 11, 31),
    comparator: Comparator.GT,
  },
});

const commonMap = [
  {
    dataField: 'aggregation',
    text: 'Aggregation',
    filter: textFilter(),
    sort: true,
    editable: false,
    display:true,
  },
  {
    dataField: 'location',
    text: 'Location',
    filter: textFilter(),
    sort: true,
    editable: false,
    display:true,
    filterType: 'text'
  },
  {
    dataField: 'constituent',
    text: 'Constituent',
    filter: textFilter(),
    sort: true,
    editable: false,
    display:true,
  },
  {
    dataField: 'sampleidShort',
    text: 'Sample ID (Trunc)',
    filter: textFilter(),
    sort: true,
    editable: false,
    display:true,
  },
  {
    dataField: 'date',
    text: 'Date',
    filter: defaultDateFilter1,
    sort: true,
    editable: false,
    display:true,
    filterType: 'date'
  },
  {
    dataField: 'lowValue',
    text: 'Low Value',
    filter: numberFilter(),
    sort: true,
    editable: false,
    display:false,
  },
  {
    dataField: 'highValue',
    text: 'High Value',
    filter: numberFilter(),
    sort: true,
    editable: false,
    display:false,
  },
  {
    dataField: 'currentValue',
    text: 'Current Value',
    filter: numberFilter(),
    sort: true,
    editable: false,
    display:false,
  },
  {
    dataField: 'rpd',
    text: 'RPD',
    filter: numberFilter(),
    sort: true,
    editable: false,
    display:true,
  },
  {
    dataField: 'lowValueDate',
    text: 'Low Date',
    filter: dateFilter(),
    sort: true,
    editable: false,
    display:true,
    filterType: 'date'
  },
  {
    dataField: 'highValueDate',
    text: 'High Date',
    filter: dateFilter(),
    sort: true,
    editable: false,
    display:true,
    filterType: 'date'
  },
  {
    dataField: 'lastChangeDate',
    text: 'Last Change Date',
    filter: dateFilter(),
    sort: true,
    editable: false,
    display:true,
    filterType: 'date'
  },
  {
    dataField: 'lastChangeValue',
    text: 'Prior Value',
    filter: numberFilter(),
    sort: true,
    editable: false,
    display:false,
  },
];

const columnMapping = {
  all: commonMap,
};

for (const cm of Object.keys(columnMapping)) {
  for (const c of columnMapping[cm]) {
    if (!c.headerStyle && !c.style) {
      c.headerStyle = {
        minWidth: '135px',
      };
    }
  }
}

export default columnMapping;

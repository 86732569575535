import React from 'react';
import Chip from '@mui/material/Chip';
import { auditLabels, rinsLabels, rinsStyle, rinsTypes } from '../RinsConstants';
import { CustomTable } from '../CustomTable';
import moment from 'moment';

const priorYearLabels = {
  d3OrD7: 'Prior Year RINs Used - D code of 3 / 7',
  d4: 'Prior Year RINs Used - D code of 4',
  d5: 'Prior Year RINs Used - D code of 5',
  d6: 'Prior Year RINs Used - D code of 6',
};

const rvoBalance = {
  initial: 'Initial',
  d3OrD7: 'After Rins  3 / 7',
  d4: 'After Rins 4',
  d5: 'After Rins 5',
  d6: 'After Rins 6/ Deficit RVO',
};

const alternateLabels = {
  d3OrD7: 'Alternate D code of  3 / 7',
  d4: 'Alternate D code of 4',
  d5: 'Alternate D code of 5',
  d6: 'Alternate D code of 6',
};

const currentYears = {
  d3OrD7: 'Current Year RINs Used - D code of 3 / 7',
  d4: 'Current Year RINs Used - D code of 4',
  d5: 'Current Year RINs Used - D code of 5',
  d6: 'Current Year RINs Used - D code of 6',
};

const mapTransactions = (data) => {
  const transactions = data?.transactions || {};

  return (
    Object.keys(transactions).map((id) => ({
      ...transactions[id],
      id,
      cost: transactions[id].price * transactions[id].rinInfo.cost,
    })) || []
  );
};

const headers = (executed) => [
  { label: 'Transaction Date', key: 'transactionDate', hide: !executed },
  { label: 'Trade Partner', key: 'tradePartner' },
  { label: 'Buy / Sell', key: 'buySell' },
  { label: 'RIN', key: 'rinInfo' },
  { label: 'Rin Year', key: 'rinYear' },
  { label: 'Type', key: 'type' },
  { label: 'Price', key: 'price' },
  { label: 'Contract Date', key: 'contractDate' },
  { label: 'Expected Delivery', key: 'expectedDelivery', hide: executed },
  { label: 'RA Deal Number', key: 'raDealNumber' },
  { label: 'Cost', key: 'cost', format: (val) => Math.round(val) },
  { label: 'Notes', key: 'notes' },
];

const emtsHeaders = (year) => [
  { label: 'RIN type', key: 'type', format: (value) => rinsLabels[value] ?? '' },
  { label: year - 1, key: 'lastYear' },
  { label: year, key: 'currentYear' },
  { label: `${year} Retired`, key: 'retired' },
  { label: `Retirement date`, key: 'retirementDate' },
  { label: 'Total', key: 'total' },
];

const rvoprojectedHeaders = (year) => [
  {
    label: 'RIN type',
    key: 'type',
    format: (value) => <Chip label={rinsLabels[value]} style={rinsStyle[value]} />,
  },
  { label: 'Initial RVO %', key: 'initialRvo' },
  { label: 'Final RVO %', key: 'finalRvo' },
  { label: `${year} RVO`, key: 'currentRvo' },
  { label: `Nested RVO ${year}`, key: 'currentNestedRvo' },
  { label: `RINs Projected C/O Inventory ${year - 1}`, key: 'projectedLastYear' },
  { label: `Nested RINs Max Usable ${year - 1}`, key: 'nestedLastYear' },
  { label: `Nested RINs Contracted ${year}`, key: 'rinsContracted' },
  { label: `RINs Actual Inventory ${year}`, key: 'actualInventory' },
  { label: 'Outstanding RVO', key: 'outstandingRvo' },
  { label: '% Of Plan Satisfied', key: 'projectionSatisfiedPercentage' },
];

const objectFormat = (value, labels) => {
  const desiredOrder = ['initial', 'd3OrD7', 'd4', 'd5', 'd6'];

  return (
    <div style={{ display: 'grid', gridTemplateColumns: 'repeat(1,1fr)', gap: '.25rem' }}>
      {Object.keys(value)
        .sort((a, b) => desiredOrder.indexOf(a) - desiredOrder.indexOf(b))
        .map((key) => {
          return <Chip label={value[key]?.toLocaleString() || '-'} style={rinsStyle[key]} />;
        })}
    </div>
  );
};

const headersAuditCheck = [
  {
    label: 'Type',
    key: 'type',
    format: (value) =>
      auditLabels[value] ? <Chip label={auditLabels[value]} style={rinsStyle[value]} /> : '',
  },
  {
    label: 'Gasoline and Diesel Production/Renewable Fuel Export Volume',
    key: 'gasolineAndDieselProductionRenewableFuelExportVolume',
  },
  {
    label: 'Renewable Fuel Standard Value/Equivalence Value',
    key: 'renewableFuelStandardValueEquivalenceValue',
  },
  {
    label: 'Renewable Volume Obligation (RVO)',
    key: 'renewableVolumeObligationRvo',
  },
  {
    label: 'Prior Year Deficit',
    key: 'priorYearDeficit',
  },
  {
    label: 'Prior Year RIN C/O (Includes Contracts)',
    key: 'lastYearRinCOIncludesContracts',
  },
  {
    label: '20% raw max',
    key: 'rawMax20Percent',
  },
  {
    label: '20% nested optimal',
    key: 'nestedOptimal20Percent',
  },
  {
    label: 'Prior Year RINs Used -D Code',
    key: 'priorYearRinsUsedD',
    format: (value) => objectFormat(value, priorYearLabels),
  },
  {
    label: 'Prior Year RINs Used',
    key: 'priorYearRinsUsed',
  },
  {
    label: 'Prior Year Limit Check',
    key: 'priorYearLimitCheck',
  },
  {
    label: 'Excess Prior Year RIN C/O',
    key: 'excessLastYearRinCarryover',
  },
  {
    label: 'RVO Balance',
    key: 'rvoBalance',
    format: (value) => objectFormat(value, rvoBalance),
  },

  {
    label: 'Current Year RIN Inventory (Includes Contracts)',
    key: 'currentYearRinInventoryIncludesContracts',
  },
  {
    label: 'Current Year RINs Used',
    key: 'currentYearRinsUsed',
    format: (value) => objectFormat(value, currentYears),
  },

  {
    label: 'Alternate D code',
    key: 'alternate',
    format: (value) => objectFormat(value, alternateLabels),
  },
  {
    label: 'Carry Over',
    key: 'carryOver',
  },
];

const mapAudits = (data) => {
  const audits = data?.audits || {};

  return Object.keys(audits).map((id) => ({ ...audits[id], id })) || [];
};

function RinsGrid({ data, headers }) {
  return (
    <div
      style={{
        display: 'grid',
        gridTemplateColumns: 'repeat(5,1fr)',
        gap: '1rem',
        alignItems: 'center',
      }}
    >
      {headers.map((header) => {
        const columns = data.map((audit) => {
          const value = audit[header.key];
          if (header.format) {
            return <div>{header.format(value)}</div>;
          }

          return <Chip variant="outlined" color="primary" label={value} />;
        });

        return (
          <>
            <Chip color="primary" label={header.label} />
            {columns}
          </>
        );
      })}
    </div>
  );
}

const RinsReport = () => {
  const data = JSON.parse(localStorage.getItem('rin-report'));

  const emtsRows =
    Object.keys(data?.holdings ?? {}).map((id) => ({ ...data.holdings[id], id })) || [];
  const transactions = mapTransactions(data);

  const projectedRows =
    Object.keys(data?.projections ?? {}).map((id) => ({ ...data?.projections[id], id })) || [];

  const trackingRows = Object.values(data?.tracking ?? {}) || [];

  const currentTracking = trackingRows
    .sort((a, b) => (moment(a.date).isAfter(moment(b.date)) ? -1 : 1))
    .map((row) => Math.round(row.production))[0];

  const ptrackingHeaders = [
    { label: 'Obtained From', key: 'obtainedFrom' },
    { label: 'Date', key: 'date' },
    {
      label: 'Production',
      key: 'production',
      format: (value) => Math.round(value).toLocaleString(),
    },
  ];

  const audits = mapAudits(data);

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '1.2rem' }}>
      <h1>RINS Tracking Tool</h1>
      <div style={{ display: 'grid', gap: '1rem', gridTemplateColumns: 'repeat(4,1fr)' }}>
        <Chip color="primary" label="Company" />
        <Chip label={data.company?.toUpperCase()} />
        <Chip color="primary" label="Year" />
        <Chip label={data.year} />
      </div>

      <h2>Transactions</h2>

      <div
        style={{ display: 'flex', justifyContent: 'space-between', gap: '1rem', margin: '1rem 0 ' }}
      >
        {rinsTypes.map((type) => (
          <Chip label={rinsLabels[type]} style={rinsStyle[type]} />
        ))}
      </div>
      <h4>Contracted</h4>

      <CustomTable
        headers={headers(false)}
        rows={transactions.filter((r) => !r.executed)}
        maxHeight={null}
      />
      <h4>Executed Buy/Sell</h4>
      <CustomTable
        maxHeight={null}
        headers={headers(true)}
        rows={transactions
          .filter((r) => r.executed)
          .sort((a, b) => new Date(b.transactionDate) - new Date(a.transactionDate))}
      />

      <h2>RVO Tracking/Projection</h2>
      <h3>EMTS RIN Holdings</h3>
      <CustomTable headers={emtsHeaders(data.year)} rows={emtsRows} />

      <h3>Projected RVO Calculation</h3>
      <div style={{ display: 'grid', gridTemplateColumns: '1fr 50px' }}>
        <p>{`Production Year ${data.year}`}</p>
        <p style={{ textAlign: 'end' }}>{currentTracking?.toLocaleString()}</p>
      </div>
      <RinsGrid data={projectedRows} headers={rvoprojectedHeaders(data.year)} />

      <h3>Production tracking </h3>
      <div
        style={{
          display: 'grid',
          gap: '1rem',
          gridTemplateColumns: 'repeat(2,1fr)',
          gridTemplateRows: '1fr 6fr',
        }}
      >
        <div
          style={{
            gridRow: '1/3',
          }}
        >
          <CustomTable headers={ptrackingHeaders} rows={trackingRows} />
        </div>
        <Chip label="Notes" />
        <p>{data?.trackingNotes || ''}</p>
      </div>
      <h2>RVO Audit/Check</h2>
      <RinsGrid data={audits} headers={headersAuditCheck} />
      <Chip label="Notes" />
      <p>{data?.auditNotes || ''}</p>
    </div>
  );
};

export default RinsReport;

import React from 'react';
import logo from '../../images/magellan-horizontal.jpg';
import Image from 'react-bootstrap/Image';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import Container from 'react-bootstrap/Container';

import Navigation from '../Navigation';
import SignInPage from '../SignIn';
import PasswordForgetPage from '../PasswordForget';
import QMSPage from '../QMS';
import SortedPage from '../Sorted';
import AggregationPage from '../Aggregation';
import FacilityReviewPage from '../FacilityReview';
import ChangeLogPage from '../ChangeLog';
import MetricPage from '../Metric';
import ReportPage from '../Report';
import AccountPage from '../Account';
import AdminPage from '../Admin';
import LibraryPage from '../Library';

import * as ROUTES from '../../constants/routes';
import { withAuthentication } from '../Session';
import { createTheme, ThemeProvider } from '@mui/material';
import RinsPage from '../RINS/RinsPage';
import RinsReport from '../RINS/report/RinsReport';

const App = () => {
  const theme = createTheme({
    palette: {
      primary: {
        light: '#33935E',
        main: '#007936',
        dark: '#005425',
        contrastText: '#fff',
      },
      secondary: {
        light: '#8A8C80',
        main: '#6D7061',
        dark: '#4C4E43',
        contrastText: '#fff',
      },
    },
  });

  return (
    <Router>
      <ThemeProvider theme={theme}>
        <Route
          render={({ location }) => {
            const showNavigation = location.pathname !== ROUTES.RINS_REPORT;
            return showNavigation && <Navigation />;
          }}
        />

        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '1.25rem',
          }}
        >
          <Image
            src={logo}
            alt="Logo"
            fluid
            style={{ gridRow: '1/2', gridColumn: '2/3', margin: 'auto' }}
          />
          <Container style={{ marginTop: '1.25rem' }}>
            <Route exact path={ROUTES.LANDING} component={SignInPage} />
            <Route path={ROUTES.SIGN_IN} component={SignInPage} />
            <Route path={ROUTES.PASSWORD_FORGET} component={PasswordForgetPage} />
            <Route path={ROUTES.METRICS} component={MetricPage} />
            <Route path={ROUTES.QMS} component={QMSPage} />
            <Route path={ROUTES.REFINED_DATA} component={SortedPage} />
            <Route path={ROUTES.AGGREGATIONS} component={AggregationPage} />
            <Route path={ROUTES.CHANGE_LOG} component={ChangeLogPage} />
            <Route path={ROUTES.FACILITY_REVIEW} component={FacilityReviewPage} />
            <Route path={ROUTES.REPORTS} component={ReportPage} />
            <Route path={ROUTES.ACCOUNT} component={AccountPage} />
            <Route path={ROUTES.ADMIN} component={AdminPage} />
            <Route path={ROUTES.LIBRARY} component={LibraryPage} />
            <Route path={ROUTES.RINS} component={RinsPage} />
            <Route path={ROUTES.RINS_REPORT} component={RinsReport} />
          </Container>
        </div>
      </ThemeProvider>
    </Router>
  );
};

export default withAuthentication(App);

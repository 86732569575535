import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { SystemWideMarginTracker } from './systemWideMarginTracker';
import { PerformanceAcrossFacilities } from './performanceAcrossFacilities';
import { SingleFacilityPerformance } from './singleFacilityPerformance';
import { SystemWideCreditGeneration } from './systemWideCreditGeneration';
import { ButtonTabs } from '../Common/ButtonTabs/buttonTabs';

const ChartsAndTable = ({ chartData, facilities, options, substances }) => {
  const [selectedTab, setSelectedTab] = useState(0);

  const tabDisplay = [
    {
      title: 'System wide Margin Tracking',
      component: (
        <SystemWideMarginTracker chartData={chartData} options={options} substances={substances} />
      ),
    },
    {
      title: 'Performance Across Facilities',
      component: (
        <PerformanceAcrossFacilities
          chartData={chartData}
          facilities={facilities}
          options={options}
          substances={substances}
        />
      ),
    },
    {
      title: 'Single Facility Performance',
      component: (
        <SingleFacilityPerformance
          chartData={chartData}
          options={options}
          substances={substances}
        />
      ),
    },
    {
      title: 'System Wide Credit Generation',
      component: <SystemWideCreditGeneration chartData={chartData} />,
    },
  ];

  return (
    <>
      <ButtonTabs
        tabDisplay={tabDisplay}
        selectedTab={selectedTab}
        setSelectedTab={setSelectedTab}
      />
      {tabDisplay[selectedTab].component}
    </>
  );
};

ChartsAndTable.propTypes = {
  chartData: PropTypes.shape({}).isRequired,
  facilities: PropTypes.shape({}).isRequired,
  options: PropTypes.shape({}).isRequired,
  substances: PropTypes.arrayOf(PropTypes.any).isRequired,
};

export { ChartsAndTable };

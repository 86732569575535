import React, { useEffect, useState } from 'react';

import { CustomTable } from '../CustomTable';
import { Button, Chip, IconButton, TextField, Tooltip } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { RightPanel } from '../../Common/Panels/rightPanel';
import { Loader } from '../Loader';
import { AuditForm } from './AuditForm';
import { auditLabels, rinsStyle, rinsTypes } from '../RinsConstants';
import PropTypes from 'prop-types';
import Firebase from '../../Firebase/firebase';

const priorYearLabels = {
  d3OrD7: 'Prior Year RINs Used - D code of 3 / 7',
  d4: 'Prior Year RINs Used - D code of 4',
  d5: 'Prior Year RINs Used - D code of 5',
  d6: 'Prior Year RINs Used - D code of 6',
};

const rvoBalance = {
  initial: 'Initial',
  d3OrD7: 'After Rins  3 / 7',
  d4: 'After Rins 4',
  d5: 'After Rins 5',
  d6: 'After Rins 6/ Deficit RVO',
};

const alternateLabels = {
  d3OrD7: 'Alternate D code of  3 / 7',
  d4: 'Alternate D code of 4',
  d5: 'Alternate D code of 5',
  d6: 'Alternate D code of 6',
};

const currentYears = {
  d3OrD7: 'Current Year RINs Used - D code of 3 / 7',
  d4: 'Current Year RINs Used - D code of 4',
  d5: 'Current Year RINs Used - D code of 5',
  d6: 'Current Year RINs Used - D code of 6',
};

const mapAudits = (data) => {
  const audits = data?.audits || {};

  return Object.keys(audits).map((id) => ({ ...audits[id], id })) || [];
};
const RVOAuditCheck = ({ firebase, year, company, data }) => {
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [rowToUpdate, setRowToUpdate] = useState({});
  const [notes, setNotes] = useState(data?.auditNotes || '');

  useEffect(() => {
    setNotes(data?.auditNotes || '');
  }, [data, setNotes]);

  const rows = mapAudits(data);

  const objectFormat = (value, labels) => {
    const desiredOrder = ['initial', 'd3OrD7', 'd4', 'd5', 'd6'];

    return (
      <div style={{ display: 'grid', gridTemplateColumns: 'repeat(2,1fr)', gap: '.25rem' }}>
        {Object.keys(value)
          .sort((a, b) => desiredOrder.indexOf(a) - desiredOrder.indexOf(b))
          .map((key) => {
            return (
              <Tooltip title={labels[key]}>
                <Chip label={value[key]?.toLocaleString() || '-'} style={rinsStyle[key]} />
              </Tooltip>
            );
          })}
      </div>
    );
  };

  const headers = [
    {
      label: 'Type',
      key: 'type',
      format: (value) => auditLabels[value] ?? '',
    },
    {
      label: 'Gasoline and Diesel Production/ Renewable Fuel Export Volume',
      key: 'gasolineAndDieselProductionRenewableFuelExportVolume',
    },
    {
      label: 'Renewable Fuel Standard Value/ Equivalence Value',
      key: 'renewableFuelStandardValueEquivalenceValue',
    },
    {
      label: 'Renewable Volume Obligation (RVO)',
      key: 'renewableVolumeObligationRvo',
    },
    {
      label: 'Prior Year Deficit',
      key: 'priorYearDeficit',
    },
    {
      label: 'Prior Year RIN C/O (Includes Contracts)',
      key: 'lastYearRinCOIncludesContracts',
    },
    {
      label: '20% raw max',
      key: 'rawMax20Percent',
    },
    {
      label: '20% nested optimal',
      key: 'nestedOptimal20Percent',
    },
    {
      label: 'Prior Year RINs Used -D Code',
      key: 'priorYearRinsUsedD',
      format: (value) => objectFormat(value, priorYearLabels),
    },
    {
      label: 'Prior Year RINs Used',
      key: 'priorYearRinsUsed',
    },
    {
      label: 'Prior Year Limit Check',
      key: 'priorYearLimitCheck',
    },
    {
      label: 'Excess Prior Year RIN C/O',
      key: 'excessLastYearRinCarryover',
    },
    {
      label: 'RVO Balance',
      key: 'rvoBalance',
      format: (value) => objectFormat(value, rvoBalance),
    },

    {
      label: 'Current Year RIN Inventory (Includes Contracts)',
      key: 'currentYearRinInventoryIncludesContracts',
    },
    {
      label: 'Current Year RINs Used',
      key: 'currentYearRinsUsed',
      format: (value) => objectFormat(value, currentYears),
    },

    {
      label: 'Alternate D code',
      key: 'alternate',
      format: (value) => objectFormat(value, alternateLabels),
    },
    {
      label: 'Carry Over',
      key: 'carryOver',
    },
    {
      label: 'Alternate Carry Over',
      key: 'alternateCarryOver',
    },
    {
      label: 'Actions',
      key: 'actions',
      component: (row) => (
        <div style={{ display: 'flex' }}>
          <Tooltip title="Edit">
            <IconButton aria-label="edit" size="small" onClick={() => updateAudit(row)}>
              <EditIcon />
            </IconButton>
          </Tooltip>
        </div>
      ),
    },
  ];

  const updateAudit = (row) => {
    setRowToUpdate(row);
    setOpenModal(true);
  };

  const updateRow = async (newRow) => {
    setLoading(true);
    await firebase.updateAudit({ year, company, audit: newRow });
    // setRows(rows.map((row) => (row.type === newRow.type ? newRow : row)));
    setLoading(false);
    setOpenModal(false);
  };

  const saveNotes = () => {
    setLoading(true);
    firebase.updateAuditNotes({ year, company, notes }).finally(() => setLoading(false));
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
      <div
        style={{ display: 'flex', justifyContent: 'space-between', gap: '1rem', margin: '1rem 0' }}
      >
        {rinsTypes.map((type) => (
          <Chip label={auditLabels[type]} style={rinsStyle[type]} />
        ))}
      </div>
      <CustomTable rows={rows} headers={headers} maxHeight={1000} />
      <TextField
        label="Notes"
        multiline
        rows={4}
        value={notes}
        onChange={(e) => setNotes(e.target.value)}
      />
      <Button variant="outlined" color="secondary" onClick={saveNotes}>
        Save Notes
      </Button>
      <RightPanel open={openModal} setOpen={setOpenModal}>
        <AuditForm rowToUpdate={rowToUpdate} onUpdate={updateRow} />
      </RightPanel>
      <Loader isLoading={loading} />
    </div>
  );
};

RVOAuditCheck.propTypes = {
  year: PropTypes.number.isRequired,
  company: PropTypes.string.isRequired,
  data: PropTypes.shape({
    audits: PropTypes.object,
    auditNotes: PropTypes.string,
  }).isRequired,
  firebase: PropTypes.instanceOf(Firebase).isRequired,
};

export { RVOAuditCheck };

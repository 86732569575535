import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';

import Spinner from '../Spinner';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Accordion';
import { withAuthorization, withEmailVerification } from '../Session';
import Firebase, { withFirebase } from '../Firebase';

import 'react-datepicker/dist/react-datepicker.css';
import '../../../node_modules/react-vis/dist/style.css';
import { FacilityInfo } from './facilityInfo';
import { DatePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { loadFacility } from './facilityService';
import PropTypes from 'prop-types';

class FacilityReviewPageBase extends Component {
  constructor(props) {
    super(props);

    const today = new Date();
    const thisMonth = today.getMonth() + 1;
    const startDate = new Date(today.getFullYear() - 1, thisMonth <= 6 ? 5 : 11, 31);

    this.state = {
      loading: true,
      selectedFacility: '',
      facility: {},
      minDate: startDate,
      maxDate: today,
    };

    this.changeNotes = this.changeNotes.bind(this);
    this.changeMinDate = this.changeMinDate.bind(this);
    this.changeMaxDate = this.changeMaxDate.bind(this);
  }

  async componentDidMount() {
    this.setState({ loading: true });
    const { firebase } = this.props;

    const allFacillities = (await firebase.facilities().once('value')).val().sort();
    await this.notesFetcher();
    await this.setState({ allFacillities, selectedFacility: allFacillities[0] });
  }

  // eslint-disable-next-line no-unused-vars
  componentDidUpdate(prevProps, prevState, snapshot) {
    const { selectedFacility } = this.state;

    if (prevState.selectedFacility !== selectedFacility) {
      this.updateFilteredData();
    }
  }

  notesFetcher = async () => {
    const { selectedFacility } = this.state;

    const { firebase } = this.props;

    const notesSnapshot = await firebase.notes('facilityreview').once('value');
    const notes = notesSnapshot.val() || {};

    const cleanName = firebase.cleanName(selectedFacility);
    if (!notes[selectedFacility]) {
      notes[selectedFacility] = notes[cleanName] || '';
    }
    this.setState({ notes });
  };

  updateFilteredData = async () => {
    const { selectedFacility, minDate, maxDate } = this.state;
    const { firebase } = this.props;
    this.setState({ loading: true });
    const facility = await loadFacility({
      selectedFacility,
      firebase,
      minDate,
      maxDate,
    });

    this.setState({ loading: false, facility });
  }; // updateFilteredData

  changeNotes(event) {
    if (
      event.target.id &&
      event.target.id.includes('-notes') &&
      event.target &&
      event.target.value
    ) {
      const { firebase } = this.props;
      const newValue = event.target.value;
      const facility = event.target.id.replace(/-notes/, '');
      const writeObj = {};
      writeObj[firebase.cleanName(facility)] = newValue;
      this.setState(
        (prevState) => {
          const notes = { ...prevState.notes };
          notes[facility] = newValue;
          return { notes };
        },
        () => {
          firebase.saveNote('facilityreview', writeObj);
        }
      );
    }
  }

  changeMinDate(date) {
    this.setState({ loading: true, minDate: date });

    setTimeout(() => {
      this.updateFilteredData();
    }, 200);
  }

  changeMaxDate(date) {
    this.setState({ loading: true, maxDate: date });
    setTimeout(() => {
      this.updateFilteredData();
    }, 200);
  }

  render() {
    const {
      loading,
      notes,
      selectedFacility,
      allFacillities,
      facility,
      minDate,
      maxDate,
    } = this.state;

    return (
      <Container fluid="true">
        <h1>Facility Review</h1>
        <Row style={{ gap: '1.25rem', flexWrap: 'nowrap' }}>
          <FormControl fullWidth>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
                label="Min Date"
                onChange={this.changeMinDate}
                value={minDate}
                renderInput={(params) => {
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  return <TextField {...params} />;
                }}
                style={{ width: '100%' }}
                maxDate={maxDate}
              />
            </LocalizationProvider>
          </FormControl>
          <FormControl fullWidth>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
                label="Max Date"
                value={maxDate}
                onChange={this.changeMaxDate}
                style={{ width: '100%' }}
                renderInput={(params) => {
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  return <TextField {...params} />;
                }}
                minDate={minDate}
              />
            </LocalizationProvider>
          </FormControl>
          <FormControl fullWidth>
            <InputLabel id="facility-label">Facility</InputLabel>
            <Select
              labelId="facility-label"
              label="Facility"
              value={selectedFacility}
              onChange={(e) => this.setState({ selectedFacility: e.target.value })}
              disabled={!allFacillities}
            >
              {allFacillities && allFacillities.map((f) => <MenuItem value={f}>{f}</MenuItem>)}
            </Select>
          </FormControl>
        </Row>
        <hr />
        <Row>
          {loading && <Spinner loading={loading} />}
          {!loading && selectedFacility && (
            <FacilityInfo
              facilityName={selectedFacility}
              facility={facility}
              notes={notes}
              changeNotes={this.changeNotes}
            />
          )}
        </Row>
      </Container>
    );
  }
}

FacilityReviewPageBase.propTypes = {
  firebase: PropTypes.instanceOf(Firebase).isRequired,
};

const condition = (authUser) => !!authUser;

const FacilityReviewPage = withFirebase(FacilityReviewPageBase);

export default compose(
  withEmailVerification,
  withAuthorization(condition),
  withRouter,
  withFirebase
)(FacilityReviewPage);

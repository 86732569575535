import app from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/storage';

const config = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
};

class Firebase {
  constructor() {
    app.initializeApp(config);

    /* Helper */

    this.serverValue = app.database.ServerValue;
    this.emailAuthProvider = app.auth.EmailAuthProvider;

    /* Firebase APIs */

    this.auth = app.auth();
    this.db = app.database();
    this.storage = app.storage();

    /* Social Sign In Method Provider */

    this.googleProvider = new app.auth.GoogleAuthProvider();
    this.facebookProvider = new app.auth.FacebookAuthProvider();
    this.twitterProvider = new app.auth.TwitterAuthProvider();

    this.dataPath = 'mmp';
  }

  // *** Auth API ***

  doCreateUserWithEmailAndPassword = (email, password) =>
    this.auth.createUserWithEmailAndPassword(email, password);

  doSignInWithEmailAndPassword = (email, password) =>
    this.auth.signInWithEmailAndPassword(email, password);

  doSignInWithGoogle = () => this.auth.signInWithPopup(this.googleProvider);

  doSignInWithFacebook = () => this.auth.signInWithPopup(this.facebookProvider);

  doSignInWithTwitter = () => this.auth.signInWithPopup(this.twitterProvider);

  doSignOut = () => this.auth.signOut();

  doPasswordReset = (email) => this.auth.sendPasswordResetEmail(email);

  doSendEmailVerification = () =>
    this.auth.currentUser.sendEmailVerification({
      url: process.env.REACT_APP_CONFIRMATION_EMAIL_REDIRECT,
    });

  doPasswordUpdate = (password) => this.auth.currentUser.updatePassword(password);

  // *** Merge Auth and DB User API *** //

  onAuthUserListener = (next, fallback) =>
    this.auth.onAuthStateChanged((authUserIn) => {
      let authUser = authUserIn;
      if (authUser) {
        this.user(authUser.uid)
          .once('value')
          .then((snapshot) => {
            let dbUser = snapshot.val();

            // default empty roles
            if (!dbUser) {
              dbUser = {};
            }
            if (!dbUser.roles) {
              dbUser.roles = {};
            }

            // merge auth and db user
            authUser = {
              uid: authUser.uid,
              email: authUser.email,
              emailVerified: authUser.emailVerified,
              providerData: authUser.providerData,
              ...dbUser,
            };

            if (authUser.data) {
              this.dataPath = authUser.data;
            }

            next(authUser);
          });
      } else {
        fallback();
      }
    });

  // *** Storage ***
  storageRef = (rid) => this.storage.ref(rid);

  // *** Notes API ***

  notes = (nid) => this.db.ref(`data/${this.dataPath}/notes/${nid}`);

  saveNote = (nid, updateObj) =>
    this.db.ref(`data/${this.dataPath}/notes`).child(`${nid}`).update(updateObj);

  // *** ChangeLog API ***

  changelog = (fid) => this.db.ref(`data/${this.dataPath}/changelog/${fid}`);

  changelogs = () => this.db.ref(`data/${this.dataPath}/changelog`);

  // *** Report API ***
  report = (fid, path) => this.db.ref(`data/${path || this.dataPath}/reports/${fid}`);

  gsf0402ReportByFacility = async (facilityname) =>
    this.db
      .ref(`data/${this.dataPath}/reports/gsf0402`)
      .orderByChild('facilityname')
      .equalTo(facilityname)
      .once('value');

  reports = () => this.db.ref(`data/${this.dataPath}/reports`);

  // *** Agg API ***

  agg = (fid, path) => this.db.ref(`data/${path || this.dataPath}/aggs/${fid}`);

  aggs = () => this.db.ref(`data/${this.dataPath}/aggs`);

  aggsByFacility = (aggType, location) => {
    const refpath = `data/${this.dataPath}/aggs/${aggType}`;
    return this.db.ref(refpath).orderByChild('location').equalTo(location).once('value');
  };

  facilities = () => this.db.ref(`data/${this.dataPath}/aggs/facilities`);

  // *** Sorts API ***

  sort = (fid) => this.db.ref(`data/${this.dataPath}/sorts/${fid}`);

  sorts = () => this.db.ref(`data/${this.dataPath}/sorts`);

  sortRvpByFacility = (location) =>
    this.db
      .ref(`data/${this.dataPath}/sorts/rvp`)
      .orderByChild('location')
      .equalTo(location)
      .once('value');

  // *** Files API ***

  fileQuantity = async (fid) =>
    (await this.db.ref(`data/${this.dataPath}/files/${fid}`).once('value')).numChildren();
  // file = (fid) => this.db.ref(`data/${this.dataPath}/files/${fid}`);
  file = ({ fid, page = 0, pageSize = 100 }) =>
    this.db
      .ref(`data/${this.dataPath}/files/${fid}`)
      .orderByKey()
      .startAt(page.toString())
      .endAt((page + pageSize - 1).toString());

  fileRest = ({ fid, page = 0, pageSize = 100 }) =>
    this.db
      .ref(`data/${this.dataPath}/files/${fid}`)
      .orderByKey()
      .startAt((page + pageSize).toString());

  files = () => this.db.ref(`data/${this.dataPath}/files`);

  // *** User API ***

  user = (uid) => this.db.ref(`users/${uid}`);

  users = () => this.db.ref('users');

  // *** Message API ***

  message = (uid) => this.db.ref(`messages/${uid}`);

  messages = () => this.db.ref('messages');

  // ** clean values **
  cleanName = (name) => name.replace(/[.#$/[\]]/g, '');

  rin = async ({ year, company }) => {
    return this.db
      .ref(`data/${this.dataPath}/rin/${company}/${year}/transactions`)
      .once('value')
      .then((snapshot) => {
        const snap = snapshot.val();
        if (!snap) return [];

        return (
          Object.keys(snap).map((id) => ({
            ...snap[id],
            id,
            cost: snap[id].price * snap[id].rinInfo.cost,
          })) || []
        );
      });
  };

  deleteRin = async ({ id, year, company }) => {
    const reference = this.db.ref(
      `data/${this.dataPath}/rin/${company}/${year}/transactions/${id}`
    );
    await reference.remove();
    return id;
  };

  updateRin = async ({ year, company, rin }) => {
    const reference = this.db.ref(
      `data/${this.dataPath}/rin/${company}/${year}/transactions/${rin.id}`
    );

    try {
      return await reference.update(rin);
    } catch (e) {
      throw new Error(`error updating rin ${e.message}`);
    }
  };

  newRin = async ({ rin, year, company }) => {
    const reference = this.db.ref(`data/${this.dataPath}/rin/${company}/${year}/transactions`);
    const thenableReference = await reference.push(rin);
    return thenableReference
      .once('value')
      .then((snapshot) => ({ ...rin, id: snapshot.key, cost: rin.price * rin.rinInfo.cost }));
  };

  newRins = async ({ transactions, company }) => {
    const years = Object.keys(transactions);

    await Promise.all(
      years.map((year) => {
        const reference = this.db.ref(`data/${this.dataPath}/rin/${company}/${year}`);
        return reference.child('skipUpdate').set(true);
      })
    );

    await Promise.all(
      years.map((year) => {
        const reference = this.db.ref(`data/${this.dataPath}/rin/${company}/${year}/transactions`);
        return Promise.all(transactions[year].map((rin) => reference.child(rin.id).set(rin)));
      })
    );

    await Promise.all(
      years.map((year) => {
        const reference = this.db.ref(`data/${this.dataPath}/rin/${company}/${year}`);
        return reference.child('skipUpdate').set(false);
      })
    );
  };

  newTracking = async ({ tracking, company }) => {
    await Promise.all(
      Object.keys(tracking).map((year) => {
        const reference = this.db.ref(`data/${this.dataPath}/rin/${company}/${year}/tracking`);
        return Promise.all(tracking[year].map((rin) => reference.push(rin)));
      })
    );
  };
  rinListener = async ({ year, company, listener, dataRef }) => {
    const newDataPath = `data/${this.dataPath}/rin/${company}/${year}`;

    function onDataChange(snapshot) {
      const snap = snapshot.val();

      listener(snap);
    }

    // Check if the dataRef needs to be updated
    if (dataRef.currentPath !== newDataPath) {
      // Remove the previous listener (if any)
      if (dataRef.current !== null) {
        dataRef.current.off('value', onDataChange);
      }
      // Set up a new listener for the updated data path
      dataRef.current = this.db.ref(newDataPath);
      dataRef.currentPath = newDataPath;
    }

    return dataRef.current.on('value', onDataChange);
  };

  updateTrackingNotes = async ({ year, company, notes }) => {
    return this.updateRinNotes({ company, year, notes, noteKey: `trackingNotes` });
  };
  updateAuditNotes = async ({ year, company, notes }) => {
    return this.updateRinNotes({ company, year, notes, noteKey: `auditNotes` });
  };

  updateRinNotes({ company, year, notes, noteKey }) {
    const updateData = {};
    updateData[`data/${this.dataPath}/rin/${company}/${year}/${noteKey}`] = notes;

    return this.db.ref().update(updateData);
  }

  updateProjectedRvo = async ({ year, company, rvo }) => {
    const reference = this.db.ref(
      `data/${this.dataPath}/rin/${company}/${year}/projections/${rvo.id}`
    );

    try {
      return await reference.update(rvo);
    } catch (e) {
      throw new Error(`error updating rin ${e.message}`);
    }
  };

  newRvoTracking = async ({ tracking, year, company }) => {
    const reference = this.db.ref(`data/${this.dataPath}/rin/${company}/${year}/tracking`);
    const thenableReference = await reference.push(tracking);
    return thenableReference.once('value').then((snapshot) => ({ ...tracking, id: snapshot.key }));
  };

  updateAudit = async ({ year, company, audit }) => {
    const reference = this.db.ref(
      `data/${this.dataPath}/rin/${company}/${year}/audits/${audit.id}`
    );

    try {
      return await reference.update(audit);
    } catch (e) {
      throw new Error(`error updating audit ${e.message}`);
    }
  };

  updateEMTSHolding = async ({ year, company, holding }) => {
    const reference = this.db.ref(
      `data/${this.dataPath}/rin/${company}/${year}/holdings/${holding.id}`
    );

    try {
      return await reference.update(holding);
    } catch (e) {
      throw new Error(`error updating holding ${e.message}`);
    }
  };
}

export default Firebase;

/* eslint-disable react/no-array-index-key */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-console */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/jsx-filename-extension */
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import Row from 'react-bootstrap/Row';
import { Type } from 'react-bootstrap-table2-editor';
import { numberFilter, selectFilter, textFilter } from 'react-bootstrap-table2-filter';

import Spinner from '../Spinner';
import LinkIcon from '@mui/icons-material/Link';
import PasswordIcon from '@mui/icons-material/Password';

import { withAuthorization, withEmailVerification } from '../Session';
import { withFirebase } from '../Firebase';
import { PaginationTable } from '../Common/PaginationTable/paginationTable';
import {
  Button,
  FormControl,
  InputLabel,
  Link,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  TableCell,
  TableRow,
  TextField,
} from '@mui/material';
import { ButtonTabs } from '../Common/ButtonTabs/buttonTabs';

let facilities = [];
let folders = [];
let filesRows = [];
let activePromises = [];
const years = [];
for (let i = 2020; i <= new Date().getFullYear(); i += 1) {
  years.push(i);
}
const selectOptionsYN = [
  { value: 'Y', label: 'Y' },
  { value: 'N', label: 'N' },
];
const documentTypes = [
  {
    id: 'brec',
    name: 'Blendstock Receipt',
  },
  {
    id: 'prec',
    name: 'Pipeline Receipt',
  },
  {
    id: 'scoa',
    name: 'Supplier COA',
  },
  {
    id: 'rcoa',
    name: 'Refiner COA',
  },
  {
    id: 'oth',
    name: 'Other',
  },
];

function getFileExtension(fn) {
  const elts = fn.split('.');
  let ext = '';
  if (elts.length > 0) {
    ext = elts.pop();
  }
  return ext.length <= 5 ? ext : '';
}

function getDateAndTime() {
  const today = new Date();
  const time = `${today.getHours()}-${today.getMinutes()}-${today.getSeconds()}`;
  const date = `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`;
  return `${date}-${time}`;
}

function makeStoragePromise(uploadTask) {
  return new Promise((resolve, reject) => {
    try {
      uploadTask.on(
        'state_changed',
        (snapshot) => {
          if (snapshot.bytesTransferred === snapshot.totalBytes) {
            // give it half a sec to register
            setTimeout(() => resolve(), 500);
          }
        },
        (error) => {
          console.log(`promise error: ${error}`);
          reject(error);
        }
      );
    } catch (e) {
      console.log(`try/catch error: ${e}`);
      reject(e);
    }
  });
}

const AccessInformation = () => (
  <List>
    <ListItem disablePadding>
      <ListItemButton>
        <ListItemIcon>
          <LinkIcon />
        </ListItemIcon>
        <ListItemText
          primary="URL"
          secondary={(
            <Link target="_blank" href="https://compliance-browser.netlify.app/">
              netlify
            </Link>
          )}
        />
      </ListItemButton>
    </ListItem>
    <ListItem disablePadding>
      <ListItemButton>
        <ListItemIcon>
          <PasswordIcon />
        </ListItemIcon>
        <ListItemText primary="Password" secondary="compliance589" />
      </ListItemButton>
    </ListItem>
  </List>
);

class LibraryPageBase extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      columns: [
        {
          dataField: 'year',
          text: 'Year',
          sort: true,
          filter: numberFilter(),
        },
        {
          dataField: 'folder',
          text: 'Folder',
          sort: true,
          filter: textFilter(),
        },
        {
          dataField: 'documentType',
          text: 'Document Type',
          sort: true,
          filter: textFilter(),
        },
        {
          dataField: 'sampleId',
          text: 'Sample Id',
          sort: true,
          filter: textFilter(),
        },
        {
          dataField: 'public',
          text: 'Public',
          sort: true,
          editable: true,
          editor: {
            type: Type.CHECKBOX,
            value: 'Y:N',
          },
          filter: selectFilter({ options: selectOptionsYN }),
        },
        {
          dataField: 'path',
          text: 'Unique Name',
          sort: true,
          filter: textFilter(),
        },
        {
          dataField: 'originalFileName',
          text: 'Original File Name',
          sort: true,
          filter: textFilter(),
        },
      ],
      year: new Date().getFullYear(),
      folderIndex: '',
      folderName: '',
      documentType: 'oth',
      sampleId: '',
      selectedTab: 0,
    };
    this.loadValues = this.loadValues.bind(this);
    this.loadFiles = this.loadFiles.bind(this);
    this.changeUploadOptions = this.changeUploadOptions.bind(this);
    this.doUpload = this.doUpload.bind(this);
    this.saveActive = this.saveActive.bind(this);
    this.doSaveActive = this.doSaveActive.bind(this);
  }

  componentDidMount() {
    this.loadValues();
    this.loadFiles();
  }

  async getRow(fileRef, year, folder) {
    const metadata = await fileRef.getMetadata();
    const file = String(fileRef).split('/').pop();
    const [documentType, sampleIdAttempt, dateTimeAttempt, , extAttempt] = file.split('.');
    const sampleId = extAttempt ? sampleIdAttempt : '';
    const dateTime = extAttempt ? dateTimeAttempt : sampleIdAttempt;
    return {
      fileRef,
      year,
      folder,
      documentType,
      sampleId,
      dateTime,
      fileName: String(fileRef),
      key: String(fileRef),
      path: file,
      public:
        metadata && metadata.customMetadata && metadata.customMetadata.public !== 'Y' ? 'N' : 'Y',
      originalFileName:
        (metadata && metadata.customMetadata && metadata.customMetadata.originalFileName) ||
        'undefined',
    };
  }

  async loadValues() {
    const [facilitiesSnapshot] = await Promise.all([
      // eslint-disable-next-line react/destructuring-assignment
      this.props.firebase.agg('facilities').once('value'),
    ]);
    const facilityArray = facilitiesSnapshot.val();
    if (facilityArray) {
      facilities = facilityArray.sort();
      folders = [...facilities];
    }
  }

  async loadFiles() {
    filesRows = [];
    folders = [...facilities];
    const rootPath = `library/mmp`;
    const storageRef = this.props.firebase.storageRef(rootPath);
    const yearItems = await storageRef.listAll();

    const yearFolders = await Promise.all(
      yearItems.prefixes.map((yearRef) => {
        const year = String(yearRef).split('/').pop();
        return yearRef.listAll().then((folderItems) => ({ folderItems, year }));
      })
    );

    const facilityFolders = await Promise.all(
      yearFolders.flatMap(({ folderItems, year }) =>
        folderItems.prefixes.map((folderRef) => {
          const folder = String(folderRef).split('/').pop();
          if (folders.indexOf(folder) < 0) {
            folders.push(folder);
          }

          return folderRef.listAll().then((fileItems) => ({ fileItems, year, folder }));
        })
      )
    );

    const rows = await Promise.all(
      facilityFolders.flatMap(({ fileItems, year, folder }) =>
        fileItems.items.map((fileRef) => this.getRow(fileRef, year, folder))
      )
    );

    filesRows.push(...rows);

    folders = folders.sort();

    this.setState({ loading: false });
  }

  changeUploadOptions(event) {
    this.setState({ loading: false, [event.target.id]: event.target.value });
  }

  // TODO check how to implement this
  async saveActive(oldValue, newValue, row) {
    // Create a reference to the file whose metadata we want to change
    const { fileRef } = row;
    const newMetadata = {
      customMetadata: {
        public: newValue,
      },
    };
    activePromises.push(fileRef.updateMetadata(newMetadata));
  }

  // TODO check how to implement this
  async doSaveActive() {
    this.setState({ loading: true });
    // Update metadata properties
    await Promise.all(activePromises);
    activePromises = [];
    await this.loadFiles();
    this.setState({ loading: false });
  }

  async doUpload() {
    const { year, folderIndex, documentType, folderName, sampleId, files } = this.state;

    this.setState({ loading: true });

    if (files) {
      const promiseArray = [];
      const fileList = Object.keys(files);
      const folder = String(folderIndex) === '-1' ? folderName || 'other' : folders[folderIndex];
      let fileIndex = 0;
      for (const fileNum of fileList) {
        fileIndex += 1;
        const file = files[fileNum];
        const ext = getFileExtension(file.name);
        const dateAndTime = getDateAndTime();
        const filenamePrefix = `${year}/${folder}/${documentType}.${
          sampleId ? `${sampleId}.` : ''
        }${dateAndTime}.${fileIndex}`;
        const filename = `${filenamePrefix}.${file.name}.${ext}`;
        const storageRef = this.props.firebase.storageRef(
          `library/${this.props.authUser?.libraryData || 'mmp'}/${filename}`
        );
        promiseArray.push(
          makeStoragePromise(
            storageRef.put(file, {
              customMetadata: { originalFileName: file.name },
              metadata: { contentDisposition: `attachment; filename="${filename} (${file.name})"` },
            })
          )
        );
      }
      await Promise.all(promiseArray);
      await this.loadFiles();
    }
    this.setState({ loading: false });
  }

  filesInputValue() {
    const { files } = this.state;
    return files?.[0]?.name;
  }

  renderFileUpload = () => {
    const { year, folderIndex, folderName, documentType, sampleId } = this.state;

    return (
      <Row style={{ flexDirection: 'column', gap: '0.75rem' }}>
        <div style={{ display: 'flex', gap: '0.75rem' }}>
          <FormControl fullWidth>
            <InputLabel id="attestation-year">Attestation Year</InputLabel>
            <Select
              labelId="attestation-year"
              onChange={(event) => this.setState({ year: event.target.value })}
              value={year}
              label="Attestation Year"
            >
              {years.map((y) => (
                <MenuItem key={y} value={y}>
                  {y}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth>
            <InputLabel id="facility-folder">Facility/Folder</InputLabel>
            <Select
              labelId="facility-folder"
              onChange={(event) => this.setState({ folderIndex: event.target.value })}
              value={folderIndex}
              label="Facility/Folder"
            >
              {folders.map((f, index) => (
                <MenuItem key={index} value={index}>
                  {f}
                </MenuItem>
              ))}
              <MenuItem value={-1}>Other</MenuItem>
            </Select>
          </FormControl>
          {folderIndex === -1 && (
            <TextField
              onChange={(event) => this.setState({ folderName: event.target.value })}
              value={folderName}
            />
          )}

          <FormControl fullWidth>
            <InputLabel id="document-type">Document Type</InputLabel>
            <Select
              labelId="document-type"
              onChange={(event) => this.setState({ documentType: event.target.value })}
              value={documentType}
              label="Document Type"
            >
              {documentTypes.map((dt) => (
                <MenuItem key={dt.id} value={dt.id}>
                  {dt.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl fullWidth>
            <TextField
              label="QMS Sample Id"
              onChange={(event) => this.setState({ sampleId: event.target.value })}
              value={sampleId}
            />
          </FormControl>
        </div>
        <div style={{ display: 'flex', gap: '0.75rem' }}>
          <Button variant="outlined" component="label" color="primary">
            Choose Files
            <input
              hidden
              type="file"
              value={this.filesInputValue()}
              multiple
              onChange={(event) => {
                console.log('event.target.files ', event.target.files);

                this.setState({ files: event.target.files });
              }}
            />
          </Button>

          <Button color="primary" variant="contained" onClick={this.doUpload}>
            Upload
          </Button>
        </div>
      </Row>
    );
  };

  renderFileBrowser() {
    const { columns, loading } = this.state;

    if (loading) return <Spinner loading={loading} />;

    return (
      <PaginationTable
        rows={filesRows}
        columns={columns}
        rowComponent={(row) => (
          <TableRow>
            {columns.map((c) => (
              <TableCell key={Math.random()}>{row[c.dataField]}</TableCell>
            ))}
          </TableRow>
        )}
      />
    );
  }

  render() {
    const { selectedTab } = this.state;

    const tabDisplay = [
      { title: 'File Upload', component: this.renderFileUpload() },
      { title: 'Access Information', component: <AccessInformation /> },
      { title: 'File Browser/Search', component: this.renderFileBrowser() },
    ];

    return (
      <div style={{ display: 'flex', flexDirection: 'column', gap: '1.2rem' }}>
        <h1>Library</h1>
        <ButtonTabs
          tabDisplay={tabDisplay}
          selectedTab={selectedTab}
          setSelectedTab={(selectedTab) => this.setState({ selectedTab })}
        />
        {tabDisplay[selectedTab].component}
      </div>
    );
  }
}

const condition = (authUser) => !!authUser;

const LibraryPage = withFirebase(LibraryPageBase);

export default compose(
  withEmailVerification,
  withAuthorization(condition),
  withRouter,
  withFirebase
)(LibraryPage);

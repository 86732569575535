import React, { useEffect, useState } from 'react';
import { CustomTable } from '../CustomTable';
import { Button, IconButton, TextField, Tooltip } from '@mui/material';
import { FabAddIcon } from '../FabAddIcon';
import { RightPanel } from '../../Common/Panels/rightPanel';
import RvoTPForm from './RvoTPForm';
import PropTypes from 'prop-types';
import Firebase from '../../Firebase';
import moment from 'moment';
import { rinsLabels } from '../RinsConstants';
import EditIcon from '@mui/icons-material/Edit';
import { ProductionTrackingForm } from './ProductionTrackingForm';
import { Loader } from '../Loader';
import { HoldingForm } from './HoldingForm';

function RVOTP({ firebase, year, company, data }) {
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [rowToUpdate, setRowToUpdate] = useState({});
  const [, setProjectedRows] = useState([]);
  const [form, setForm] = useState('tracking');
  const [notes, setNotes] = useState(data?.trackingNotes || '');
  const [holdingToUpdate, setHoldingToUpdate] = useState({});

  useEffect(() => {
    setNotes(data?.trackingNotes || '');
  }, [data]);

  const projectedRows =
    Object.keys(data?.projections ?? {}).map((id) => ({ ...data?.projections[id], id })) || [];

  const trackingRows = Object.values(data?.tracking ?? {}) || [];

  const emtsRows =
    Object.keys(data?.holdings ?? {}).map((id) => ({ ...data.holdings[id], id })) || [];

  const currentTracking = trackingRows
    .sort((a, b) => (moment(a.date).isAfter(moment(b.date)) ? -1 : 1))
    .map((row) => Math.round(row.production))[0];

  const saveNotes = () => {
    setLoading(true);
    firebase.updateTrackingNotes({ year, company, notes }).finally(() => setLoading(false));
  };

  const emtsHeaders = [
    { label: 'RIN type', key: 'type', format: (value) => rinsLabels[value] ?? '' },
    { label: year - 1, key: 'lastYear' },
    { label: `${year - 1} Retired`, key: 'lastYearRetired' },
    { label: `${year - 1} Retirement date`, key: 'lastYearRetirmentDate' },
    { label: year, key: 'currentYear' },
    { label: `${year} Retired`, key: 'retired' },
    { label: `${year} Retirement date`, key: 'retirementDate' },
    { label: 'Total', key: 'total' },
    {
      label: 'Actions',
      key: 'actions',
      component: (row) => (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Tooltip title="Edit">
            <IconButton aria-label="edit" size="small" onClick={() => updateHolding(row)}>
              <EditIcon />
            </IconButton>
          </Tooltip>
        </div>
      ),
    },
  ];

  const rvoprojectedHeaders = [
    { label: 'RIN type', key: 'type', format: (value) => rinsLabels[value] ?? '' },
    { label: 'Initial RVO %', key: 'initialRvo' },
    { label: 'Final RVO %', key: 'finalRvo' },
    { label: `${year} RVO`, key: 'currentRvo' },
    { label: `Nested RVO ${year}`, key: 'currentNestedRvo' },
    { label: `RINs Projected C/O Inventory ${year - 1}`, key: 'projectedLastYear' },
    { label: `Nested RINs Max Usable ${year - 1}`, key: 'nestedLastYear' },
    { label: `Nested RINs Contracted ${year}`, key: 'rinsContracted' },
    { label: `RINs Actual Inventory ${year}`, key: 'actualInventory' },
    { label: 'Outstanding RVO', key: 'outstandingRvo' },
    { label: '% Of Plan Satisfied', key: 'projectionSatisfiedPercentage' },
    {
      label: 'Actions',
      key: 'actions',
      component: (row) => (
        <div style={{ display: 'flex' }}>
          <Tooltip title="Edit">
            <IconButton aria-label="edit" size="small" onClick={() => updateProjection(row)}>
              <EditIcon />
            </IconButton>
          </Tooltip>
        </div>
      ),
    },
  ];

  const ptrackingHeaders = [
    { label: 'Obtained From', key: 'obtainedFrom' },
    { label: 'Date', key: 'date' },
    {
      label: 'Production',
      key: 'production',
      format: (value) => Math.round(value).toLocaleString(),
    },
  ];

  const updateProjection = (pvo) => {
    setOpenModal(true);
    setForm('projected');
    setRowToUpdate(pvo);
  };

  const updateHolding = (holding) => {
    setOpenModal(true);
    setForm('holding');
    setHoldingToUpdate(holding);
  };

  function savetracking(t) {
    setLoading(true);
    firebase
      .newRvoTracking({ tracking: t, year, company })
      // .then(() => setTrackingRows([...trackingRows, t]))
      .finally(() => {
        setOpenModal(false);
        setLoading(false);
      });
  }

  function changeHolding(value) {
    setLoading(true);
    firebase
      .updateEMTSHolding({ company, year, holding: value })
      // .then(() => {
      //   setEmtsRows(emtsRows.map((r) => (r?.id === value.id ? value : r)));
      // })
      .finally(() => {
        setOpenModal(false);
        setLoading(false);
      });
  }

  const forms = {
    projected: (
      <RvoTPForm onSubmit={(value) => changeProjection(value)} initialValue={rowToUpdate} />
    ),
    tracking: <ProductionTrackingForm saveTracking={savetracking} />,
    holding: (
      <HoldingForm holdingToUpdate={holdingToUpdate} year={year} updateHoldings={changeHolding} />
    ),
  };

  const changeProjection = async (rvo) => {
    rvo.transactionDate = rvo.executed ? moment().format('MM/DD/YYYY') : null;
    await firebase
      .updateProjectedRvo({ rvo, year, company })
      .then(() => setProjectedRows(projectedRows.map((r) => (r?.id === rvo.id ? rvo : r))))
      .finally(() => setOpenModal(false));
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '2rem' }}>
      <h2>EMTS RIN Holdings</h2>
      <CustomTable headers={emtsHeaders} rows={emtsRows} />

      <h2>Projected RVO Calculation</h2>
      <div style={{ display: 'grid', gridTemplateColumns: '1fr 50px' }}>
        <p>{`Production Year ${year}`}</p>
        <p style={{ textAlign: 'end' }}>{currentTracking?.toLocaleString()}</p>
      </div>
      <CustomTable headers={rvoprojectedHeaders} rows={projectedRows} maxHeight={880} />
      <h2>Production tracking </h2>
      <div
        style={{
          display: 'grid',
          gap: '1rem',
          gridTemplateColumns: 'repeat(2,1fr)',
          gridTemplateRows: '3fr 1fr',
        }}
      >
        <div
          style={{
            gridRow: '1/3',
          }}
        >
          <CustomTable headers={ptrackingHeaders} rows={trackingRows} />
        </div>

        <TextField
          style={{ width: '100%' }}
          label="Notes"
          multiline
          rows={10}
          value={notes}
          onChange={(e) => setNotes(e.target.value)}
        />
        <Button variant="outlined" color="secondary" onClick={saveNotes}>
          Save Notes
        </Button>
      </div>
      <FabAddIcon
        onClick={() => {
          setRowToUpdate({});
          setOpenModal(true);
          setForm('tracking');
        }}
      />
      <RightPanel open={openModal} setOpen={setOpenModal}>
        {forms[form]}
      </RightPanel>
      <Loader isLoading={loading} />
    </div>
  );
}

RVOTP.propTypes = {
  firebase: PropTypes.instanceOf(Firebase).isRequired,
  year: PropTypes.number.isRequired,
  company: PropTypes.string.isRequired,
  data: PropTypes.shape({
    tracking: PropTypes.any,
    projections: PropTypes.any,
    holdings: PropTypes.any,
    trackingNotes: PropTypes.string,
  }).isRequired,
};

export { RVOTP };

import { dateFilter, numberFilter, textFilter, Comparator } from 'react-bootstrap-table2-filter';

const today = new Date();
const thisMonth = today.getMonth() + 1;

const defaultDateFilter1 = dateFilter({
  defaultValue: {
    date: new Date(today.getFullYear() - 1, thisMonth <= 6 ? 5 : 11, 31),
    comparator: Comparator.GT,
  },
});

const defaultDateFilter2 = dateFilter({
  defaultValue: {
    date: new Date(today.getTime() + 24 * 60 * 60 * 1000),
    comparator: Comparator.LT,
  },
});

const defaultDateFilter3 = dateFilter();

const commonMap = [
  {
    dataField: 'reportformid',
    text: 'Report Form ID',
    filter: textFilter(),
    sort: true,
    display:true
  },
  {
    dataField: 'reporttype',
    text: 'Report Type',
    filter: textFilter(),
    sort: true,
    display:true
  },
  {
    dataField: 'cbi',
    text: 'CBI',
    filter: textFilter(),
    sort: true,
    display:true
  },
  {
    dataField: 'reportdate',
    text: 'Report Date',
    filter: defaultDateFilter1,
    sort: true,
    display:true,
    filterType: 'date'
  },
  {
    dataField: 'reportyear',
    text: 'Report Year',
    filter: numberFilter(),
    sort: true,
    display:true
  },
];

const dataYearDefault =
  new Date().getMonth() >= 3 ? new Date().getFullYear() : new Date().getFullYear() - 1;

const columnMapping = {
  /*
   ABT0300
  */
  abt0300: commonMap.concat(
    {
      dataField: 'year',
      text: 'Data Year',
      filter: numberFilter({
        // default value is this year if April or later, otherwise last year
        id: 'abt0300year',
        defaultValue: {
          number: dataYearDefault,
          comparator: Comparator.EQ,
        },
      }),
      sort: true,
      display:true
    },
    {
      dataField: 'reporterid',
      text: 'Reporter ID',
      filter: numberFilter(),
      sort: true,
      display:true
    },
    {
      dataField: 'companyid',
      text: 'Company ID',
      filter: numberFilter(),
      sort: true,
    },
    {
      dataField: 'facilityid',
      text: 'Facility ID',
      filter: numberFilter(),
      sort: true,
    },
    {
      dataField: 'averagingstandard',
      text: 'Averaging Standard',
      filter: textFilter(),
      sort: true,
    },
    {
      dataField: 'batchvolume',
      text: 'Total Volume',
      filter: numberFilter(),
      sort: true,
    },
    {
      dataField: 'prioryeardeficit',
      text: 'Prior Year Deficit',
      filter: textFilter(),
      sort: true,
    },
    {
      dataField: 'downstreamoxygenate',
      text: 'Downstream Oxygenate Recertification',
      filter: textFilter(),
      sort: true,
    },
    {
      dataField: 'facilityname',
      text: 'Facility Name',
      filter: textFilter(),
      sort: true,
    },
    {
      dataField: 'average',
      text: 'Unadjusted Volume Weighted Average Level',
      filter: numberFilter(),
      sort: true,
    },
    {
      dataField: 'netannualaverage',
      text: 'Net Annual Average Level',
      filter: textFilter(),
      sort: true,
    },
    {
      dataField: 'calculatedcompliance',
      text: 'Calculated Compliance Value',
      filter: textFilter(),
      sort: true,
    },
    {
      dataField: 'deficit',
      text: 'Deficit',
      filter: textFilter(),
      sort: true,
    },
    {
      dataField: 'comments',
      text: 'Comments',
      filter: textFilter(),
      sort: true,
    }
  ),
  /*
   DSL0100
  */
  dsl0100: commonMap.concat(
    {
      dataField: 'reporterid',
      text: 'Reporter ID',
      filter: numberFilter(),
      sort: true,
      display:true
    },
    {
      dataField: 'companyid',
      text: 'Company ID',
      filter: numberFilter(),
      sort: true,
      display:true
    },
    {
      dataField: 'facilityid',
      text: 'Facility ID',
      filter: numberFilter(),
      sort: true,
    },
    {
      dataField: 'productdesignation',
      text: 'Product Designation',
      filter: textFilter(),
      sort: true,
    },
    {
      dataField: 'totalvolume',
      text: 'Total Volume',
      filter: numberFilter(),
      sort: true,
    },
    {
      dataField: 'sulfurmax',
      text: 'Sulfur - Maximum Result',
      filter: numberFilter(),
      sort: true,
    },
    {
      dataField: 'sulfuravg',
      text: 'Sulfur - Average Result',
      filter: numberFilter(),
      sort: true,
    },
    {
      dataField: 'facilityname',
      text: 'Facility Name',
      filter: textFilter(),
      sort: true,
    },
    {
      dataField: 'comments',
      text: 'Comments',
      filter: textFilter(),
      sort: true,
    }
  ),
  /*
   RFG0303
  */
  rfg0303: commonMap.concat(
    {
      dataField: 'reporterid',
      text: 'Reporter ID',
      filter: numberFilter(),
      sort: true,
      display:true
    },
    {
      dataField: 'companyid',
      text: 'Company ID',
      filter: numberFilter(),
      sort: true,
      display:true
    },
    {
      dataField: 'facilityid',
      text: 'Facility ID',
      filter: numberFilter(),
      sort: true,
    },
    {
      dataField: 'batchnumber',
      text: 'Batch Number',
      filter: textFilter(),
      sort: true,
    },
    {
      dataField: 'butanebatchnumber',
      text: 'Butane or Pentane Batch Number',
      filter: textFilter(),
      sort: true,
    },
    {
      dataField: 'volumetype',
      text: 'Volume Type',
      filter: textFilter(),
      sort: true,
    },
    {
      dataField: 'batchvolume',
      text: 'Batch Volume',
      filter: numberFilter(),
      sort: true,
    },
    {
      dataField: 'butanebatchvolume',
      text: 'Butane or Pentane Batch Volume',
      filter: numberFilter(),
      sort: true,
    },
    {
      dataField: 'productiondate',
      text: 'Production Date (Start)',
      filter: defaultDateFilter1,
      sort: true,
    },
    {
      dataField: 'productiondate2',
      text: 'Production Date (End)',
      filter: defaultDateFilter2,
      sort: true,
    },
    {
      dataField: 'facilityname',
      text: 'Facility Name',
      filter: textFilter(),
      sort: true,
    },
    {
      dataField: 'datepcgbatchreceived',
      text: 'Date PCG Batch Received',
      filter: defaultDateFilter1,
      sort: true,
    },
    {
      dataField: 'producttype',
      text: 'Product Type',
      filter: textFilter(),
      sort: true,
    },
    {
      dataField: 'batchgrade',
      text: 'Batch Grade',
      filter: textFilter(),
      sort: true,
    },
    {
      dataField: 'labwaiver',
      text: 'Lab Waiver',
      filter: textFilter(),
      sort: true,
    },
    {
      dataField: 'independentlabanalysis',
      text: 'Independent Lab Analysis',
      filter: textFilter(),
      sort: true,
    },
    {
      dataField: 'voccontrol',
      text: 'VOC Control',
      filter: textFilter(),
      sort: true,
    },
    {
      dataField: 'oxygen',
      text: 'Oxygen',
      filter: numberFilter(),
      sort: true,
    },
    {
      dataField: 'oxygenparam',
      text: 'Oxygen Parameter Test Method',
      filter: textFilter(),
      sort: true,
    },
    {
      dataField: 'sulfur',
      text: 'Sulfur',
      filter: numberFilter(),
      sort: true,
    },
    {
      dataField: 'sulfurparam',
      text: 'Sulfur Parameter Test Method',
      filter: textFilter(),
      sort: true,
    },
    {
      dataField: 'aromatics',
      text: 'Aromatics',
      filter: numberFilter(),
      sort: true,
    },
    {
      dataField: 'aromaticsparam',
      text: 'Aromatics Parameter Test Method',
      filter: textFilter(),
      sort: true,
    },
    {
      dataField: 'olefins',
      text: 'Olefins',
      filter: numberFilter(),
      sort: true,
    },
    {
      dataField: 'olefinsparam',
      text: 'Olefins Parameter Test Method',
      filter: textFilter(),
      sort: true,
    },
    {
      dataField: 'benzene',
      text: 'Benzene',
      filter: numberFilter(),
      sort: true,
    },
    {
      dataField: 'benzeneparam',
      text: 'Benzene Parameter Test Method',
      filter: textFilter(),
      sort: true,
    },
    {
      dataField: 'methanol',
      text: 'Methanol',
      filter: numberFilter(),
      sort: true,
    },
    {
      dataField: 'methanolparam',
      text: 'Methanol Parameter Test Method',
      filter: textFilter(),
      sort: true,
    },
    {
      dataField: 'mtbe',
      text: 'MTBE',
      filter: numberFilter(),
      sort: true,
    },
    {
      dataField: 'mtbeparam',
      text: 'MTBE Parameter Test Method',
      filter: textFilter(),
      sort: true,
    },
    {
      dataField: 'ethanol',
      text: 'Ethanol',
      filter: numberFilter(),
      sort: true,
    },
    {
      dataField: 'ethanolparam',
      text: 'Ethanol Parameter Test Method',
      filter: textFilter(),
      sort: true,
    },
    {
      dataField: 'etbe',
      text: 'ETBE',
      filter: numberFilter(),
      sort: true,
    },
    {
      dataField: 'etbeparam',
      text: 'ETBE Parameter Test Method',
      filter: textFilter(),
      sort: true,
    },
    {
      dataField: 'tame',
      text: 'TAME',
      filter: numberFilter(),
      sort: true,
    },
    {
      dataField: 'tameparam',
      text: 'TAME Parameter Test Method',
      filter: textFilter(),
      sort: true,
    },
    {
      dataField: 'tbutanol',
      text: 't-Butanol',
      filter: numberFilter(),
      sort: true,
    },
    {
      dataField: 'tbutanolparam',
      text: 't-Butanol Parameter Test Method',
      filter: textFilter(),
      sort: true,
    },
    {
      dataField: 'rvp',
      text: 'RVP',
      filter: numberFilter(),
      sort: true,
    },
    {
      dataField: 'rvpparam',
      text: 'RVP Parameter Test Method',
      filter: textFilter(),
      sort: true,
    },
    {
      dataField: 't50',
      text: 'T50',
      filter: numberFilter(),
      sort: true,
    },
    {
      dataField: 't50param',
      text: 'T50 Parameter Test Method',
      filter: textFilter(),
      sort: true,
    },
    {
      dataField: 't90',
      text: 'T90',
      filter: numberFilter(),
      sort: true,
    },
    {
      dataField: 't90param',
      text: 'T90 Parameter Test Method',
      filter: textFilter(),
      sort: true,
    },
    {
      dataField: 'e200',
      text: 'E200',
      filter: numberFilter(),
      sort: true,
    },
    {
      dataField: 'e200param',
      text: 'E200 Parameter Test Method',
      filter: textFilter(),
      sort: true,
    },
    {
      dataField: 'e300',
      text: 'E300',
      filter: numberFilter(),
      sort: true,
    },
    {
      dataField: 'e300param',
      text: 'E300 Parameter Test Method',
      filter: textFilter(),
      sort: true,
    },
    {
      dataField: 'toxics',
      text: 'Toxics',
      filter: textFilter(),
      sort: true,
    },
    {
      dataField: 'vocs',
      text: 'VOCs',
      filter: textFilter(),
      sort: true,
    },
    {
      dataField: 'exhausttoxics',
      text: 'Exhaust Toxics Emissions',
      filter: textFilter(),
      sort: true,
    },
    {
      dataField: 'tier3batchsulfur',
      text: 'Tier 3 Batch Sulfur',
      filter: textFilter(),
      sort: true,
    },
    {
      dataField: 'batchdatasource',
      text: 'Batch Data Source',
      filter: textFilter(),
      style: {
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        maxWidth: '200px',
      },
      sort: true,
    },
    {
      dataField: 'blendstock',
      text: 'Blendstock',
      filter: textFilter(),
      sort: true,
    },
    {
      dataField: 'sampleid',
      text: 'Sample ID',
      filter: textFilter(),
      style: {
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        maxWidth: '200px',
      },
      sort: true,
    },
    {
      dataField: 'coadate',
      text: 'COA Date',
      filter: dateFilter(),
      sort: true,
    },
    {
      dataField: 'facilityorigin',
      text: 'Facility Origin',
      filter: textFilter(),
      sort: true,
    },
    {
      dataField: 'butanepct',
      text: 'Butane Pct',
      filter: textFilter(),
      sort: true,
    },
    {
      dataField: 'comments',
      text: 'Comments',
      filter: textFilter(),
      sort: true,
    }
  ),
  /*
   RFG030X
  */
  rfg030x: commonMap.concat(
    {
      dataField: 'reporterid',
      text: 'Reporter ID',
      filter: numberFilter(),
      sort: true,
      display:true
    },
    {
      dataField: 'companyid',
      text: 'Company ID',
      filter: numberFilter(),
      sort: true,
      display:true
    },
    {
      dataField: 'facilityid',
      text: 'Facility ID',
      filter: numberFilter(),
      sort: true,
    },
    {
      dataField: 'batchnumber',
      text: 'Batch Number',
      filter: textFilter(),
      sort: true,
    },
    {
      dataField: 'volumetype',
      text: 'Volume Type',
      filter: textFilter(),
      sort: true,
    },
    {
      dataField: 'batchvolume',
      text: 'Batch Volume',
      filter: numberFilter(),
      sort: true,
    },
    {
      dataField: 'facilityname',
      text: 'Facility Name',
      filter: textFilter(),
      sort: true,
    },
    {
      dataField: 'productiondate',
      text: 'Production Date (Start)',
      filter: defaultDateFilter1,
      sort: true,
    },
    {
      dataField: 'productiondate2',
      text: 'Production Date (End)',
      filter: defaultDateFilter2,
      sort: true,
    },
    {
      dataField: 'datepcgbatchreceived',
      text: 'Date PCG Batch Received',
      filter: defaultDateFilter1,
      sort: true,
    },
    {
      dataField: 'producttype',
      text: 'Product Type',
      filter: textFilter(),
      sort: true,
    },
    {
      dataField: 'voccontrol',
      text: 'Gas Vol Stds',
      filter: textFilter(),
      sort: true,
    },
    {
      dataField: 'oxygenate',
      text: 'Oxygenate',
      filter: textFilter(),
      sort: true,
    },
    {
      dataField: 'oxygenateparameter',
      text: 'Oxygenate Paramter Test Method',
      filter: textFilter(),
      sort: true,
    },
    {
      dataField: 'oxygenatetype',
      text: 'Oxygenate Type',
      filter: textFilter(),
      sort: true,
    },
    {
      id: 'sulfurbob',
      dataField: 'sulfur',
      text: 'Sulfur - BOB',
      filter: numberFilter(),
      sort: true,
    },
    {
      id: 'sulfurhb',
      dataField: 'sulfurhb',
      text: 'Sulfur - HB, FG, BS',
      filter: numberFilter(),
      sort: true,
    },
    {
      dataField: 'sulfurparam',
      text: 'Sulfur Parameter Test Method',
      filter: textFilter(),
      sort: true,
    },
    {
      dataField: 'benzene',
      text: 'Benzene',
      filter: numberFilter(),
      sort: true,
    },
    {
      dataField: 'benzeneparam',
      text: 'Benzene Parameter Test Method',
      filter: textFilter(),
      sort: true,
    },
    {
      id: 'rvpbob',
      dataField: 'rvp',
      text: 'RVP - BOB',
      filter: numberFilter(),
      sort: true,
    },
    {
      id: 'rvphb',
      dataField: 'rvphb',
      text: 'RVP - HB, FG, BS',
      filter: numberFilter(),
      sort: true,
    },
    {
      dataField: 'rvpparam',
      text: 'RVP Parameter Test Method',
      filter: textFilter(),
      sort: true,
    },
    {
      dataField: 'benzenedeficit',
      text: 'Benzene Deficit',
      filter: textFilter(),
      sort: true,
    },
    {
      dataField: 'sulfurdeficit',
      text: 'Sulfur Deficit',
      filter: textFilter(),
      sort: true,
    },
    {
      dataField: 'butanepct',
      text: 'Butane Pct',
      filter: textFilter(),
      sort: true,
    },
    {
      dataField: 'comments',
      text: 'Comments',
      filter: textFilter(),
      sort: true,
    },
    {
      dataField: 'blendstock',
      text: 'Blendstock',
      filter: textFilter(),
      sort: true,
    },
    {
      dataField: 'datalocation',
      text: 'Data Location',
      filter: textFilter(),
      sort: true,
    }
  ),
  gsf0402: commonMap.concat(
    {
      dataField: 'companyid',
      text: 'Company ID',
      filter: numberFilter(),
      sort: true,
      display:true
    },
    {
      dataField: 'facilityname',
      text: 'Facility Name',
      filter: textFilter(),
      sort: true,
      display:true
    },
    {
      dataField: 'facilityid',
      text: 'Facility ID',
      filter: numberFilter(),
      sort: true,
    },
    {
      dataField: 'batchnumber',
      text: 'Batch Number',
      filter: textFilter(),
      sort: true,
    },
    {
      dataField: 'producttype',
      text: 'Product Type',
      filter: textFilter(),
      sort: true,
    },
    {
      dataField: 'productiondate',
      text: 'Production Date (Start)',
      filter: defaultDateFilter1,
      sort: true,
    },
    {
      dataField: 'productiondate2',
      text: 'Production Date (End)',
      filter: defaultDateFilter2,
      sort: true,
    },
    {
      dataField: 'batchvolume',
      text: 'Batch Volume',
      filter: numberFilter(),
      sort: true,
    },
    {
      dataField: 'sulfur',
      text: 'Sulfur',
      filter: numberFilter(),
      sort: true,
    },
    {
      dataField: 'sulfurparam',
      text: 'Sulfur Parameter Test Method',
      filter: textFilter(),
      sort: true,
    },
    {
      dataField: 'volsulfur',
      text: 'Vol * Sulfur (ppm-gal)',
      filter: numberFilter(),
      sort: true,
    },
    {
      dataField: 'benzene',
      text: 'Benzene',
      filter: numberFilter(),
      sort: true,
    },
    {
      dataField: 'benzeneparam',
      text: 'Benzene Parameter Test Method',
      filter: textFilter(),
      sort: true,
    },
    {
      dataField: 'volbenzene',
      text: 'Vol * Benzene (%v-gal)',
      filter: numberFilter(),
      sort: true,
    },
    {
      dataField: 'datasource',
      text: 'Data Source',
      filter: textFilter(),
      style: {
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        maxWidth: '200px',
      },
      sort: true,
    },
    {
      dataField: 'blendstock',
      text: 'Blendstock',
      filter: textFilter(),
      sort: true,
    },
    {
      dataField: 'sampleid',
      text: 'Sample ID',
      filter: textFilter(),
      style: {
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        maxWidth: '200px',
      },
      sort: true,
    },
    {
      dataField: 'coadate',
      text: 'COA Date',
      filter: dateFilter(),
      sort: true,
    },
    {
      dataField: 'comments',
      text: 'Comments',
      filter: textFilter(),
      sort: true,
    }
  ),
  str0200: [
    {
      dataField: 'reporttype',
      text: 'Report Type',
      filter: textFilter(),
      sort: true,
      display:true
    },
    {
      dataField: 'cbi',
      text: 'CBI',
      filter: textFilter(),
      sort: true,
      display:true
    },
    {
      dataField: 'reportdate',
      text: 'Report Date',
      filter: defaultDateFilter3,
      sort: true,
    },
    {
      dataField: 'reportyear',
      text: 'Report Year',
      filter: numberFilter(),
      sort: true,
    },
    {
      dataField: 'reporterid',
      text: 'Reporter ID',
      filter: numberFilter(),
      sort: true,
    },
    {
      dataField: 'companyid',
      text: 'Company ID',
      filter: numberFilter(),
      sort: true,
    },
    {
      dataField: 'facilityname',
      text: 'Facility Name',
      filter: textFilter(),
      sort: true,
    },
    {
      dataField: 'facilityid',
      text: 'Facility ID',
      filter: numberFilter(),
      sort: true,
    },
    {
      dataField: 'batchnumber',
      text: 'Batch Number',
      filter: textFilter(),
      sort: true,
    },
    {
      dataField: 'volumetype',
      text: 'Volume Type',
      filter: textFilter(),
      sort: true,
    },
    {
      dataField: 'batchvolume',
      text: 'Batch Volume',
      filter: numberFilter(),
      sort: true,
    },
    {
      dataField: 'productiondate',
      text: 'Production Date',
      filter: defaultDateFilter3,
      sort: true,
    },
    {
      dataField: 'datepbprecd',
      text: 'Date PCG, Butane, or Pentane Batch Received',
      filter: defaultDateFilter3,
      sort: true,
    },
    {
      dataField: 'producttype',
      text: 'Product Type',
      filter: textFilter(),
      sort: true,
    },
    {
      dataField: 'gasolinevolatilitystandards',
      text: 'Gasoline Volatility Standards',
      filter: textFilter(),
      sort: true,
    },
    {
      dataField: 'oxygenate',
      text: 'Oxygenate',
      filter: textFilter(),
      sort: true,
    },
    {
      dataField: 'oxygenateparametertestmethod',
      text: 'Oxygenate Paramter Test Method',
      filter: textFilter(),
      sort: true,
    },
    {
      dataField: 'oxygenatetype',
      text: 'Oxygenate Type',
      filter: textFilter(),
      sort: true,
    },
    {
      id: 'sulfur-pergalloncompliancevalue',
      dataField: 'sulfur-pergalloncompliancevalue',
      text: 'Sulfur - Per Gallson Compliance Value',
      filter: numberFilter(),
      sort: true,
    },
    {
      id: 'sulfur-averagecompliancevalue',
      dataField: 'sulfur-averagecompliancevalue',
      text: 'Sulfur - Average Compliance Value',
      filter: numberFilter(),
      sort: true,
    },
    {
      dataField: 'sulfurparametertestmethod',
      text: 'Sulfur Parameter Test Method',
      filter: textFilter(),
      sort: true,
    },
    {
      dataField: 'benzene',
      text: 'Benzene',
      filter: numberFilter(),
      sort: true,
    },
    {
      dataField: 'benzeneparametertestmethod',
      text: 'Benzene Parameter Test Method',
      filter: textFilter(),
      sort: true,
    },
    {
      id: 'rvpstr0200',
      dataField: 'rvp',
      text: 'RVP',
      filter: numberFilter(),
      sort: true,
    },
    {
      dataField: 'rvpparametertestmethod',
      text: 'RVP Parameter Test Method',
      filter: textFilter(),
      sort: true,
    },
    {
      dataField: 'benzenedeficit',
      text: 'Benzene Deficit',
      filter: textFilter(),
      sort: true,
    },
    {
      dataField: 'sulfurdeficit',
      text: 'Sulfur Deficit',
      filter: textFilter(),
      sort: true,
    },
    {
      dataField: 'butaneorpentane',
      text: 'Butane Or Pentane',
      filter: textFilter(),
      sort: true,
    },
    {
      dataField: 'butaneorpentaneparametertestmethod',
      text: 'Butane Or Pentane Parameter Test Method',
      filter: textFilter(),
      sort: true,
    },
    {
      dataField: 'comments',
      text: 'Comments',
      filter: textFilter(),
      sort: true,
    },
    {
      dataField: 'includedincreditcalc',
      text: 'Included in Credit Calc',
      filter: textFilter(),
      sort: true,
    },
  ],
};

for (const cm of Object.keys(columnMapping)) {
  for (const c of columnMapping[cm]) {
    if (!c.headerStyle && !c.style) {
      c.headerStyle = {
        minWidth: '135px',
      };
    }
  }
}

export default columnMapping;

import React, { useState } from 'react';
import { Button, TextField } from '@mui/material';
import { rinsLabels } from '../RinsConstants';
import PropTypes from 'prop-types';

const HoldingForm = ({ holdingToUpdate, year, updateHoldings }) => {
  const initialValue =
    typeof holdingToUpdate.retired === 'string'
      ? Number(holdingToUpdate?.retired?.replace(/\D/, ''))
      : holdingToUpdate.retired;

  const initialDate = holdingToUpdate?.retirementDate || '';

  const [value, setValue] = useState(initialValue);
  const [retirementDate, setRetirementDate] = useState(initialDate);

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
      Holding
      <TextField label="Type" type="text" value={rinsLabels[holdingToUpdate.type] || ''} disabled />
      <TextField label={year - 1} type="text" value={holdingToUpdate.lastYear} disabled />
      <TextField label={year} type="text" value={holdingToUpdate.currentYear} disabled />
      <TextField
        label="Retired"
        type="number"
        value={value}
        onChange={(e) => setValue(e.target.value)}
      />
      <TextField
        label="Retirement date"
        value={retirementDate}
        onChange={(e) => setRetirementDate(e.target.value)}
      />
      <TextField label="Total" type="text" value={holdingToUpdate.total} disabled />
      <Button
        onClick={() => {
          updateHoldings({ ...holdingToUpdate, retired: value, retirementDate });
        }}
      >
        Save
      </Button>
    </div>
  );
};

HoldingForm.propTypes = {
  holdingToUpdate: PropTypes.shape({
    type: PropTypes.string.isRequired,
    lastYear: PropTypes.string.isRequired,
    currentYear: PropTypes.string.isRequired,
    retired: PropTypes.string.isRequired,
    total: PropTypes.number.isRequired || PropTypes.string.isRequired,
  }).isRequired,
  year: PropTypes.number.isRequired,
  updateHoldings: PropTypes.func.isRequired,
};

export { HoldingForm };

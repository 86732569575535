import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { compose } from 'recompose';
import { Comparator, dateFilter, numberFilter, textFilter } from 'react-bootstrap-table2-filter';

import Spinner from '../Spinner';

import { withAuthorization, withEmailVerification } from '../Session';
import Firebase, { withFirebase } from '../Firebase';
import { CollapsibleRow } from '../FacilityReview/collapsibleRow';
import { PaginationTable } from '../Common/PaginationTable/paginationTable';
import { FormControl, InputLabel, MenuItem, Select, TableCell, TableRow } from '@mui/material';

const fileList = [
  { id: 'glo_analytical_beforecsv', name: 'GLO Analytical Before' },
  { id: 'glo_analytical_aftercsv', name: 'GLO Analytical After' },
  { id: 'bol_truck_logcsv', name: 'BOL Truck Log' },
  { id: 'butane_coacsv', name: 'Butane COA' },
  { id: 'frac_gas_releasecsv', name: 'Frac Gas Release' },
  { id: 'blendworkflowcsv', name: 'Blend Workflow' },
  { id: 'blendstock_certificationcsv', name: 'Blendstock Certification' },
  { id: 'blendstockcsv', name: 'Blendstock' },
  { id: 'epafacilityidcsv', name: 'EPA Facility ID' },
  { id: 'voccontrolgroupcsv', name: 'VOC Control Group' },
  { id: 'methodcsv', name: 'Method' },
];

const today = new Date();
const thisMonth = today.getMonth() + 1;

const defaultDateFilter1 = dateFilter({
  defaultValue: {
    date: new Date(today.getFullYear() - 1, thisMonth <= 6 ? 5 : 11, 31),
    comparator: Comparator.GT,
  },
});

const columnMapping = {
  glo_commoncsv: [
    {
      dataField: 'sampleid',
      text: 'Sample ID',
      filter: textFilter(),
      sort: true,
      display: true,
    },
    {
      dataField: 'sampledate',
      text: 'Sample Date (Start)',
      filter: defaultDateFilter1,
      sort: true,
      display: true,
      filterType: 'date',
    },
    // {
    //   dataField: 'sampledate2',
    //   text: 'Sample Date (End)',
    //   filter: defaultDateFilter2,
    //   sort: true
    // },
    {
      dataField: 'location',
      text: 'Location',
      filter: textFilter(),
      sort: true,
      display: true,
      filterType: 'text',
    },
    {
      dataField: 'tank_line',
      text: 'Tank Line',
      filter: textFilter(),
      sort: true,
      style: {
        'white-space': 'nowrap',
        'text-overflow': 'ellipsis',
        overflow: 'hidden',
        'max-width': '200px',
      },
      display: true,
    },
    {
      dataField: 'product',
      text: 'Product',
      filter: textFilter(),
      sort: true,
      display: true,
      filterType: 'text',
    },
    {
      dataField: 'remarks',
      text: 'Remarks',
      filter: textFilter(),
      sort: true,
      style: {
        'white-space': 'nowrap',
        'text-overflow': 'ellipsis',
        overflow: 'hidden',
        'max-width': '200px',
      },
    },
    {
      dataField: 'beforervp',
      text: 'Before RVP',
      filter: numberFilter(),
      sort: true,
      display: true,
    },
    {
      dataField: 'afterrvp',
      text: 'After RVP',
      filter: numberFilter(),
      sort: true,
      display: true,
    },
    {
      dataField: 'beforesulfur',
      text: 'Before Sulfur',
      filter: numberFilter(),
      sort: true,
      display: true,
    },
    {
      dataField: 'aftersulfur',
      text: 'After Sulfur',
      filter: numberFilter(),
      sort: true,
      display: true,
    },

    {
      dataField: 'cbsblend',
      text: 'CBS Blend',
      filter: numberFilter(),
      sort: true,
    },
    {
      dataField: 'correspondingsample',
      text: 'Corresponding Sample',
      filter: textFilter(),
      sort: true,
    },
    {
      dataField: 'endpoint',
      text: 'End Point',
      filter: numberFilter(),
      sort: true,
      display: true,
    },
    {
      dataField: 'fracgasbarrelsblended',
      text: 'Frac Gas Barrels Blended',
      filter: numberFilter(),
      sort: true,
    },
    {
      dataField: 'gasbarrelsafterblend',
      text: 'Gas Barrels After Blend',
      filter: numberFilter(),
      sort: true,
    },
    {
      dataField: 'gasbarrelsbeforeblend',
      text: 'Gas Barrels Before Blend',
      filter: numberFilter(),
      sort: true,
    },
    {
      dataField: 'hbbls',
      text: 'H BBLS',
      filter: numberFilter(),
      sort: true,
    },

    {
      dataField: 'spherecertificationsample',
      text: 'Sphere Certification Sample',
      filter: textFilter(),
      sort: true,
    },
    {
      dataField: 'splitmonthblend',
      text: 'Split Month Blend',
      filter: textFilter(),
      sort: true,
    },
    {
      dataField: 'tmixbarrelsblended',
      text: 'T-Mix Barrels Blended',
      filter: numberFilter(),
      sort: true,
    },
    {
      dataField: 'targetrvp',
      text: 'TargetRVP',
      filter: numberFilter(),
      sort: true,
    },
    {
      dataField: 'wbbls',
      text: 'W BBLS',
      filter: numberFilter(),
      sort: true,
    },
  ],
};

class QMSPageBase extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      rows: [],
      columns: [],
      selectedSort: fileList[0],
    };
    this.changeSelectedFile = this.changeSelectedFile.bind(this);
    this.loadSelectedFile = this.loadSelectedFile.bind(this);
  }

  componentDidMount() {
    this.loadSelectedFile();
  }

  loadSelectedFile(force = false) {
    const { rows, selectedSort } = this.state;
    if (force || !rows || rows.length < 1) {
      this.setState({ loading: true });
      const { firebase } = this.props;
      firebase.file({ fid: selectedSort.id }).on('value', (snapshot) => {
        const tRows = snapshot.val();
        // need to double up dates for two-way date filters
        const rows = tRows.map((t) => ({ ...t }));
        const header = Object.keys(rows[0]);

        let columns = [];
        if (columnMapping[selectedSort.id]) {
          columns = columnMapping[selectedSort.id];
        } else {
          // build header based upon actual contents
          header
            .filter((h) => !['tank_line', 'remarks'].includes(h))
            .forEach((h, index, array) => {
              const display =
                array.length < 8 ||
                [
                  'date',
                  'dateentered',
                  'location',
                  'batchid',
                  'bolid',
                  'blendid',
                  'sampleid',
                  'location',
                  'tank_line',
                  'product',
                  'sulfur-upper',
                  'sulfur-middle',
                  'sulfur-lower',
                  'benzene-upper',
                  'benzene-middle',
                  'benzene-lower',
                  'type',
                  'blendtype',
                  'totalpre-blendvolume',
                  'targetrvp',
                  'bsvg_bbls-gas-before',
                  'bsvg_bbls-h-grade',
                  'bsvg_bbls-n9',
                  'bsvg_bbls-transmix',
                  'bsvg_bbls-w-grade',
                ].some(
                  (d) =>
                    !h.includes('updatedby') &&
                    !h.includes('test_sampleid') &&
                    !h.includes('dateoftankswitch') &&
                    !h.includes('targetrvpoverride') &&
                    !h.startsWith('mlo') &&
                    h.toLowerCase().includes(d) &&
                    (d.length > 20 || h.length < 20)
                );
              let filterType;
              if (display) {
                filterType = ['date', 'location'].some((d) => h.includes(d)) ? 'date' : 'text';
              }
              columns.push({
                dataField: h,
                text: h,
                // filter:
                //   h.endsWith('date') || h.startsWith('date') ? defaultDateFilter1 : textFilter(),
                // sort: true,
                display,
                filterType,
              });
            });

          const importantColumnNames = ['blendid', 'sampleid', 'sampledate', 'product', 'location'];
          const importantColumns = columns.filter((h) =>
            importantColumnNames.includes(h.dataField)
          );
          const otherColumns = columns.filter((h) => !importantColumnNames.includes(h.dataField));
          columns = [...importantColumns, ...otherColumns];
        }

        this.setState({ rows, columns, loading: false }, () => {
          firebase.fileRest({ fid: selectedSort.id }).on('value', (snapshot) => {
            const val = snapshot.val();
            if (!val) return;

            const newRows = (Array.isArray(val) ? val : Object.values(val)).map((v) => ({ ...v }));
            this.setState({ rows: [...rows, ...newRows].filter((r) => r !== undefined) });
          });
        });
      });
    }
  }

  async changeSelectedFile(event) {
    const selectedSort = JSON.parse(
      JSON.stringify(fileList.find((fl) => fl.id === event.target.value))
    );
    await this.setState({ rows: [], selectedSort });
    this.loadSelectedFile(true);
  }

  render() {
    const { loading, rows, columns, selectedSort } = this.state;

    const collapsible = columns.length > 8;

    const rowsRender = (row) =>
      collapsible ? (
        <CollapsibleRow row={row} columns={columns} key={Math.random()} />
      ) : (
        <TableRow>
          {columns.map((c) => (
            <TableCell key={Math.random()}>{row[c.dataField]}</TableCell>
          ))}
        </TableRow>
      );

    return (
      <div>
        <h1>QMS - Raw Data</h1>

        <FormControl fullWidth>
          <InputLabel id="file-select">File</InputLabel>
          <Select
            label="File"
            labelId="file-select"
            value={selectedSort.id}
            onChange={this.changeSelectedFile}
          >
            {fileList.map((fl) => (
              <MenuItem key={`file-${fl.id}`} value={fl.id}>
                {fl.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {loading && <Spinner loading={loading} />}

        {rows && rows.length > 0 && (
          <div>
            <PaginationTable
              rows={rows}
              columns={columns}
              rowComponent={rowsRender}
              columnFilter={(c) => c.display}
              collapsible={collapsible}
            />
          </div>
        )}
      </div>
    );
  }
}

QMSPageBase.propTypes = {
  firebase: PropTypes.instanceOf(Firebase).isRequired,
};

const condition = (authUser) => !!authUser;

const QMSPage = withFirebase(QMSPageBase);

export default compose(withEmailVerification, withAuthorization(condition), withFirebase)(QMSPage);

export const filesList = (userData) => userData === 'mmp2' ? [
    {
      id: 'bgb',
      name: 'BGB'
    },
    {
      id: 'cba',
      name: 'CBA'
    },
    {
      id: 'cbs2',
      name: 'CBS'
    },
    {
      id: 'dm',
      name: 'Des Moines'
    },
    {
      id: 'kc',
      name: 'Kansas City'
    },
    {
      id: 'pcg',
      name: 'PGPOST'
    },
    {
      id: 'tgp',
      name: 'TGP'
    }
  ] :
  [
    {
      id: 'cbs',
      name: 'CBS'
    },
    {
      id: 'ehr',
      name: 'East Houston Refined'
    },
    {
      id: 'dmb',
      name: 'Des Moines Butane'
    },
    {
      id: 'dmfg',
      name: 'Des Moines Frac Gas'
    },
    {
      id: 'kcb',
      name: 'Kansas City Butane'
    },
    {
      id: 'g2',
      name: 'Greensboro II'
    },
    {
      id: '8082',
      name: '80.82'
    },
    {
      id: 'wtb',
      name: 'W Tank Batching'
    },
    {
      id: 'rvp',
      name: 'RVP'
    }
  ];
